import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './SubNav.scss';

const SubNavItems = ({ navList, activeKey }) =>
    <Breadcrumb>
        {navList.map((nav, i) => nav.text === activeKey
            ? <Breadcrumb.Item key={'link-active-' + i} active>
                <span>{nav.text}</span>
            </Breadcrumb.Item>
            : <LinkContainer key={'link-' + i} to={nav.link}>
                <Breadcrumb.Item>
                    {nav.text}
                </Breadcrumb.Item>
            </LinkContainer>)
        }
    </Breadcrumb>

const SubNav = ({ title, subNavList, activeKey }) =>
    <div className="main-content pb-0">
        <Row>
            <Col>
                <h3 className="subnav-header">{title}</h3>
                {subNavList.length > 0 && <SubNavItems navList={subNavList} activeKey={activeKey} />}
            </Col>
        </Row>
    </div>

export default SubNav;