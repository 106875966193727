export const bankHolidays =
    [   
        'Fri Apr 15 2022', 
        'Mon Apr 18 2022',  // England & Wales only
        'Mon May 02 2022', 
        'Thu Jun 02 2022', 
        'Fri Jun 03 2022', 
        'Mon Aug 01 2022',  // Scotland only
        'Mon Aug 29 2022',  // England & Wales only
        'Wed Nov 30 2022',  // Scotland only
        'Mon Dec 26 2022', 
        'Tue Dec 27 2022', 
        'Mon Jan 02 2023', 
        'Tue Jan 03 2023'   // Scotland only
    ];