import React, { Component } from 'react';
import HomeHeader from '../shared/HomeHeader';
import HomeFooter from '../shared/HomeFooter';
import { Link } from 'react-router-dom';

import './FAQ.scss';

class BookReturn extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={false} />
                <div className="faq-container">
                    <div className="faq-text">
                        <h1>How do I book a return?</h1>
                        <p>Sending a returns parcel is easy with Return Robin. </p>
                        <p><Link to="/register">Sign up</Link> to start your return, or you can <Link to="/login">log in</Link> if you have already created an account with us.</p>
                        <p>From there, just click “create a return”, enter your return information and choose the date you would like it to be collected.</p>
                        <p>All you have to do then is make sure that your return item is packed safely and ready to go.  Our driver brings your label with them.</p>
                    </div>
                </div>
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default BookReturn;