import { findIndex, propEq } from 'ramda';
import { format } from 'date-fns';
import { GET } from './Consumer';
import Endpoints from './components/common/Endpoints';


function sortValuesByDirection(array, key, direction) {
    return array.slice().sort((a, b) => {
        if (a[key] < b[key]) {
            return direction === 1 ? 1 : -1;
        }
        if (a[key] > b[key]) {
            return direction === 1 ? -1 : 1;
        }
        return 0;
    });
}


function getUser() {
    return JSON.parse(localStorage.getItem('user') || "null");
}

function isLoggedIn() {
    return (getUser() !== "null");
}

function toggleSortDirection(oldKey, direction, newKey) {
    if (oldKey !== newKey) {
        return { key: newKey, direction: 1 };
    }

    let newDirection = direction === 1 ? -1 : 1;
    return { key: newKey, direction: newDirection };
}

function authHeader() {
    // return authorization header with jwt token
    let user = getUser();
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token.token };
    } else {
        return {};
    }
}

function tokenisedFetch(url, init) {
    let reqInit = init || {};
    let headers = Object.assign({}, reqInit.headers, authHeader());

    return fetch(url, { ...reqInit, headers: headers, credentials: 'include' });
}

async function tokenisedFetchAsync(url, init) {
    let reqInit = init || {};
    let headers = Object.assign({}, reqInit.headers, authHeader());

    return await fetch(url, { ...reqInit, headers: headers, credentials: 'include' });
}

function hasPermission(feature) {
    const user = getUser();

    if (user) {
        if (user.permissions.indexOf(feature) >= 0) {
            return true;
        }
    }

    return false;
}

function checkPermission(feature, component) {
    const user = getUser();

    if (user) {
        if (user.permissions.indexOf(feature) >= 0) {
            return component;
        }
    }

    return null;
}

function checkProfile(profile, component) {
    const user = getUser();

    if (user) {
        if (user.profiles.indexOf(profile) >= 0) {
            return component;
        }
    }

    return null;
}

function hasProfile(profile) {
    const user = getUser();

    if (user) {
        if (user.profiles.indexOf(profile) >= 0) {
            return true;
        }
    }

    return false;
}

function isNullOrEmptyGuid(guid) {
    return guid === "00000000-0000-0000-0000-000000000000" || guid === null || guid === "" || guid === 0 || guid === "0" || guid === undefined;
}

function toLocalTimeString(dateString) {
    const date = new Date(dateString);
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString('en-GB');
}

function toLocalDateString(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString();
}

const findIndexByAttribute = (identifier, value, list) => {
    return findIndex(propEq(identifier, value))(list);
};

const updateItemPropInArray = (index, attrToUpdate, value, list) => {
    const updatedItem = {
        ...list[index],
        [attrToUpdate]: value
    };

    return [
        ...list.slice(0, index),
        updatedItem,
        ...list.slice(index + 1)
    ];
};

function setCaretPosition(caretPos) {
    const elem = document.getElementsByClassName('intl-tel-input-field')[0];

    if (elem) {
        elem.focus();
        setTimeout(() => {
            elem.setSelectionRange(caretPos, caretPos);
        }, 0);
    }
};

function isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0);
}

const formatDate = (dateString) => format(new Date(dateString), 'dd MMM yyyy');

const formatDateTime = (dateTimeString) => format(new Date(dateTimeString), 'dd MMM yyyy HH:mm');

const getStatusClass = (status) => (status === "Pending" || status ==='Awaiting Drop-off')
    ? "status-pending"
    : status === "In Process"
        ? "status-process"
        : status === "Completed" || status === "Drop-off Successful"
            ? "status-completed"
            :status === "Expired"
            ? "status-expired"
            : status === "Cancelled"
                ? "status-cancelled"
                : "status-failed";

const getInvoiceStatusClass = (status) => status === "DIRECT_DEBIT" ? "status-directdebit" : status === "CARD" ? "status-card" : "";

const getInvoiceStatusText = (text) => text === "DIRECT_DEBIT" ? "Direct Debit" : text === "CARD" ? "Card" : "";

const checkRetailer = async () => {
    return GET(Endpoints.RETAILER.GET.RETAILER_INFORMATION_CHECK)
        .then(response => response.json())
        .then(result => {

            if (!result.error) {
                localStorage.setItem('isRetailerVerified', result.data.setUp);
                localStorage.setItem('hasServiceSelected', result.data.courierService);
            } else localStorage.setItem('isRetailerVerified', 'false');

        }).catch(() => localStorage.setItem('isRetailerVerified', 'false'));
}

export {
    authHeader,
    isNullOrEmptyGuid,
    tokenisedFetch,
    tokenisedFetchAsync,
    hasPermission,
    checkPermission,
    sortValuesByDirection,
    toggleSortDirection,
    isLoggedIn,
    checkProfile,
    hasProfile,
    findIndexByAttribute,
    updateItemPropInArray,
    toLocalTimeString,
    toLocalDateString,
    setCaretPosition,
    isEmpty,
    formatDate,
    formatDateTime,
    getStatusClass,
    getInvoiceStatusClass,
    getInvoiceStatusText,
    checkRetailer
};
