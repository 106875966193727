import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashboardTile = ({ icon, iconClass, title, handleClick }) => (
    <Col xs={6} sm={6} md={4} lg={2} className="mt-3">
        <Card className="dashboard-tile" onClick={handleClick}>
            <FontAwesomeIcon icon={icon} size="4x" className={`mt-4 ${iconClass}`}/>
            <Card.Title className="mt-3">{title}</Card.Title>
        </Card>
    </Col>
);

export {
    DashboardTile
}