import React from 'react';

import './Slider.scss';

const Slider = ({ id, name, label, checked, disabled = false, onChange }) =>
    <div className="switch-container py-2">
        <label className="switch">
            <input id={id} name={name} type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
            <span className="slider round"></span>
        </label>
        <label className="switch-label ml-2 mt-1">{label}</label>
    </div>

export default Slider;