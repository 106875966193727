import React, { Fragment } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const LowScore = ({ robinScore }) =>
    <Fragment>
        <p>Sorry, in order to create a new return, your Robin score needs to be <span className="font-weight-bold">above 30</span>. Your Robin Score is currently {robinScore}.</p>
        <div className="mt-4">
            <h6>Why can I not create a return?</h6>
            <p>Your Robin Score is under the qualifying amount of 30 points.</p>
            <h6>What do I do now?</h6>
            <p>Just by being a Return Robin user, your Robin Score will increase by 20 points every 28 days. Once your Robin Score is 30 or above you can create a return again.</p>
        </div>
    </Fragment>

const TooManyReturns = () =>
    <Fragment>
        <p>Sorry, you have created the maximum number of returns possible for today.</p>
        <div className="mt-4">
            <h6>Why can I not create a return?</h6>
            <p>Return Robin has a limit of 3 returns per day.</p>
            <h6>What do I do now?</h6>
            <p>Tomorrow you can create another 3 returns. Try again tomorrow.</p>
        </div>
    </Fragment>

const ReturnPermissionDenied = ({ handleOkButton, tooManyReturns, robinScore }) =>
    <Fragment>
        <h3>Create Return</h3>
        <Row>
            <Col xs={12} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <div className="message-permission">
                    <FontAwesomeIcon icon={faTimesCircle} size="6x" />
                    <div className="message">
                        {tooManyReturns
                            ? <TooManyReturns />
                            : <LowScore robinScore={robinScore} />
                        }
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <div className="message-permission-button">
                    <Button variant="primary" onClick={handleOkButton} block>Ok</Button>
                </div>
            </Col>
        </Row>
    </Fragment>

export default ReturnPermissionDenied;