import React, { Fragment } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Confirm from '../../../common/modals/Confirm';

const CardActions = ({ hideShowRemoveModal, onReplaceCardClick }) =>
    <div className="float-right">
        <Button variant="link" className="underline" type="button" onClick={hideShowRemoveModal}>Remove Card</Button>
        <Button variant="primary" className="ml-3" onClick={onReplaceCardClick}>Replace Card</Button>
    </div>

const Status = ({ active }) =>
    <div className="mt-5 mb-4">
        <p><strong>Card Status</strong></p>
        {active
            ? <span className="status-completed">Active</span>
            : <span className="status-pending">Pending</span>
        }
    </div>

const Card = ({ details }) =>
    <div className={`payment-card ${details.active ? "active" : "inactive"}`}>
        <p className="payment-card-title">Your Card Details</p>
        <div className="payment-card-mark"></div>
        <p className="payment-card-number">{details.cardSummary}</p>
        <p className="payment-card-name">{details.name}</p>
    </div>

const CardDetails = ({ details, showRemoveModal, hideShowRemoveModal, onRemoveCardAction, onReplaceCardClick }) =>
    <Fragment>
        <Row>
            <Col sm={12} md={6}>
                <Card details={details} />
                <Status active={details.active} />
                <CardActions hideShowRemoveModal={hideShowRemoveModal} onReplaceCardClick={onReplaceCardClick} />
            </Col>
        </Row>
        <Confirm
            title="Remove Card"
            variant="outline-danger"
            text="Are you sure you want to remove your card details? You will no longer be an active retailer on Return Robin. Any existing returns will continue but no new returns will be able to be created."
            handleClose={hideShowRemoveModal}
            handleConfirmAction={onRemoveCardAction}
            buttonText="Remove Card"
            linkText="Cancel"
            closeLink={true}
            show={showRemoveModal}
        />
    </Fragment>

export default CardDetails
