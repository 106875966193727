import React, { Fragment } from 'react';
import ReturnSummary from './shared/ReturnSummary';

const CreateReturnSummary = ({ summary, parcelConfirmationDetails, handleParcelConfirmationInputChange, parcelConfirmationValidation, 
    isHomeCollection, selectedAddressFull, hasValidSelazarIntegration }) =>
    <Fragment>
        <h4 className="mt-2 mb-3">Return Information</h4>
        <ReturnSummary
            summary={summary}
            parcelConfirmationDetails={parcelConfirmationDetails}
            handleParcelConfirmationInputChange={handleParcelConfirmationInputChange}
            parcelConfirmationValidation={parcelConfirmationValidation}
            isHomeCollection={isHomeCollection}
            selectedAddressFull={selectedAddressFull}
            hasValidSelazarIntegration={hasValidSelazarIntegration} />
    </Fragment>

export default CreateReturnSummary;