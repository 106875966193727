import React, { Component } from 'react';
import { IssueCard } from '../shared/Snippets';

export class Issue extends Component {

    render() {
        return (
           <IssueCard title="Create Account" message="There seems to be an issue with account creation"/>
        )
    }

}