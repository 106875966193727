import React, { Component } from 'react';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import RRLogo from '../../common/RRLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { ErrorAlert } from '../../common/Alerts';
import { POST } from '../../../Consumer';

const Success = ({ onSuccess }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>Account Created</h3>
                <div className="d-block text-center m-5">
                    <FontAwesomeIcon className="block text-center green" icon={faCheckCircle} size="4x" />
                </div>
                <div className="text-center">
                    <p>You have successfully registered for Return Robin.</p>
                </div>
                <Button variant="primary" block onClick={onSuccess}>Login</Button>
            </div>
        </div>
    </div>
);

const Error = ({ errorMessages }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>Error</h3>
                <div>
                    {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                </div>
            </div>
        </div>
    </div>
);

export class EmailVerification extends Component {

    constructor(props) {
        super(props);

        this.state = {
            passwordresetid: this.props.match.params.verificationid,
            errorMessages: [],
            accountCreated: false,
            loading: true
        }
    }

    async componentDidMount() {
        await this.retrieveVerificationResult();
        this.setState({ loading: false });
    }

    retrieveVerificationResult = () => {
        const { passwordresetid } = this.state;

        return POST(Endpoints.RETAILER.POST.VERIFY + "/" + passwordresetid)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else this.setState({ accountCreated: true });

                this.setState({ loading: false });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue verifying the account."], loading: false }));
    }

    goToLogin = () => {
        this.props.history.push('/retailer/login');
    }

    render() {
        const { loading, errorMessages, accountCreated } = this.state;

        return (
            <React.Fragment>
                {loading && <LoadingBar /> }
                {accountCreated ? <Success onSuccess={this.goToLogin} /> : <Error errorMessages={errorMessages} />}
            </React.Fragment>
        )
    }
}