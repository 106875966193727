import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const GoogleMapContainer = ({ searchLocation, markers, markerSelectedIndex, icon, selectedIcon, mapStyles, mapZoom, markerOnClick, mapOnClick }) => { 

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const determineMarkerIcon = (index) => {
    const isSelected = index === markerSelectedIndex || (markerSelectedIndex == null && index === 0);
    return { url: isSelected ? selectedIcon : icon, scaledSize: { width: 50, height: 50 }};
  }

  const collectPlusMarkers =
    markers.map((location, index) => {
      return (
      <Marker key={index} position={{lat: Number(location.lat), lng: Number(location.long) }} icon={determineMarkerIcon(index)} onClick={() => markerOnClick(index)} />
      )
    });
  
      return (
         <LoadScript
           googleMapsApiKey={GOOGLE_API_KEY}>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={mapZoom}
              onClick={mapOnClick}
              center={searchLocation}>
              <Marker key={"searchLocation"} position={{ lat: Number(searchLocation.lat), lng: Number(searchLocation.lng) }} />
              {collectPlusMarkers}
            </GoogleMap>
         </LoadScript>
      )
}

export default GoogleMapContainer;