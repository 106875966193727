import React, { Component } from 'react';
import HomeHeader from '../shared/HomeHeader';
import HomeFooter from '../shared/HomeFooter';
import { Link } from 'react-router-dom';

import './FAQ.scss';

class TrackReturn extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={false} />
                <div className="faq-container">
                    <div className="faq-text">
                        <h1>How do I track my return?</h1>
                        <p>You can track and monitor the progress of your return until it reaches its final destination.</p>
                        <p>Simply <Link to="/login">log in</Link> to your Return Robin account and view the return. You will see an up-to-date status, as well as a tracking link for further details on progress.</p>
                        <p>We will also keep you notified of important steps through email, and a text message the day before pickup.</p>
                    </div>
                </div>
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default TrackReturn;