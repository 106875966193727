import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Confirm from '../../../common/modals/Confirm';
import { formatDateTime } from '../../../../Utilities';

const DebitStatus = ({ status }) =>
    status === "PENDING"
        ? <span className="status-pending">Pending</span>
        : status === "ACTIVE"
            ? <span className="status-completed">Active</span>
            : <span className="status-failed">Inactive</span>

const DirectDebitOverview = ({ accountDetails, showRemoveModal, handleShowHideRemoveModal, handleRemoveAction }) =>
    <Row>
        <Col sm={12} md={6}>
            <section>
                <p className="title">Name</p>
                <p>{accountDetails.name}</p>
                <p className="title">Bank Account Type</p>
                <p>{accountDetails.type}</p>
                <p className="title">Last Status Check</p>
                <p>{accountDetails.status === "PENDING" ? "pending" : formatDateTime(accountDetails.lastChecked)}</p>
                <p className="title">Direct Debit Status</p>
                <p>
                    <DebitStatus status={accountDetails.status} />
                </p>
                <p className="title">Payment Term</p>
                <p>{accountDetails.paymentTerms} days</p>
            </section>
            <div className="float-right">
                <Button variant="danger" onClick={handleShowHideRemoveModal}>Remove Bank Account</Button>
            </div>
            <Confirm
                title="Remove Direct Debit"
                variant="outline-danger"
                text="Are you sure you want to remove your direct debit?"
                handleClose={handleShowHideRemoveModal}
                handleConfirmAction={handleRemoveAction}
                buttonText="Remove Direct Debit"
                linkText="Cancel"
                closeLink={true}
                show={showRemoveModal}
            />
        </Col>
    </Row>

export default DirectDebitOverview;
