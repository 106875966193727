import React, { Fragment } from 'react';
import ReturnAddress from './ReturnAddress';
import { Col, Row } from 'react-bootstrap';
import { AddAddressModal } from '../../../address/customer/AddAddressModal';

const CreateReturnAddress = ({fetchAddresses, userAddresses, selectedAddressId, handleAddressSelection }) =>
{

    const AddressTitle = () =>
        <section>
            <h4>Return Method</h4>
            <p className="title">Select Collection Address</p>
        </section>

    const AddressSelection = ({ userAddresses, selectedAddressId, handleAddressSelection }) =>
        <Row>
            {userAddresses ? userAddresses.map(userAddress =>
                <Col sm={12} md={7} key={userAddress.id} className="px-2">
                    <ReturnAddress address={userAddress.address} addressID={userAddress.id} selected={selectedAddressId === userAddress.id} defaultAddress={userAddress.default} handleAddressSelection={handleAddressSelection} />
                </Col>
            ) : null}
        </Row>

    return (    
        <Fragment>
            <AddressTitle />
            <AddressSelection userAddresses={userAddresses} selectedAddressId={selectedAddressId} handleAddressSelection={handleAddressSelection} />
            <AddAddressModal fetchAddresses={fetchAddresses}/>
        </Fragment>

    )
}

export default CreateReturnAddress;