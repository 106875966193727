import React, { Component } from 'react';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { GET } from '../../../../Consumer';

class DirectDebitComplete extends Component {

    async componentDidMount() {
        await this.getLatestDirectDebitDetails();
    }

    getLatestDirectDebitDetails = () => GET(Endpoints.FINANCE.DIRECT_DEBIT.GET.LATEST)
        .then(response => {
            if (response.ok) return response.json();
        })
        .then(results => {
            const data = results ? results.data : [];
            this.props.history.push({ pathname: "/retailer/settings/payments/directdebit", state: { addSuccess: data.status === "PENDING" ? true : false } })
        })
        .catch(error => {
            console.log(error);
        });

    render() {
        return (
            <LoadingBar />
        )
    }
}

export default DirectDebitComplete;