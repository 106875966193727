import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { WarningAlert } from './Alerts';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

const VERIFICATION_TITLE = "Verification Pending";
const VERIFICATION_MESSAGE = "You can add an integration to a fulfilment partner to speed up the verification."

const SERVICE_SELECT_TITLE = "Returns Service Selection";
const SERVICE_SELECT_MESSAGE = "Please select a returns service to become an active retailer."

const UnverifiedRetailer = ({ title, hasServiceSelected, isRetailerVerified }) => {

    const { push } = useHistory();

    return (<div className="main-content">
        <h3>{title ? title : "Welcome to Return Robin!"}</h3>
        <Row>
            <Col sm={12} md={6}>
                {!hasServiceSelected && <WarningAlert heading={SERVICE_SELECT_TITLE} message={SERVICE_SELECT_MESSAGE} />}
                {!isRetailerVerified && <WarningAlert heading={VERIFICATION_TITLE} message={VERIFICATION_MESSAGE} />}
                <Button variant="primary" className='float-right' onClick={() => push('/retailer/settings')}>
                    <FontAwesomeIcon icon={faCogs} size="1x" />Go to Settings
                </Button>
            </Col>
        </Row>
    </div>)
}

export default UnverifiedRetailer;
