import React, { Component } from 'react';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { GET } from '../../../../Consumer';

class CardReturn extends Component {

    async componentDidMount() {
        await this.getLatestCardDetails();
    }

    getLatestCardDetails = () => GET(Endpoints.FINANCE.CARD.GET.LATEST)
        .then(response => {
            if (response.ok) return response.json();
        })
        .then(results => {
            const latestCardDetails = results ? results.data : [];
            this.props.history.push({ pathname: "/retailer/settings/payments", state: { showCompleteError: !latestCardDetails.isActive, completeErrorMessage: latestCardDetails.message } })
        })
        .catch(error => {
            console.log(error);
            this.props.history.push({ pathname: "/retailer/settings/payments", state: { showCompleteError: false } })
        });

    render() {
        return (
            <LoadingBar />
        )
    }
}

export default CardReturn;