import React, { Component } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import FormValidator from '../../common/FormValidator';
import { setCaretPosition, isEmpty } from '../../../Utilities';
import RRLogo from '../../common/RRLogo';
import Mobile from '../../common/Mobile';
import 'react-intl-tel-input/dist/main.css';
import { POST_WITH_HEADER } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { WarningAlert, ErrorAlert } from '../../common/Alerts';
import LoadingBar from '../../common/LoadingBar';
import { Redirect } from 'react-router-dom';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export class MobileVerification extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'phoneNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'This is not a valid phone number, try again.'
            },
        ]);

        this.state = {
            form: {
                phoneNumber: '',
            },
            validation: this.validator.valid(),
            error: false,
            errorMessages: [],
            signInRequired: false,
            heading: '',
            warning: false,
            identifier: this.props.location.state !== undefined ?  this.props.location.state.identifier : undefined,
            loading: false
        };
    }

    validate = (form) => {
        let validation = this.validator.validate(form);

        if (!isEmpty(form.phoneNumber)) {
            try {
                const phone = phoneUtil.parse(form.phoneNumber, form.countryCode);
                if (!phoneUtil.isValidNumber(phone)) {
                    validation.isValid = false;
                    validation.phoneNumber.isInvalid = true;
                    validation.phoneNumber.message = "This is not a valid phone number, try again.";
                }
            } catch (e) {
                console.log(e);
                validation.isValid = false;
                validation.phoneNumber.isInvalid = true;
                validation.phoneNumber.message = "This is not a valid phone number, try again.";
            }
        }
        return validation;
    }

    handlePhoneNumberChange = (...args) => {
        setCaretPosition(args[3].length);

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{
                    phoneNumber: args[3],
                    countryCode: args[2].iso2
                }
            }
        }));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { form, identifier } = this.state;

        const validation = this.validate(form);
        this.setState({ validation: validation });
        this.submitted = true;

        if (validation.isValid) {
            return POST_WITH_HEADER(Endpoints.USER.POST.VERIFY_MOBILE_NUMBER, identifier, form)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null) this.setState({ warning: result.data.warning ?? false, heading: result.data.heading, signInRequired: result.data.signInRequired ?? false, errorMessages: result.data.errorMessages, loading: false });
                        this.setState({ error: true, errorMessages: result.message, loading: false });
                    }
                    else this.props.history.push({pathname: "/register/accountverification", state: {userid: result.data.user.id, phoneNumber: result.data.user.phoneNumber}});
                })
                .catch(() => this.setState({ errorMessages: ["There has been an issue submitting verification."], loading: false }));
        } else {
            this.setState({ loading: false });
        }
    }

    renderForm = () => {
        let { form, validation, errorMessages, heading, signInRequired } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                {errorMessages.length > 0  && <ErrorAlert messages={errorMessages} heading={heading} />}
                {signInRequired && <WarningAlert linkRequired={signInRequired} link="login" linkText="Sign in" linkPostText="to use Return Robin." />}
                
                <Mobile invalidPhoneNumber={validation.phoneNumber.isInvalid} value={form.phoneNumber} handlePhoneNumberChange={this.handlePhoneNumberChange} validationMessage={validation.phoneNumber.message} />

                <FormGroup>
                    <Button variant="primary" block type="submit" value="Submit">Confirm Mobile Number</Button>
                </FormGroup>
            </Form>
        )
    }

    renderRedirect = () => {
        if (this.state.identifier === undefined) {
          return <Redirect to='/' />
        }
      }

    render() {
        const { loading } = this.state;

        return (
            <div className="center">
                <div className="container">
                    {loading && <LoadingBar />}
                    <RRLogo />
                    <div className="center-block mb-5">
                        <h3>Create an Account</h3>
                        <p>To use Return Robin we need your mobile number.</p>
                        {this.renderForm()}
                        {this.renderRedirect()}
                    </div>
                </div>
            </div>
        )
    }
}