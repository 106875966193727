import React, { Component } from 'react';
import HomeHeader from '../shared/HomeHeader';
import HomeFooter from '../shared/HomeFooter';

import './FAQ.scss';

class MultipleReturns extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={false} />
                <div className="faq-container">
                    <div className="faq-text">
                        <h1>I have multiple returns, what do I do?</h1>
                        <p>Return Robin provides an easy way to manage all of your returns with your favourite retailers.</p> 
                        <p>Just sign into the Return Robin platform, and log each return using the “Create a Return” button.</p>
                        <p>You can then amend, track and monitor each return individually. If you have multiple items to return from the same original order, you can create a single return.</p> 
                        <p>Just make sure all items fit in a parcel that is not above the maximum allowance.</p>
                    </div>
                </div>
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default MultipleReturns;