import React, { Component } from 'react';
import Endpoints from '../../common/Endpoints';
import { POST } from '../../../Consumer';
import { PasswordLinkSentCard } from '../shared/Snippets';

class PasswordLinkSent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: this.props.location.state !== undefined ? this.props.location.state.email : undefined,
            errorMessages: [],
            warningMessages: []
        }
    }

    handleLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    }

    handleResendRequest = (e) => {
        e.preventDefault();
        
        const { email } = this.state
        const viewModel = { Email: email };

        return POST(Endpoints.PASSWORD.POST.RESET_REQUEST, viewModel)
            .then(response => response.json())
            .then((result) => {
                if (result.error) result.data !== null ? this.setState({ errorMessages: result.data, warningMessages: [] }) : this.setState({ errorMessages: result.message, warningMessages: [] });
                else this.setState({ warningMessages: ['Reset password link has been resent'], errorMessages: [] });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue resending link."], warningMessages: [] }));
    }

    render() {
        const { email, errorMessages, warningMessages } = this.state

        return (
            <PasswordLinkSentCard email={email} warningMessages={warningMessages} errorMessages={errorMessages} handleResendRequest={this.handleResendRequest} handleLogin={this.handleLogin} />
        )
    }
}

export default PasswordLinkSent;