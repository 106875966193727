import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumb from '../common/Breadcrumb';

const Description = () => (
    <section>
        <p className="title">What is a Robin Score?</p>
        <p>Your Robin score is a number from 0 to 100 that represents your history with using Return Robin. The higher the score, the better, as this tells the retailer that you are a good candidate to be using Return Robin.</p>
    </section>
);

const Impacts = () => (
    <section>
        <p className="title">What impacts my Robin Score?</p>
        <p>When your return parcel is collected successfully you will gain points. You'll also gain more points every 28 days just by being an active customer. The only instance that your score can decrease is if there was a failed collection. This means if a courier attempted to collect your return parcel at your desired location and was unable to do so. If the return collection failed due to the courier, this will not impact your score.</p>
        <p>You need to have at least 30 points to create a return. If your score goes below 30 you will be unable to use the return service until it increases again.</p>
    </section>
);


class FAQ extends React.Component {
    render() {
        return (
            <Container className="" fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <Breadcrumb link="/profile" text="Back to Your Profile" />
                        <h3 className="mb-3">Robin Score FAQs</h3>
                        <Description />
                        <Impacts />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default FAQ;