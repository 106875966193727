import React, { Component, Fragment } from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import FormValidator from '../../../common/FormValidator';
import LoadingBar from '../../../common/LoadingBar';
import { RetailerCard } from '../../../common/inputs/AddressSelectionCards';
import Slider from '../../../common/inputs/Slider';
import { ErrorAlert } from '../../../common/Alerts';
import { GET, POST, extractData } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import { BANK_ACCOUNT_TYPES } from '../../../common/constants';

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

const DebitActions = ({ handleCancelForm, addDebitDetails }) =>
    <FormGroup>
        <div className="float-right d-none d-sm-block">
            <Button variant="link" className="underline" type="button" onClick={handleCancelForm}>Cancel</Button>
            <Button variant="primary" onClick={addDebitDetails}>Add Details</Button>
        </div>
        <div className="d-block d-sm-none text-center">
            <Button variant="link" className="underline" type="button" onClick={handleCancelForm}>Cancel</Button>
            <Button variant="primary" block onClick={addDebitDetails}>Add Details</Button>
        </div>
    </FormGroup>

const AccountDetails = ({ bankAccountType, bankAccountValidation, handleAccountHolderInputChange }) =>
    <Fragment>
        <h3 className="my-3">Account Details</h3>
        <FormGroup>
            <FormLabel htmlFor="bankAccountType">Bank Account Type</FormLabel>
            <div className="form-input-description mb-3">
                <p>Currently we are only able to accept UK bank accounts</p>
            </div>
            <FormControl id="bankAccountType" name="bankAccountType" as="select" onChange={(e) => handleAccountHolderInputChange("bankAccountType", e.target.value)} value={bankAccountType}>
                <option key="" value="">Select Account Type...</option>;
                {BANK_ACCOUNT_TYPES.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)}
            </FormControl>
            <span className="text-danger">{bankAccountValidation.bankAccountType.message}</span>
        </FormGroup>
    </Fragment>

const AccountHolderCustomAddress = ({ addressDetails, addressDetailsValidation, handleAddressDetailsInputChange }) =>
    <Fragment>
        <FormGroup>
            <FormLabel htmlFor="line1">Address Line 1</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="line1" onChange={(e) => handleAddressDetailsInputChange("line1", e.target.value)} value={addressDetails.line1} />
            <span className="text-danger">{addressDetailsValidation.line1.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="line2">Address Line 2  <span className="form-label-optional font-weight-normal">- optional</span></FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="line2" onChange={(e) => handleAddressDetailsInputChange("line2", e.target.value)} value={addressDetails.line2} />
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="city">Town</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="city" onChange={(e) => handleAddressDetailsInputChange("city", e.target.value)} value={addressDetails.city} />
            <span className="text-danger">{addressDetailsValidation.city.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="postalCode">Postcode</FormLabel>
            <FormControl type="text" size="20" maxLength="20" id="postalCode" onChange={(e) => handleAddressDetailsInputChange("postalCode", e.target.value)} value={addressDetails.postalCode} />
            <span className="text-danger">{addressDetailsValidation.postalCode.message}</span>
        </FormGroup>
    </Fragment>

const AccountHolderAddress = ({ retailerAddress, customAddress, addressDetails, addressDetailsValidation, handleToggleChange, handleAddressDetailsInputChange }) =>
    <Fragment>
        <h3 className="my-3">Account Holder Address</h3>
        <RetailerCard address={retailerAddress} selected={!customAddress} />
        <div className="my-3">
            <Slider id="customAddress" name="customAddress" label="Use another address" checked={customAddress} onChange={() => handleToggleChange(!customAddress)} />
        </div>
        {customAddress && <AccountHolderCustomAddress addressDetails={addressDetails} addressDetailsValidation={addressDetailsValidation} handleAddressDetailsInputChange={handleAddressDetailsInputChange} />}
    </Fragment>

const AccountHolderDetails = ({ customerName, email, phone, accountHolderValidation, handleAccountHolderInputChange }) =>
    <Fragment>
        <h3 className="my-3">Account Holder Details</h3>
        <FormGroup>
            <FormLabel htmlFor="customerName">Name</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="customerName" onChange={(e) => handleAccountHolderInputChange("customerName", e.target.value)} value={customerName} />
            <span className="text-danger">{accountHolderValidation.customerName.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <FormControl type="email" id="email" onChange={(e) => handleAccountHolderInputChange("email", e.target.value)} size="40" name="email" value={email} />
            <span className="text-danger">{accountHolderValidation.email.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="phone">Phone</FormLabel>
            <FormControl type="text" size="20" maxLength="20" id="phone" onChange={(e) => handleAccountHolderInputChange("phone", e.target.value)} name="phone" value={phone} />
            <span className="text-danger">{accountHolderValidation.phone.message}</span>
        </FormGroup>
    </Fragment>


class DirectDebitForm extends Component {

    accountHolderValidator = new FormValidator([
        {
            field: 'customerName',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the account holder's name`
        },
        {
            field: 'email',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your email address'
        },
        {
            field: 'phone',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your phone number'
        }
    ]);

    addressDetailsValidator = new FormValidator([
        {
            field: 'line1',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter address line 1 of the account holder's address`
        },
        {
            field: 'city',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the town of the account holder's address`
        },
        {
            field: 'postalCode',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the postcode of the account holder's address`
        }
    ]);

    bankAccountValidator = new FormValidator([
        {
            field: 'bankAccountType',
            method: 'isEmpty',
            validWhen: false,
            message: `Please select the bank account type`
        },
    ]);


    state = {
        loading: true,
        holderDetails: {
            customerName: "",
            email: "",
            phone: "",
        },
        bankAccountDetails: {
            bankAccountType: ""
        },
        addressDetails: {
            line1: "",
            line2: "",
            city: "",
            postalCode: ""
        },
        retailerAddress: {},
        customAddress: false,
        accountHolderValidation: this.accountHolderValidator.valid(),
        addressDetailsValidation: this.addressDetailsValidator.valid(),
        bankAccountValidation: this.bankAccountValidator.valid(),
        errorMessages: [],
    }

    async componentDidMount() {
        await this.getRetailerAddress();
        this.setState({ loading: false });
    }

    handleAccountHolderInputChange = (name, value) => this.setState(prevState => ({ holderDetails: { ...prevState.holderDetails, [name]: value } }));
    handleAddressDetailsInputChange = (name, value) => this.setState(prevState => ({ addressDetails: { ...prevState.addressDetails, [name]: value } }));
    handleBankAccountInputChange = (name, value) => this.setState(prevState => ({ bankAccountDetails: { ...prevState.bankAccountDetails, [name]: value } }));
    handleToggleChange = (value) => this.setState({ customAddress: value });

    getRetailerAddress = () =>
        GET(Endpoints.SETTINGS.GET.RETAILER_ADDRESS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const retailerAddress = results ? results.data[0] : {};
                this.setState({ retailerAddress: retailerAddress });
            })
            .catch(error => console.log(error));

    addDebitDetails = async () => {
        const { holderDetails, addressDetails, bankAccountDetails, customAddress, retailerAddress } = this.state;
        this.setState({ loading: true });

        if (customAddress) {
            const addressDetailsValidation = this.addressDetailsValidator.validate(addressDetails);
            this.setState({ addressDetailsValidation: addressDetailsValidation });

            if (!addressDetailsValidation.isValid) {
                this.setState({ loading: false });
                return;
            }
        }

        const accountHolderValidation = this.accountHolderValidator.validate(holderDetails);
        const bankAccountValidation = this.bankAccountValidator.validate(bankAccountDetails)
        this.setState({ accountHolderValidation: accountHolderValidation, bankAccountValidation: bankAccountValidation });

        if (!accountHolderValidation.isValid || !bankAccountValidation.isValid) {
            this.setState({ loading: false });
            return;
        };

        const stripe = await loadStripe(STRIPE_API_KEY);

        const formData = {
            name: holderDetails.customerName,
            email: holderDetails.email,
            phone: holderDetails.phone,
            address: {
                line1: customAddress ? addressDetails.line1 : retailerAddress.line1,
                line2: customAddress ? addressDetails.line2 : retailerAddress.line2,
                city: customAddress ? addressDetails.city : retailerAddress.city,
                postalCode: customAddress ? addressDetails.postalCode : retailerAddress.postcode
            },
            successURL: "/retailer/settings/payments/directdebit/complete",
            cancelURL: "/retailer/settings/payments/directdebit/cancelled",
            bankAccountType: bankAccountDetails.bankAccountType
        }


        POST(Endpoints.FINANCE.DIRECT_DEBIT.POST.CREATE, formData)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    if (data) {
                        stripe.redirectToCheckout({ sessionId: data.id });
                        this.setState({ showForm: false });
                    }
                    else this.setState({ furtherAuth: data.id });
                }
                else this.setState({ errorMessages: ["An error occurred:" + result.message] })
            })
            .catch(() => this.setState({ errorMessages: ["There was an error saving the card details."] }));
    }

    render() {
        const { loading, holderDetails, accountHolderValidation, bankAccountDetails, bankAccountValidation, addressDetails, addressDetailsValidation, retailerAddress, customAddress, errorMessages } = this.state;
        const { handleCancelForm } = this.props;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                    <Row>
                        <Col sm={12} md={6}>
                            <AccountHolderDetails
                                customerName={holderDetails.customerName}
                                email={holderDetails.email}
                                phone={holderDetails.phone}
                                accountHolderValidation={accountHolderValidation}
                                handleAccountHolderInputChange={this.handleAccountHolderInputChange}
                            />
                            <AccountHolderAddress
                                retailerAddress={retailerAddress}
                                customAddress={customAddress}
                                addressDetails={addressDetails}
                                addressDetailsValidation={addressDetailsValidation}
                                handleToggleChange={this.handleToggleChange}
                                handleAddressDetailsInputChange={this.handleAddressDetailsInputChange}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <AccountDetails
                                bankAccountType={bankAccountDetails.bankAccountType}
                                bankAccountValidation={bankAccountValidation}
                                handleAccountHolderInputChange={this.handleBankAccountInputChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DebitActions handleCancelForm={handleCancelForm} addDebitDetails={this.addDebitDetails} />
                        </Col>
                    </Row>
                </Fragment>
        )
    }
}

export default DirectDebitForm;