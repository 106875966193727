import React, { Component, Fragment } from 'react';
import LoadingBar from '../../../common/LoadingBar';
import SettingsNav from '../../../common/nav/SettingsNav';
import SubNav from '../../../common/nav/SubNav';
import UnverifiedRetailer from '../../../common/UnverifiedRetailer';
import { PAYMENTS_SUB_NAV, PAYMENTS_SUB_NO_DD_NAV } from '../../../common/constants';
import Endpoints from '../../../common/Endpoints';
import { GET, PUT, extractData } from '../../../../Consumer';
import DirectDebitNoLink from './DirectDebitNoLink';
import DirectDebitOverview from './DirectDebitOverview';
import DirectDebitForm from './DirectDebitForm';
import DirectDebitCancelledWarning from './DirectDebitCancelledWarning';
import DirectDebitSuccess from './DirectDebitSuccess';

class DirectDebit extends Component {

    state = {
        loading: true,
        accountDetails: null,
        showForm: false,
        paymentTerms: '',
        showRemoveModal: false,
        enabledForDirectDebit: false,
        cancelled: this.props.location.state !== undefined ? this.props.location.state.cancelled : false,
        addSuccess: this.props.location.state !== undefined ? this.props.location.state.addSuccess : false
    }

    async componentDidMount() {
        await this.enabledForDirectDebit();
        await this.getLatestDirectDebitDetails();
        this.setState({ loading: false });
    }

    enabledForDirectDebit = () =>
        GET(Endpoints.FINANCE.DIRECT_DEBIT.GET.ENABLED)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                if (data != null) {
                    this.setState({ enabledForDirectDebit: data })
                }
            })
            .catch(error => console.log(error));

    getLatestDirectDebitDetails = async () =>
        GET(Endpoints.FINANCE.DIRECT_DEBIT.GET.CURRENT)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    if (data != null) {
                        const accountData = JSON.parse(data.directDebitDetailsContentsPayload).AccountHolder.Name;
                        if (accountData) this.setState({ accountDetails: { name: accountData, bankAccountType: data.bankAccountType, paymentTerms: data.paymentTerms, type: data.bankAccountType, lastChecked: data.lastChecked, status: data.status } });
                    }
                    else this.setState({ accountDetails: null })
                }
            })
            .catch(error => console.log(error))

    onCancelForm = async () => {
        this.setState({ loading: true, showForm: false, showRemoveModal: false });
        await this.getLatestDirectDebitDetails()
        this.setState({ loading: false });
    }

    handleNoLinkClick = () => this.setState({ showForm: true });
    handleCancelForm = () => this.setState({ showForm: false });
    handleShowHideRemoveModal = () => this.setState(prevState => ({ showRemoveModal: !prevState.showRemoveModal }));

    handleRemoveAction = async () => {
        PUT(Endpoints.FINANCE.DIRECT_DEBIT.PUT.DISABLE)
            .then(res => {
                if (res && res.ok) return res.json();
                else return null;
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    if (data) {
                        this.setState({ accountDetails: null, showRemoveModal: false });
                        this.onCancelForm();
                    }
                }
            }, err => { console.error(err); })
            .catch(error => console.log(error));
    }

    handleReplaceClick = () => this.setState({ showForm: true });
    handleBackClick = () => this.setState({ showForm: true, cancelled: false });

    render() {
        const { loading, accountDetails, paymentTerms, showForm, addSuccess, showRemoveModal, cancelled, enabledForDirectDebit } = this.state;
        const { showVerification } = this.props;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <SettingsNav activeTitle="Payments" />
                    <div className="nav-content">
                        {showVerification
                            ? <UnverifiedRetailer history={this.props.history} />
                            : <Fragment>
                                <SubNav title="Payments" subNavList={enabledForDirectDebit ? PAYMENTS_SUB_NAV : PAYMENTS_SUB_NO_DD_NAV} activeKey="Direct Debit" />
                                <div className="main-content pt-2">
                                    {cancelled
                                        ? <DirectDebitCancelledWarning handleBackClick={this.handleBackClick} />
                                        :
                                        <Fragment>
                                            {addSuccess && <DirectDebitSuccess />}
                                            {(accountDetails && !showForm)
                                                ? <DirectDebitOverview
                                                    accountDetails={accountDetails}
                                                    paymentTerms={paymentTerms}
                                                    showRemoveModal={showRemoveModal}
                                                    handleShowHideRemoveModal={this.handleShowHideRemoveModal}
                                                    handleRemoveAction={this.handleRemoveAction}
                                                    handleReplaceClick={this.handleReplaceClick}
                                                />
                                                : showForm
                                                    ? <DirectDebitForm handleCancelForm={this.handleCancelForm} />
                                                    : !addSuccess && <DirectDebitNoLink handleNoLinkClick={this.handleNoLinkClick} />
                                            }
                                        </Fragment>
                                    }
                                </div>
                            </Fragment>
                        }
                    </div>
                </Fragment>
        )
    }
}

export default DirectDebit;