import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import LoadingBar from '../common/LoadingBar';
import { ScoreDetails, ScoreInfo } from './shared/Snippets';
import Endpoints from '../common/Endpoints';
import { GET } from '../../Consumer';
import { ErrorAlert } from '../common/Alerts';

import './shared/Profile.scss';

const ContactDetails = ({ contactDetails }) => (
    <section>
        <h4>Contact Details</h4>
        <p className="title">Email Address</p>
        <p>{contactDetails.email}</p>
        <p className="title">Mobile Number</p>
        <p>{contactDetails.mobile}</p>
    </section>
);

class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: "",
            profile: {}
        };
    }

    async componentDidMount() {
        await this.getProfile();
        this.setState({ loading: false });
    }

    getProfile = () => {
        return GET(Endpoints.SCORE.GET.PROFILE)
            .then(response => response.json())
            .then(result => {
                const profile = result.data ? result.data.profile : null;
                const error = profile ? "" : "We are currently unable to retrieve profile details.";
                this.setState({ profile: profile, error: error });
            });
    }

    render() {
        const { profile, error, loading } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <div className="main-content profile-details">
                        <Row>
                            <Col sm={12} md={6}>
                                <h3 className="mb-3">Your Profile</h3>
                                {error && <ErrorAlert messages={error} /> }
                                {!error && <ContactDetails contactDetails={profile.contactDetails} />}
                            </Col>
                        </Row>
                        {!error && <ScoreDetails title="Robin Score" scoreDetails={profile.scoreDetails} />}
                        <Row>
                            <Col sm={12} md={4}>
                                <Button variant="secondary" onClick={() => this.props.history.push('/profile/scorehistory')} block>View Robin Score History</Button>
                                <ScoreInfo />
                            </Col>
                        </Row>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default Profile;