import React, { Fragment, useEffect, useState } from 'react';
import LoadingBar from '../../../common/LoadingBar';
import SettingsNav from '../../../common/nav/SettingsNav';
import SubNav from '../../../common/nav/SubNav';
import { PAYMENTS_SUB_NAV, PAYMENTS_SUB_NO_DD_NAV } from '../../../common/constants';
import Endpoints from '../../../common/Endpoints';
import { GET_ASYNC, DELETE } from '../../../../Consumer';
import AddCard from './AddCard';
import CompleteCard from './CompleteCard';
import CardForm from './CardForm';
import CardDetails from './CardDetails';
import { useHistory } from 'react-router';
import { WarningAlert } from '../../../common/Alerts';

const CardPayment = ({ location }) => {

    //#region State
    const history = useHistory();

    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cardDetails, setCardDetails] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [showCompleteError] = useState(location?.state?.showCompleteError || false);
    const [completeErrorMessage] = useState(location?.state?.completeErrorMessage || undefined);

    const [isRetailer, setIsRetailer] = useState(false);
    const [enabledForDirectDebit, setEnabledForDirectDebit] = useState(false);

    //#endregion

    //#region API

    useEffect(() => {
        (async () => {
            try {
                const response = await GET_ASYNC(Endpoints.RETAILER.GET.IS_RETAILER);

                if (response?.ok) {
                    const data = await response.json();
                    if (data.data === true) {
                        setIsRetailer(true);
                    }
                    else {
                        localStorage.clear();
                        history.push('/login');
                    }
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
                setShowError(true);
            }
        })();
    }, [setIsRetailer, history]);

    useEffect(() => {
        if (isRetailer) {
            getLatestCardDetails();
            checkForDirectDebit();
            setLoading(false);
        }
    }, [isRetailer]);

    const getLatestCardDetails = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.FINANCE.CARD.GET.CURRENT);

            if (response?.ok) {
                const data = await response.json();
                if (data.data != null) setCardDetails({ name: data.data.name, cardSummary: data.data.cardNumber, active: data.data.active });
                else setCardDetails(null);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowError(true);
        }
    }

    const onRemoveCardAction = async () => {
        try {
            const response = await DELETE(Endpoints.FINANCE.CARD.DELETE.REMOVE);

            if (response?.ok) {
                const data = await response.json();
                if (data != null) setCardDetails(null)
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowError(true);
        }
    }

    const checkForDirectDebit = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.FINANCE.DIRECT_DEBIT.GET.ENABLED);

            if (response?.ok) {
                const data = await response.json();
                if (data != null) {
                    setEnabledForDirectDebit(data.data)
                }
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowError(true);
        }
    }


    //#endregion

    //#region Controls

    const onAddCardClick = () => setShowForm(true);
    const onReplaceCardClick = () => setShowForm(true);
    const hideShowRemoveModal = () => setShowRemoveModal(!showRemoveModal);

    //#endregion

    //#region Render

    if (showError) {
        return (
            <>
                <SettingsNav activeTitle="Payments" />
                <div className="nav-content">
                    <div className="main-content mt-2">
                        <WarningAlert linkRequired="true" link="retailer/settings/payments" linkPreText="An API error has occurred, please try again." linkText="Reload" />
                    </div>
                </div>
            </>
        );
    }

    return (
        loading
            ? <LoadingBar />
            : <Fragment>
                <SettingsNav activeTitle="Payments" />
                <div className="nav-content">
                    <Fragment>
                        <SubNav title="Payments" subNavList={enabledForDirectDebit ? PAYMENTS_SUB_NAV : PAYMENTS_SUB_NO_DD_NAV} activeKey="Card Payment" />
                            <div className="main-content mt-2">
                                {showCompleteError
                                    ? <CompleteCard errorMessages={completeErrorMessage} />
                                    : (cardDetails && !showForm)
                                        ? <CardDetails
                                            details={cardDetails}
                                            showRemoveModal={showRemoveModal}
                                            hideShowRemoveModal={hideShowRemoveModal}
                                            onRemoveCardAction={onRemoveCardAction}
                                            onReplaceCardClick={onReplaceCardClick}
                                        />
                                        : showForm
                                            ? <CardForm />
                                            : <AddCard onAddCardClick={onAddCardClick} />
                                }
                            </div>
                    </Fragment>
                </div>
            </Fragment>
    );

    //#endregion
}

export default CardPayment;