import React, { useEffect, useState } from 'react'
import { Form, FormGroup, FormControl, InputGroup, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import GoogleMapContainer from '../../../common/GoogleMapContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faMap, faClock } from '@fortawesome/free-regular-svg-icons';

import './ReturnWizard.scss';

const CreateReturnLocationLookup = ({ showCollectPlusMap, collectPlusDropOffSearchError, collectPlusDropOffPoints, collectPlusSearchLocation, collectPlusDropOffPostCodeText, handleCollectPlusDropOffPostCodeChange, onCollectPlusDropOffPostCodeSearch }) => {

    const [selectedDropOffPointIndex, setSelectedDropOffPointIndex] = useState(null);
    const [activeAccordianItem, setActiveAccordionItem] = useState(0);
    const [showOpeningHours, setShowOpeningHours] = useState(false);

    const mapStyles = { height: "70vh", width: "100%" };
    const mapZoom = 13;

    const collectPlusMarkerIcon = 'Images/marker-white.svg';
    const collectPlusSelectedMarkerIcon = 'Images/marker-black.svg';

    const {visible: showError, message: errorMessage} = collectPlusDropOffSearchError;

    useEffect(() => {
        setShowOpeningHours(false);
    }, [activeAccordianItem])

    const CreateHeader = () =>
    <>
        <h4 className="mt-2 mb-3">Return Method</h4>
        <h6 className="mt-2 mb-3">Find your nearest drop-off point</h6>
        <p className='mt-1 mb-3'><i>(10kg max weight)</i></p>
    </>

    const CreateDropOffList = ({ collectPlusLocations }) => {
        return (
            <>
            <div className='mt-4 mb-4'>
                <Accordion activeKey={String(activeAccordianItem)} onSelect={(e) => setActiveAccordionItem(e)}>
                    {collectPlusLocations.map((location, index) => {
                        return (
                            <Card key={index}>
                                <Accordion.Toggle as={Card.Header} eventKey={String(index)} className='card-header-alternative'>
                                    <Row>
                                        <Col sm={10} md={10} lg={10}>
                                            <span><strong>{location.site_name}</strong></span>
                                        </Col>
                                        <Col sm={2} md={2} lg={2} className='float-right'>
                                            <span>{Number(location.miles).toFixed(1) + " miles"}</span>
                                        </Col>
                                    </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={String(index)}>
                                    <Card.Body className='ml-3 m-2'>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <p className='m-0'><FontAwesomeIcon icon={faMap} />{location.address + ", " + location.city + ", " + location.postcode}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                        <p className='m-0 opening-hours' onClick={() => setShowOpeningHours(!showOpeningHours)}><FontAwesomeIcon icon={faClock} />Opening Hours</p>
                                            {showOpeningHours &&
                                                         (
                                                            <>
                                                                <div className='m-3'>
                                                                    <p className='mb-0'><strong>Monday</strong>: {location.monday_open + "-" + location.monday_close}</p>
                                                                    <p className='mb-0'><strong>Tuesday</strong>: {location.tuesday_open + "-" + location.tuesday_close}</p>
                                                                    <p className='mb-0'><strong>Wednesday</strong>: {location.wednesday_open + "-" + location.wednesday_close}</p>
                                                                    <p className='mb-0'><strong>Thursday</strong>: {location.thursday_open + "-" + location.thursday_close}</p>
                                                                    <p className='mb-0'><strong>Friday</strong>: {location.friday_open + "-" + location.friday_close}</p>
                                                                    <p className='mb-0'><strong>Saturday</strong>: {location.saturday_open + "-" + location.saturday_close}</p>
                                                                    <p className='mb-0'><strong>Sunday</strong>: {location.sunday_open + "-" + location.sunday_close}</p>
                                                                </div>
                                                            </>
                                                        )
                                            }
                                        </Col>
                                    </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                        </Card>
                        )
                    })}
                </Accordion>
            </div>
            </>
        )
    }

    const handleMapInteraction = (index) => {
        setSelectedDropOffPointIndex(index);
        setActiveAccordionItem(0);
        setShowOpeningHours(false);
    }

    const markerClick = (index) => handleMapInteraction(index);

    const mapClick = () => handleMapInteraction(null);

    const dropOffPostcodeFormSubmit = (event) => {
        event.preventDefault();
        event.target.querySelector("input").blur();
        onCollectPlusDropOffPostCodeSearch();
    }

  return (
      <>  
        <CreateHeader />
        <Form onSubmit={dropOffPostcodeFormSubmit}>
            <FormGroup className='mb-4' >
            <InputGroup>
                <FormControl id="dropOffPostcode" className="" placeholder="Enter a postcode or location" type="text" name="postcode" maxLength="10" value={collectPlusDropOffPostCodeText} onChange={(e) => handleCollectPlusDropOffPostCodeChange(e)} />
                <InputGroup.Append>
                            <InputGroup.Text id='searchBox' className='search-icon' onClick={onCollectPlusDropOffPostCodeSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                </InputGroup.Append>
                </InputGroup>
            </FormGroup>
        </Form>
        {(showCollectPlusMap && !showError) &&
        <>
            <GoogleMapContainer searchLocation={collectPlusSearchLocation} markers={collectPlusDropOffPoints} markerSelectedIndex={selectedDropOffPointIndex} icon={collectPlusMarkerIcon} selectedIcon={collectPlusSelectedMarkerIcon} mapStyles={mapStyles} mapZoom={mapZoom} markerOnClick={markerClick} mapOnClick={mapClick}  />
            {selectedDropOffPointIndex === null ? 
                <CreateDropOffList collectPlusLocations={collectPlusDropOffPoints} />
             :
                <CreateDropOffList collectPlusLocations={Array.of(collectPlusDropOffPoints.at(selectedDropOffPointIndex))} />
             }
        </>  
        }
        {(!showCollectPlusMap && showError) && <p className='font-weight-bold'>{errorMessage}</p>}

    </>
  )
}

export default CreateReturnLocationLookup;