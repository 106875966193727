import React from 'react';
import { Table, Card } from 'react-bootstrap';
import Pagination from '../Pagination';

import './PagingTable.scss';

const PagingTable = ({ headers, rows, onNext, onPrevious, onStart, onEnd, pageIndex, pageCount }) =>
    <Card className="card-table">
        <Table bordered striped>
            <thead>
                <tr>
                    {headers.map((header, i) => <th key={`header-${i}`} className="pl-3">{header}</th>)}
                </tr>
            </thead>
            <tbody>
                {rows.length > 0 && rows.map((row, i) => <tr key={`row-${i}`}>{row.map((d, i) => <td key={`data-${i}`} className="pl-3">{d}</td>)}</tr>)}
            </tbody>
        </Table>
        <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
    </Card>

export default PagingTable;