import React from 'react';
import { Card } from 'react-bootstrap';

const SuccessAlert = ({ heading, linkRequired, linkPreText, linkPostText, link, linkText, message }) => (
    <Card className="card-success-custom mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>
            {linkRequired ? <p>{message} {linkPreText} <a href={link}>{linkText}</a> {linkPostText}</p> : <p>{message}</p>}
        </Card.Body>
    </Card>
);

const AddressChangeAlert = ({ heading, linkRequired, linkPreText, linkPostText, link, linkText, message }) => (
    <Card className="card-warning mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>
            {linkRequired ? <p>{message} {linkPreText} <a href={link}>{linkText}</a> {linkPostText}</p> : <p>{message}</p>}
        </Card.Body>
    </Card>
);

const InfoAlert = ({ heading, message }) => (
    <Card className="card-info mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>
            <p>{message}</p>
        </Card.Body>
    </Card>
);

const WarningAlert = ({ heading, linkRequired, linkPreText, linkPostText, link, linkText, message }) => (
    <Card className="card-warning mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>
            {linkRequired ? <p>{message} {linkPreText} <a href={link}>{linkText}</a> {linkPostText}</p> : <p>{message}</p>}
        </Card.Body>
    </Card>
);

const ErrorAlert = ({ heading, messages }) => (
    <Card className="card-danger mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>
            {typeof messages === 'string'
                ? <p>{messages}</p>
                : messages.map((e, i) => <p key={i}>{e}</p>)
            }
        </Card.Body>
    </Card>
);

export {
    SuccessAlert,
    AddressChangeAlert,
    InfoAlert,
    WarningAlert,
    ErrorAlert
}
