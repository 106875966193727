import React, { Component } from 'react';
import { AddressCheckCard } from '../shared/Snippets'; 

const SUMMARY_TEXT = "Before you can start using Return Robin we need to know the address that all your returns will be sent to.";
const BUTTON_TEXT = "Add returns address";
const INFO_TITLE = "Why do you need the returns address?";
const INFO_TEXT = "We need to know where to send your returned products to. This also allows us to work out the returns cost efficently."

class AddressCheck extends Component {
    
    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/retailer/address/firstaddress');
    }

    render() {

        return (
            <AddressCheckCard handleClick={this.handleClick} summaryText={SUMMARY_TEXT} buttonText={BUTTON_TEXT} infoTitle={INFO_TITLE} infoText={INFO_TEXT} />
        )
    }
}

export default AddressCheck;