import React from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap';
import { CreateReturnSummaryAccordion } from '../../shared/Snippets';
import { formatDate } from '../../../../Utilities';

const trailingInformation = "You can print or screenshot this barcode and if you don't have a smartphone you can take a note of the following barcode to give to the shop assistant: ";

const ReturnCreatedAlert = () => (
    <Card className="card-success-custom mb-3">
        <Card.Title>Return Created Successfully!</Card.Title>
        <Card.Body></Card.Body>
    </Card>
);

const ReturnDropOffBarcode = ({ barcode,  consignmentNumber, expiryDate }) => (
    <Card className='text-center card-background-alternative'>
        <Card.Title><h3>Your Print in Store barcode</h3></Card.Title>
        <Card.Body>
            <Image src={`data:image/jpeg;base64,${barcode}`} fluid={true} height='200px' width='200px' />
            <h5 className='mb-3'>{consignmentNumber}</h5>
            <h4>Expires: {formatDate(expiryDate)}</h4>
            <p>{trailingInformation}<strong>{consignmentNumber}</strong></p>
        </Card.Body>
    </Card>
);

// TODO - This needs updated to follow the designs.
const CreateHomeCollectionCard = ({ collectionDate, retailer, orderID, orderIDField }) => (
    <Card style={{backgroundColor: '#ebf2f7'}}>
        <Card.Title><h3 style={{color:'black'}}>Collection Details</h3></Card.Title>
        <Card.Body>
            <Row>
                <Col sm={6} md={6} lg={6} className='text-center'>
                    <Image src='Images/home-collection-icon.svg' fluid={true} height='200px' width='200px' />
                </Col>
                <Col sm={6} md={6} lg={6} className='mt-3'>
                    <h4>Collection Date</h4>
                    <p>{formatDate(collectionDate)}</p>
                    <h4>Retailer</h4>
                    <p>{retailer}</p>
                    <h4>{orderIDField}</h4>
                    <p>{orderID}</p>
                </Col>
            </Row>
        </Card.Body>
    </Card>
)

const CreateReturnConfirmation = ({ createdReturnDetails, showProducts, showCreatedAlert, hasValidSelazarIntegration, isCreateReturnWizard = false }) =>
    <React.Fragment>
        {showCreatedAlert && <ReturnCreatedAlert />}
        {createdReturnDetails.returnMethod === 1 ?
            <ReturnDropOffBarcode
                barcode={createdReturnDetails.returnDropOffInformation.barcodeContents}
                consignmentNumber={createdReturnDetails.returnDropOffInformation.courierConsignmentNumber}
                expiryDate={createdReturnDetails.returnDropOffInformation.expiryDate}
                trailingInformation={trailingInformation} />
            : <CreateHomeCollectionCard
                collectionDate={createdReturnDetails.returnCollectionInformation.collectionDate}
                retailer={createdReturnDetails.selectedRetailer}
                orderID={!hasValidSelazarIntegration ? createdReturnDetails.orderID : createdReturnDetails.orderReference}
                orderIDField={!hasValidSelazarIntegration ? "Order ID" : "Order Reference"}/>}
        {createdReturnDetails.returnMethod === 1 ?
            <p className='mt-2 mb-3'>Please package all returns in a single parcel and take to the your nearest drop-off point with your barcode ready to scan.</p>
            : <p className='mt-2 mb-3'>Please package all returns in a single parcel ready to be collected on your chosen date.<br/> 
                You will receive an SMS message on the day with your collection time slot.</p>}
        <h4 className="mt-2 mb-3">Return Summary</h4>
        <CreateReturnSummaryAccordion
            createdReturnDetails={createdReturnDetails}
            selectedAddressFull={createdReturnDetails.returnCollectionInformation?.selectedAddressFull}
            showProducts={showProducts}
            hasValidSelazarIntegration={hasValidSelazarIntegration}
            isCreateReturnWizard={isCreateReturnWizard} />
    </React.Fragment>

export default CreateReturnConfirmation;