import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

import './SettingsNav.scss';

const SettingBorder = () => <div className="setting-border"></div>

const SettingsHeader = ({ showBorder }) =>
    <Fragment>
        <div className="setting-header">
            <p className="title"><FontAwesomeIcon icon={faCogs} />Settings</p>
        </div>
        {showBorder && <SettingBorder />}
    </Fragment>

const SettingItem = ({ title, description, active, link, showBorder }) =>
    <Fragment>
        {active
            ? <div className="setting-item active">
                <p className="title">{title}</p>
                <p className="description">{description}</p>
            </div>
            : <Fragment>
                <Link to={link}>
                    <div className="setting-item">
                        <p className="title">{title}</p>
                        <p className="description">{description}</p>
                    </div>
                </Link>
                {showBorder && <SettingBorder />}
            </Fragment>
        }
    </Fragment>

const SettingsNav = ({ activeTitle }) =>
    <div className="settings-wrapper">
        <SettingsHeader showBorder={activeTitle !== "Return Settings"} />
        <SettingItem
            title="Return Settings"
            description="Set your options for returns"
            active={activeTitle === "Return Settings"}
            link="/retailer/settings"
            showBorder={activeTitle !== "Return Settings" && activeTitle !== "Payments"}
        />
        <SettingItem
            title="Payments"
            description="Set up or update payment methods"
            active={activeTitle === "Payments"}
            link="/retailer/settings/payments"
            showBorder={activeTitle !== "Integrations"}
        />
        <SettingItem
            title="Integrations"
            description="Set up integrations"
            active={activeTitle === "Integrations"}
            link="/retailer/settings/integrations"
            showBorder={true}
        />
    </div>

export default SettingsNav;