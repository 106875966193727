import React, { Fragment } from 'react';
import { FormGroup, FormLabel, FormControl, Card, Image } from 'react-bootstrap';
import { formatDate } from '../../../../Utilities';
import { ErrorAlert } from '../../../common/Alerts';
import CustomDatePicker from '../../../common/inputs/CustomDatePicker';

const OrderDetailsTitle = () =>
    <fieldset>
        <h4 className="mt-2 mb-3">Order Details</h4>
    </fieldset>

const ReturnOrderDetails = ({ orderDetails, orderValidation, orderDate, handleOrderDetailsInputChange, handleOrderDetailsOrderDateInputChange }) =>
    <Fragment>
        <OrderDetailsTitle />
        <FormGroup>
            <FormLabel htmlFor="orderDateField">Order Date</FormLabel> 
            <CustomDatePicker
                errorClass={orderValidation.orderDate.isInvalid ? "input-error" : ""}
                maxDate={new Date()} /*Max date is today*/
                selected={orderDate}
                handleInputChange={handleOrderDetailsOrderDateInputChange} />
            <span className="text-danger">{orderValidation.orderDate.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="orderIDField">Order ID</FormLabel>
            <FormControl id="orderIDField" className={orderValidation.orderID.isInvalid ? "input-error" : ""} type="text" name="orderID" maxLength="50" value={orderDetails.orderID} onChange={handleOrderDetailsInputChange} />
            <span className="text-danger">{orderValidation.orderID.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="emailAddressField">Email Address</FormLabel>
            <FormControl id="emailAddressField" className={orderValidation.emailAddress.isInvalid ? "input-error" : ""} type="text" name="emailAddress" minLength="1" maxLength="50" value={orderDetails.emailAddress} onChange={handleOrderDetailsInputChange} />
            <div>This is the email address associated with your order.</div>
            <span className="text-danger">{orderValidation.emailAddress.message}</span>
        </FormGroup>
    </Fragment>

const ProductCard = ({ index, imageUrl, name, skuCode, returnable, selected, handleProductSelect }) =>
    <Card className={`card-product mb-3 ${selected ? "selected" : ""} ${!returnable ? "disabled" : ""}`} onClick={returnable ? () => handleProductSelect(index) : null}>
        {imageUrl
            ? <div className="image-product-container">
                <Image src={imageUrl} className="rounded" />
            </div>
            : <div className="image-placeholder-container">
                <div className="placeholder"></div>
            </div>
        }
        <div className="details-product-container">
            <p className="mb-0">{name}</p>
            {skuCode && <p className="sub-text">SKU {skuCode}</p>}
            {!returnable && <p className="warning-text">Retailer doesn't allow refunds on this item</p>}
        </div>
    </Card>

const ProductSelection = ({ orderDate, products, selectedProducts, handleProductSelect, showProductSelectError }) =>
    <div className="section-product">
        <p className="sub-text">Ordered on</p>
        <p className="sub-text">{formatDate(orderDate)}</p>
        <p className="my-3">Select which items you are returning.</p>
        {products.length && products.map(product =>
            <ProductCard
                key={`product-${product.id}-${product.index}`}
                index={product.index}
                imageUrl={product.imageUrl}
                name={product.name}
                skuCode={product.skuCode}
                returnable={product.returnable}
                selected={selectedProducts.find(p => p.index === product.index) !== undefined}
                handleProductSelect={handleProductSelect}
            />
        )}
        {showProductSelectError && <span className="text-danger">Please select product(s) to continue</span>}
    </div>

const ReturnOrderSelazarIntDetails = ({ orderProductDetails, selazarOrderDetails, selazarOrderValidation, handleSelazarOrderDetailsInputChange, showProducts, handleProductSelect, showProductSelectError, showProductsOrderAgeError, showProductsError }) =>
    <Fragment>
        <OrderDetailsTitle />
        {showProducts
            ? <ProductSelection
                orderDate={orderProductDetails.orderDate}
                products={orderProductDetails.products}
                selectedProducts={selazarOrderDetails.products}
                handleProductSelect={handleProductSelect}
                showProductSelectError={showProductSelectError}
            />
            : <Fragment>
                {showProductsError && <ErrorAlert messages="Not a valid order number or email address. Please try again or contact the retailer." />}
                {showProductsOrderAgeError && <ErrorAlert messages="The returns timeframe for this order has now passed." />}
                <FormGroup>
                    <FormLabel htmlFor="orderReferenceField">Order Reference</FormLabel>
                    <FormControl id="orderReferenceField" className={selazarOrderValidation.orderReference.isInvalid 
                        ? "input-error" : ""} type="text" name="orderReference" maxLength="50" 
                        value={selazarOrderDetails.orderReference} 
                        onChange={(e) => handleSelazarOrderDetailsInputChange("orderReference", e.target.value)} />
                    <span className="text-danger">{selazarOrderValidation.orderReference.message}</span>
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="emailAddressField">Email Address</FormLabel>
                    <FormControl id="emailAddressField" className={selazarOrderValidation.emailAddress.isInvalid 
                        ? "input-error" : ""} type="text" name="emailAddress" minLength="1" maxLength="50" 
                        value={selazarOrderDetails.emailAddress} 
                        onChange={(e) => handleSelazarOrderDetailsInputChange("emailAddress", e.target.value)} />
                    <div>This is the email address associated with your order.</div>
                    <span className="text-danger">{selazarOrderValidation.emailAddress.message}</span>
                </FormGroup>
            </Fragment>
        }
    </Fragment>

export {
    ReturnOrderDetails,
    ReturnOrderSelazarIntDetails
}