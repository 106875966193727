import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import './Breadcrumb.scss';

const Breadcrumb = ({ link, text }) =>
    <div className="link-breadcrumb mb-4">
        <FontAwesomeIcon icon={faChevronLeft} size="xs" />
        <Link className="link" to={link}>{text}</Link>
    </div>

export default Breadcrumb;