import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { GET } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';

const REASON_OTHER = "Other";

class CreateReturnProductDetails extends Component {

    state = {
        loading: true,
        reasons: [],
        errorMessages: [],
    };

    async componentDidMount() {
        await this.fetchReasons();
        this.setState({ loading: false });
    }

    fetchReasons = async () =>
        GET(Endpoints.RETURN.GET.RETRIEVE_REASONS)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    result.data !== null
                        ? this.setState({ errorMessages: result.data })
                        : this.setState({ errorMessages: result.message });
                } else {
                    const reasons = result.data
                        ? result.data
                        : [];
                    this.setState({ reasons: reasons });
                }
            })
            .catch(() => this.setState({ errorMessages: ["There has been an issue retrieving return reasons."] }));

    render() {

        const { productDetails, productValidation, productReasonOtherValidation, productReasonAdditionalInformationValidation, handleProductDetailsInputChange, loading } = this.props;
        const { reasons } = this.state;

        return (
            loading
                ? <LoadingBar />
                : 
                    <fieldset>
                        <h4 className="mt-2 mb-3">Product Details</h4>
                        <FormGroup>
                            <FormLabel htmlFor="returnReasonType">Return Reason</FormLabel>
                            <FormControl id="returnReasonType" className={productValidation.returnReason.isInvalid ? "input-error" : ""} name="returnReason" as="select" value={productDetails.returnReason} onChange={handleProductDetailsInputChange} >
                                <option key="" value="">Please select...</option>;
                                {reasons.map(reason => (<option key={reason.value} value={reason.value}>{reason.name}</option>))}
                            </FormControl>
                            <span className="text-danger">{productValidation.returnReason.message}</span>
                        </FormGroup>
                        {productDetails.returnReason === REASON_OTHER &&
                            <FormGroup>
                                <FormLabel htmlFor="returnReasonInformationField">Other, please provide a reason</FormLabel>
                                <FormControl id="returnReasonInformationField" className={productReasonOtherValidation.returnReasonInformation.isInvalid ? "input-error" : ""} type="text" name="returnReasonInformation" minLength="1" value={productDetails.returnReasonInformation} onChange={handleProductDetailsInputChange} />
                                <span className="text-danger">{productReasonOtherValidation.returnReasonInformation.message}</span>
                            </FormGroup>}
                        <FormGroup>
                            <FormLabel htmlFor="additionalField">Additional Information</FormLabel>
                            <FormControl id="additionalField" row="3" as="textarea" type="text" name="additionalInformation" value={productDetails.additionalInformation} onChange={handleProductDetailsInputChange} className={productReasonAdditionalInformationValidation.additionalInformation.isInvalid ? "input-error" : ""}/>
                            <span className="text-danger">{productReasonAdditionalInformationValidation.additionalInformation.message}</span>
                        </FormGroup>
                    </fieldset>
                )
    };
}

export default CreateReturnProductDetails;