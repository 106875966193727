import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';

import './Confirm.scss';

const Confirm = ({ title, text, handleClose, handleConfirmAction, buttonText, variant, linkText, show = true, showHeader = true, closeLink = false, 
    showCloseButton = true, large = true }) =>

    <Modal size={large ? "lg" : undefined} show={show} onHide={handleClose} centered>
        {showHeader && 
            (showCloseButton 
                ? <Modal.Header closeButton><Modal.Title>{title}</Modal.Title></Modal.Header>
                : <Modal.Header><Modal.Title>{title}</Modal.Title></Modal.Header>)
        }
        <Modal.Body>
            <div className="confirm-text">{text}</div>
        </Modal.Body>
        <Modal.Footer className="no-border text-center">
            <Row>
                <Col xs={{ span: 12, order: '2' }} sm={{ span: 12, order: '2' }} md={{ span: 8, order: '1' }} lg={{ span: 8, order: '1' }} className="col-link">
                    {closeLink && <Button variant="link" onClick={handleClose} className="cancel-link underline">{linkText}</Button>}
                </Col>
                <Col xs={{ span: 12, order: '1' }} sm={{ span: 12, order: '1' }} md={{ span: 4, order: '2' }} lg={{ span: 4, order: '2' }}>
                    <Button variant={variant} block onClick={handleConfirmAction}>{buttonText}</Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>

export default Confirm;