import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Row, Col, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap';
import { useFormValidator } from "@selazar-ui/core";
import { GET_ASYNC, PUT } from '../../../Consumer';
import { ErrorAlert, SuccessAlert } from '../../common/Alerts';
import Endpoints from '../../common/Endpoints';
import Slider from '../../common/inputs/Slider';
import SettingsNav from '../../common/nav/SettingsNav';
import LoadingBar from '../../common/LoadingBar';
import Confirm from '../../common/modals/Confirm';

const RemoveReturnsServiceModelText = () =>
    <>
        <p>Are you sure you want to remove all service selections? <span className="bold">By removing these you will become an inactive retailer and will not be able to process returns through Return Robin.</span></p>
        <p>These service options will no longer be available to your customers. Returns in process will not be affected.</p>
    </>

const ReturnServicesModalText = ({ serviceChoice }) =>
    <>
        <ul>
            {serviceChoice.map((service, index) => (
                <div key={service.name + index}>
                    {service.isActive ? <li>{service.name}</li> : <br/> }
                </div>
            ))}
        </ul>
    </>

const ModelMessage = ({ checkedItems }) => {
    if (checkedItems.filter(f => f.isActive).length === 0) return [<span key="unused"></span>, <RemoveReturnsServiceModelText key={checkedItems.length}/>];
    else return ["Use this Returns Service?", <ReturnServicesModalText key={checkedItems.toString()} serviceChoice={checkedItems} />];
}

const ReturnServiceSelection = ({ handleServiceChange, serviceChoice, handleServiceSaveClick }) =>
    <>
        <h3 className="mb-3">Returns Service Selection</h3>
        <div className="form-input-description mb-3">
            <p>If you update your selection, returns in process will not be affected.</p>
        </div>
        <Row>
            <Col sm={12} md={6}>
                {serviceChoice.map((service, index) => (
                    <Fragment key={service.name}>
                        <FormGroup className="custom-control custom-checkbox mb-0">
                            <input className="custom-control-input" type="checkbox" id={service.name} name={service.name} checked={service.isActive} onChange={handleServiceChange(index)} />
                            <FormLabel className="custom-control-label" htmlFor={service.name}>{service.name}</FormLabel>
                        </FormGroup>
                    </Fragment>
                ))}
            </Col>
            <Col sm={12} md={12}>
                <FormGroup className="mt-4">
                    <div className="float-left d-none d-sm-block">
                        <Button variant="primary" className="ml-2" onClick={handleServiceSaveClick}>Save</Button>
                    </div>
                    <div className="d-block d-sm-none text-center">
                        <Button variant="primary" block onClick={handleServiceSaveClick}>Save</Button>
                    </div>
                </FormGroup>
            </Col>
        </Row>
    </>

const setOrderAgeLimit = (orderAgeLimit) => ({
    active: orderAgeLimit.orderAgeLimitSet,
    selection: {
        thirtyDays: orderAgeLimit.orderAgeLimitValue === 30 || !orderAgeLimit.orderAgeLimitSet,
        sixtyDays: orderAgeLimit.orderAgeLimitValue === 60,
        custom: orderAgeLimit.orderAgeLimitValue !== 30 && orderAgeLimit.orderAgeLimitValue !== 60 && orderAgeLimit.orderAgeLimitSet
    },
    customDays: (orderAgeLimit.orderAgeLimitValue !== 30 && orderAgeLimit.orderAgeLimitValue !== 60 && orderAgeLimit.orderAgeLimitSet) ? orderAgeLimit.orderAgeLimitValue : 0
});

const AgeLimitSetting = ({ active, selection, customDays, validation, handleOrderAgeInputChange, handleOrderAgeSelectionChange, handleOrderAgeSaveClick }) =>
    <>
        <div className="form-input-description mb-3">
            <p>Customers will not be allowed to create a return for an order older than your selection.</p>
        </div>
        <Row>
            <Col sm={12} md={6}>
                <Slider id="ageLimit" name="ageLimit" label="Turn on order age limit" checked={active} onChange={() => handleOrderAgeInputChange("active", !active)} />
            </Col>
        </Row>
        {active &&
            <>
                <FormGroup>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <FormControl className="custom-control-input" type="radio" id="radio-thirty" name="thirtyDays" value={selection.thirtyDays} checked={selection.thirtyDays} onChange={() => handleOrderAgeSelectionChange("thirtyDays")} />
                        <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-thirty">30 Days</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <FormControl className="custom-control-input" type="radio" id="radio-sixtyDays" name="sixtyDays" value={selection.sixtyDays} checked={selection.sixtyDays} onChange={() => handleOrderAgeSelectionChange("sixtyDays")} />
                        <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-sixtyDays">60 Days</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <FormControl className="custom-control-input" type="radio" id="radio-custom" name="custom" value={selection.custom} checked={selection.custom} onChange={() => handleOrderAgeSelectionChange("custom")} />
                        <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-custom">Custom</FormLabel>
                    </FormGroup>
                </FormGroup>
                {selection.custom &&
                    <div className="mb-2">
                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup className="mb-0">
                                    <FormControl id="customDaysField" className={validation?.customDays?.isInvalid ? "input-error" : ""} type="number" min="1" max="1000" name="customDays" onChange={(e) => handleOrderAgeInputChange("customDays", e.target.value)} value={customDays} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div>
                            <span>Enter number in days e.g.14</span>
                        </div>
                        <span className="text-danger">{validation?.customDays?.message}</span>
                    </div>
                }
            </>
        }
        <FormGroup className="mt-4">
            <div className="float-start d-none d-sm-block">
                <Button variant="primary" className="ml-2" onClick={handleOrderAgeSaveClick}>Save</Button>
            </div>
            <div className="d-block d-sm-none text-center">
                <Button variant="primary" block onClick={handleOrderAgeSaveClick}>Save</Button>
            </div>
        </FormGroup>
    </>

const Settings = () => {

    //#region State

    const [loading, setLoading] = useState(true);

    const [orderAgeLoadError, setAgeLoadError] = useState(null);
    const [orderAgeError, setOrderAgeError] = useState(false);
    const [orderAgeSuccess, setOrderAgeSuccess] = useState(false);
    const [ageLimit, setAgeLimit] = useState("");

    const [checkedItems, setCheckedItems] = useState([{ name: 'Home Collection', servicesType: 'HomeCollection', isActive: false }, { name: 'Drop-off Point', servicesType: 'DropOff', isActive: false }]);
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [returnServiceSuccess, setReturnServiceSuccess] = useState(false);
    const [returnServiceUpdate, setReturnServiceUpdate] = useState(false);
    const [returnServiceNoServices, setReturnServiceNoServices] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedItems, setSelectedItems] = useState(null);
    const [hasIntegration, setHasIntegration] = useState(null);
    const [isFirstEnableOrReenable, setFirstEnableOrReenable] = useState(false);

    //#endregion

    //#region Validaiton

    const { validate, validationResults } = useFormValidator([
        {
            field: "customDays",
            method: "isInt",
            validWhen: true,
            args: [{ min: 1, max: 1000 }],
            message: "Please enter your custom days between 1 and 1000",
        },
    ]);

    //#endregion

    //#region API

    const fetchData = useCallback(async () => {
        setLoading(true);
        await Promise.all([getOrderAgeLimit(), getCourierServices(), hasIntergration()])
        setLoading(false);
    }, [])

    const getOrderAgeLimit = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.SETTINGS.GET.ORDER_AGE);

            if (response?.ok) {
                const orderAgeLimit = await response.json();

                if (orderAgeLimit) {
                    const ageLimit = setOrderAgeLimit(orderAgeLimit.data);
                    setAgeLimit(ageLimit);
                    setAgeLoadError(false);
                } else setAgeLoadError(true);
            }
        } catch (error) {
            setAgeLoadError(true);
        }
    }

    const getCourierServices = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.SETTINGS.GET.RETURN_SERVICE);

            if (response?.ok) {
                const services = await response.json();

                if (services) {
                    if (services.data.length > 0) {
                        checkedItems.forEach(function (i) {
                            var service = services.data.find(service => i.name === service.name);
                            if (service) { i.isActive = true; };
                        });
                        setIsUpdate(true);
                        setCheckedItems(checkedItems);
                    }
                } else setAgeLoadError(true);
            }
        } catch (error) { 
            setAgeLoadError(true); 
        }
    }

    const hasIntergration = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.RETAILER.GET.HAS_INTEGRATION);

            if (response?.ok) {
                const data = await response.json();
                setHasIntegration(data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    //#endregion

    //#region Controls

    /* Order Age*/
    const handleOrderAgeInputChange = (name, value) => { setAgeLimit((prevState) => ({ ...prevState, [name]: value, })); };

    const handleOrderAgeSelectionChange = (name) => {
        const noSelection = {
            thirtyDays: false,
            sixtyDays: false,
            custom: false
        }
        setAgeLimit((prevState) => ({ ...prevState, selection: { ...noSelection, [name]: true } }));
    }

    const handleOrderAgeSaveClick = async () => {

        setLoading(true);

        if (ageLimit.selection.custom) {
            var validation = validate(ageLimit);
            if (!validation?.isValid) {
                setLoading(false);
                setOrderAgeSuccess(false);
                setOrderAgeError(false);
                return;
            }
        }

        const orderAgeLimit = {
            orderAgeLimitSet: ageLimit.active,
            orderAgeLimitValue: !ageLimit.active
                ? 0
                : ageLimit.selection.custom
                    ? ageLimit.customDays
                    : ageLimit.selection.thirtyDays
                        ? 30
                        : 60
        }

        try {
            const response = await PUT(Endpoints.SETTINGS.PUT.ORDER_AGE, orderAgeLimit);

            if (response?.ok) {
                if (orderAgeLimit) {
                    const ageLimit = setOrderAgeLimit(orderAgeLimit);
                    setAgeLimit(ageLimit);
                    setOrderAgeError(false);
                    setOrderAgeSuccess(true);
                    setLoading(false);
                } else {
                    setOrderAgeError(true);
                    setOrderAgeSuccess(false);
                    setLoading(false);
                }
            }
        }
        catch (error) { setOrderAgeError(true); }
        finally { setLoading(false); }
    }

    /* Return Service selection */
    const handleServiceChange = index => e => {
        const { checked } = e.target;
        let updateCheckedItems = [...checkedItems];
        updateCheckedItems[index].isActive = checked;
        setCheckedItems(updateCheckedItems);
    };

    const handleServiceSaveClick = () => setShowServiceModal(!showServiceModal);

    const hideShowServiceModal = () => setShowServiceModal(!showServiceModal);

    const onSaveReturnServices = async () => {
        setLoading(true);

        const courierSelection = {
            HomeCollection: checkedItems.find(f => f.servicesType === 'HomeCollection')?.isActive,
            DropOff: checkedItems.find(f => f.servicesType === 'DropOff')?.isActive,
        }

        try {
            const response = await PUT(Endpoints.SETTINGS.PUT.RETURN_SERVICE, courierSelection);

            if (response?.ok) {
                hideShowServiceModal(true);
                setLoading(false);

                const result = await response.json();
                var data = result.data;

                if (data.updatedSuccessful) {
                    (courierSelection.DropOff === true || courierSelection.HomeCollection === true)? localStorage.setItem('hasServiceSelected', 'true') : localStorage.setItem('hasServiceSelected', 'false');;
                    
                    setIsUpdate(data.isUpdate);
                    setFirstEnableOrReenable(data.isFirstEnableOrReenable);
                    setSelectedItems(checkedItems.filter(f => f.isActive).length === 0);
                    if (data.isUpdate) {
                        if (checkedItems.filter(f => f.isActive).length === 0) {
                            setReturnServiceNoServices(true);
                        }
                        else setReturnServiceUpdate(true);
                    }
                    else setReturnServiceSuccess(true);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    //#endregion

    //#region Render

    return (
        loading
            ? <LoadingBar />
            : <>
                <SettingsNav activeTitle="Return Settings" />
                <div className="nav-content">
                    <div className="main-content">
                        <Row>
                            <Col sm={12} md={6}>
                                {(returnServiceNoServices && selectedItems) && <ErrorAlert heading="Account Inactive" messages="You are now an inactive retailer as there are no returns services associated with this account. Please select a returns service to become active." />}
                                {(returnServiceSuccess && isFirstEnableOrReenable) && <SuccessAlert message={hasIntegration ? "You are now an active retailer. Return Robin is now ready to use." : "Returns service selection saved. We are now working on verifying your account."} />}
                                {(returnServiceSuccess && !isFirstEnableOrReenable && !selectedItems && !isUpdate) && <SuccessAlert message="Returns service selection saved." />}
                                {(returnServiceUpdate && !selectedItems && isUpdate) && <SuccessAlert message="Returns service selection saved." />}
                                <ReturnServiceSelection handleServiceChange={handleServiceChange} serviceChoice={checkedItems} handleServiceSaveClick={handleServiceSaveClick} />
                                <Confirm
                                    title="Confirm Return Service Selection"
                                    variant="primary"
                                    text={<ModelMessage checkedItems={checkedItems} />}
                                    handleClose={hideShowServiceModal}
                                    handleConfirmAction={onSaveReturnServices}
                                    buttonText="Confirm"
                                    linkText="Cancel"
                                    closeLink={true}
                                    show={showServiceModal}
                                />
                                <h3 className="mb-3">Order Age Limit</h3>
                                {orderAgeSuccess && <SuccessAlert message="Successfully updated order age limit." />}
                                {orderAgeError && <ErrorAlert messages="Unable to update order age limit. Please try again." />}
                                {orderAgeLoadError
                                    ? <ErrorAlert messages="Unable to retrieve order age limit. Please try again." />
                                    : <AgeLimitSetting
                                        active={ageLimit.active}
                                        selection={ageLimit.selection}
                                        customDays={ageLimit.customDays}
                                        validation={validationResults}
                                        handleOrderAgeInputChange={handleOrderAgeInputChange}
                                        handleOrderAgeSelectionChange={handleOrderAgeSelectionChange}
                                        handleOrderAgeSaveClick={handleOrderAgeSaveClick}
                                    />
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
    );

    //#endregion

}

export default Settings;
