import React, { Component } from 'react';
import { ChangePasswordCompleteCard } from '../shared/Snippets';

class ChangePasswordComplete extends Component {

    handleLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    }

    render() {

        return (
            <ChangePasswordCompleteCard handleLogin={this.handleLogin} />
        )
    }
}

export default ChangePasswordComplete;