import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './LoadingBar.scss';

const LoadingBar = () =>
    <div className="loading-bar" onClick={() => false}>
        <div className="loading-content">
            <FontAwesomeIcon icon={faSpinner} spin />
            <span>Please wait...</span>
        </div>
    </div>

export default LoadingBar;