import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import './Stepper.scss';

const StepperButtons = ({ cancelText, cancelLink, handleBack, onClickCancel, showBack = false, handleNext, showNext = false, isDisabled = false, nextButtonText = "Next Step" }) => 
    <Row className="stepper-buttons mt-4">
        <Col xs={{ span: 12, order: '3' }} md={{ span: 3, order: '1' }} lg={{ span: 3, order: '1' }}>{showBack && <Button variant="secondary" onClick={handleBack}>Previous Step</Button>}</Col>
        <Col xs={{ span: 12, order: '3' }} md={{ span: 3, offset: 3, order: '2' }} lg={{ span: 3, offset: 3, order: '2' }} className="cancel-link text-center"><Link to="" className="link-button" onClick={onClickCancel}>{cancelText}</Link></Col>
        <Col xs={{ span: 12, order: '1' }} md={{ span: 3, order: '3' }} lg={{ span: 3, order: '3' }}>{showNext && <Button disabled={isDisabled} ariant="primary" onClick={handleNext} block>{nextButtonText}</Button>}</Col>
    </Row>

export default StepperButtons;
