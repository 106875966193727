import React, { Fragment } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../../../../Utilities';
import { ErrorAlert } from '../../../../common/Alerts';
import TermsAndConditions from '../../../../../termsandconditions.pdf';

const Address = ({ selectedAddressFull }) =>
    <section>
        <p className="title">Collection Address</p>
        <p className="mb-1">{selectedAddressFull.line1}</p>
        {selectedAddressFull.line2 && <p className="mb-1">{selectedAddressFull.line2}</p>}
        <p className="mb-1">{selectedAddressFull.city}</p>
        <p className="mb-1">{selectedAddressFull.county}</p>
        <p>{selectedAddressFull.postcode}</p>
    </section>

const Retailer = ({ selectedRetailer, isHomeCollection }) =>
    <section>
        <p className="title">Retailer</p>
        <p>{selectedRetailer}</p>
        <p className='title'>Return Method</p>
        <p>{isHomeCollection ? "Home Collection" : "Drop-off Point"}</p>
    </section>

const CollectionDate = ({ collectionDate }) =>
    <section>
        <p className='title'>Collection Date</p>
        <p>{formatDate(collectionDate)}</p>
    </section>

const OrderDetails = ({ emailAddress, orderId, orderReference, orderDate, hasValidSelazarIntegration }) =>
    <section>
        <h4>Order Details</h4>
        <p className="title">Email Address</p>
        <p>{emailAddress}</p>
        <Fragment>
            <p className="title">{!hasValidSelazarIntegration ? "Order ID" : "Order Reference"}</p>
            <p>{orderReference ? orderReference : orderId}</p>
        </Fragment>
        <p className="title">Order Date</p>
        <p>{formatDate(orderDate)}</p>
    </section>

const ProductDetails = ({ products, returnReason, otherReason, otherReasonInformation, additionalInformation }) =>
    <section>
        <h4>Product Details</h4>
        {(products && products.length > 0) && <Fragment>
            {products.map(product => 
                <div className="mb-3">
                    <p className="title">Item Name</p>
                    <p className="mb-0">{product.name} {product.skuCode && <span>- {product.skuCode}</span>}</p>
                </div>)}
            </Fragment>}
        <p className="title">Return Reason</p>
        <p>{returnReason}</p>
        {otherReason && <Fragment>
            <p className="title">Other Reason</p>
            <p>{otherReasonInformation}</p>
        </Fragment>}
        {additionalInformation && <Fragment>
            <p className="title">Additional Information</p>
            <p>{additionalInformation}</p>
        </Fragment>}
    </section>

const ParcelConfirmation = ({ parcelConfirmationDetails, handleParcelConfirmationInputChange, parcelConfirmationValidation, isHomeCollection }) =>
    <section className="section-confirmation">
        <h4>Parcel Confirmation</h4>
        <p>Please check your parcel and confirm:</p>
        <ul>
            <li><FontAwesomeIcon icon={faCheckCircle} className="green" />Doesn't contain prohibited items</li>
            <li><FontAwesomeIcon icon={faCheckCircle} className="green" />{isHomeCollection ? "Weighs less than 30kg" : "Weighs no more than 10kg"}</li>
            <li>
                <FontAwesomeIcon icon={faCheckCircle} className="green" />
                {isHomeCollection ? "Doesn't go over the maximum size 120cm long / maximum dimensions .23m3" : "Doesn't go over the maximum size 60cm x 50cm x 50cm"}
            </li>
        </ul>
        <FormGroup className={`custom-control custom-checkbox ${!parcelConfirmationValidation && "custom-input-error"}`}>
            <input id="terms-confirmation" className="custom-control-input" type="checkbox" checked={parcelConfirmationDetails.confirmation} onChange={handleParcelConfirmationInputChange} />
            <FormLabel className="custom-control-label" htmlFor="terms-confirmation"><span className="custom-checkbox-label">{"I confirm and agree to the "}<a href={TermsAndConditions} target='_blank' rel="noopener noreferrer" alt='Return Robin Terms and Conditions - opens in a new window'>terms & conditions</a></span></FormLabel>
        </FormGroup>
    </section>

const ReturnSummary = ({ summary, parcelConfirmationDetails, handleParcelConfirmationInputChange, parcelConfirmationValidation, showParcelSummary = true, 
    isHomeCollection, selectedAddressFull, hasValidSelazarIntegration }) =>
    <Fragment>
        {(!parcelConfirmationValidation && showParcelSummary) && <ErrorAlert messages="You need to confirm your parcel complies with the restrictions and agree to the terms and conditions." />}
        {isHomeCollection && <Address selectedAddressFull={selectedAddressFull} />}
        <Retailer selectedRetailer={summary.selectedRetailer} isHomeCollection={isHomeCollection} />
        {isHomeCollection && <CollectionDate collectionDate={summary.collectionDate} />}
        <OrderDetails 
            emailAddress={summary.emailAddress}
            orderId={summary.orderId}
            orderReference={summary.orderReference}
            selectedRetailer={summary.selectedRetailer}
            orderDate={summary.orderDate}
            isHomeCollection={isHomeCollection}
            hasValidSelazarIntegration={hasValidSelazarIntegration} />
        <ProductDetails
            products={summary.products}
            returnReason={summary.returnReason}
            otherReason={summary.otherReason}
            otherReasonInformation={summary.returnReasonInformation}
            additionalInformation={summary.additionalInformation} />
        {showParcelSummary && <ParcelConfirmation
            parcelConfirmationDetails={parcelConfirmationDetails}
            handleParcelConfirmationInputChange={handleParcelConfirmationInputChange}
            parcelConfirmationValidation={parcelConfirmationValidation}
            isHomeCollection={isHomeCollection} />}
    </Fragment>

export default ReturnSummary;