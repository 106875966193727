import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import CustomerLogin from './components/login/customer/Login';
import RetailerLogin from './components/login/retailer/Login';
import Register from './components/register/customer/Register';
import { AccountVerification } from './components/register/customer/AccountVerification';
import { MobileVerification } from './components/register/customer/MobileVerification';
import { RegistrationComplete } from './components/register/customer/RegistrationComplete';
import { RegistrationCancelled } from './components/register/customer/RegistrationCancelled';
import { Issue } from './components/register/customer/Issue';
import RetailerRegister from './components/register/retailer/Register';
import { AccountVerification as RetailerAccountVerification } from './components/register/retailer/AccountVerification';
import { Issue as RetailerIssue } from './components/register/retailer/Issue';
import ForgotPassword from './components/forgotpassword/customer/ForgotPassword';
import RetailerForgotPassword from './components/forgotpassword/retailer/ForgotPassword';
import PasswordLinkSent from './components/forgotpassword/customer/PasswordLinkSent';
import RetailerPasswordLinkSent from './components/forgotpassword/retailer/PasswordLinkSent';
import ChangePassword from './components/forgotpassword/customer/ChangePassword';
import RetailerChangePassword from './components/forgotpassword/retailer/ChangePassword';
import ChangePasswordComplete from './components/forgotpassword/customer/ChangePasswordComplete';
import RetailerChangePasswordComplete from './components/forgotpassword/retailer/ChangePasswordComplete';
import CreateReturnWizard from './components/returns/customer/create/CreateReturnWizard';
import Returns from './components/returns/customer/list/Returns';
import RetailerReturns from './components/returns/retailer/Returns';
import ReturnDetails from './components/returns/customer/list/ReturnDetails';
import RetailerReturnDetails from './components/returns/retailer/ReturnDetails';
import AddressCheck from './components/address/customer/AddressCheck';
import RetailerAddressCheck from './components/address/retailer/AddressCheck';
import { AddAddress } from './components/address/customer/AddAddress';
import AddAddressConfirmation from './components/address/customer/AddAddressConfirmation';
import RetailerAddAddressConfirmation from './components/address/retailer/AddAddressConfirmation';
import { YourAddresses } from './components/address/customer/YourAddresses';
import { FirstAddress } from './components/address/customer/FirstAddress';
import RetailerFirstAddress from './components/address/retailer/FirstAddress';
import { EditAddress } from './components/address/customer/EditAddress';
import Dashboard from './components/dashboard/customer/Dashboard';
import Invoices from './components/invoices/Invoices';
import { Layout } from './layout/Layout';
import NotFound from './components/common/NotFound';
import { EmailVerification } from './components/register/retailer/EmailVerification';
import Profile from './components/profile/Profile';
import FAQ from './components/profile/FAQ';
import ScoreHistory from './components/profile/ScoreHistory';
import ReturnSettings from './components/settings/return/Settings';
import Integrations from './components/settings/integrations/Integrations';
import AddIntegration from './components/settings/integrations/AddIntegration';
import AddSelazarIntegration from './components/settings/integrations/AddSelazarIntegration';
import EditIntegration from './components/settings/integrations/EditIntegration';
import CardPayment from './components/settings/payments/card/CardPayment';
import CardReturn from './components/settings/payments/card/CardReturn';
import DirectDebit from './components/settings/payments/debit/DirectDebit';
import DirectDebitComplete from './components/settings/payments/debit/DirectDebitComplete';
import DirectDebitCancelled from './components/settings/payments/debit/DirectDebitCancelled';

import HomeReturns from './components/home/Returns';

import HomeHelp from './components/home/Help';
import BookReturn from './components/home/faqs/BookReturn';
import TrackReturn from './components/home/faqs/TrackReturn';
import MultipleReturns from './components/home/faqs/MultipleReturns';
import CancelCollection from './components/home/faqs/CancelCollection';
import RobinScore from './components/home/faqs/RobinScore';
import LeaveParcel from './components/home/faqs/LeaveParcel';

import UnverifiedRetailer from './components/common/UnverifiedRetailer';
import { checkRetailer } from './Utilities';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    };
  }

  isLoggedIn = () => {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      return true;
    }
    else {
      return false;
    }
  }

  isReRoute = () => {
    localStorage.setItem('prevURL', JSON.stringify(this.props.location.pathname));
  }

  onLogin = (data, isRetailer) => {
    if (data.token) {
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('isRetailer', JSON.stringify(isRetailer));

      this.setState({
        loggedIn: true
      });
    }
  }

  onLogout = () => {
    const isRetailer = JSON.parse(localStorage.getItem('isRetailer'));
    this.setState({
      loggedIn: false
    });
    localStorage.clear();
    isRetailer ? this.props.history.push('/retailer/login') : this.props.history.push('/login');
  }

  addressCompleted = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null ? user.setupComplete : false;
  }

  renderLogin() {
    return (
      <React.Fragment>
        <Switch>
          <Route path='/' component={HomeReturns} exact />
          <Route path='/help' component={HomeHelp} exact />
          <Route path='/help/trackreturn' component={TrackReturn} exact />
          <Route path='/help/bookreturn' component={BookReturn} exact />
          <Route path='/help/robinscore' component={RobinScore} exact />
          <Route path='/help/multiplereturns' component={MultipleReturns} exact />
          <Route path='/help/leaveparcel' component={LeaveParcel} exact />
          <Route path='/help/cancelcollection' component={CancelCollection} exact />

          <Route path='/login' exact render={() => (<CustomerLogin onLogin={this.onLogin} />)} />
          <Route path='/retailer/login' exact render={() => (<RetailerLogin onLogin={this.onLogin} />)} />
          <Route path='/register' exact component={() => (<Register onLogin={this.onLogin} />)} />
          <Route path='/register/accountverification' component={AccountVerification} exact />
          <Route path='/register/mobile' component={MobileVerification} exact />
          <Route path='/register/complete' component={RegistrationComplete} exact />
          <Route path='/register/cancelled' component={RegistrationCancelled} exact />
          <Route path='/register/issue' component={Issue} exact />

          <Route path='/retailer/register' exact component={() => (<RetailerRegister onLogin={this.onLogin} />)} />
          <Route path='/retailer/register/accountverification' component={RetailerAccountVerification} exact />
          <Route path='/retailer/register/verify/:verificationid' component={EmailVerification} exact />
          <Route path='/retailer/register/issue' component={RetailerIssue} exact />

          <Route path='/forgotpassword' component={ForgotPassword} exact />
          <Route path='/forgotpassword/linksent' component={PasswordLinkSent} exact />
          <Route path='/forgotpassword/changepassword/:passwordresetid' component={ChangePassword} exact />
          <Route path='/forgotpassword/complete' component={ChangePasswordComplete} exact />

          <Route path='/retailer/forgotpassword' component={RetailerForgotPassword} exact />
          <Route path='/retailer/forgotpassword/linksent' component={RetailerPasswordLinkSent} exact />
          <Route path='/retailer/forgotpassword/changepassword/:passwordresetid' component={RetailerChangePassword} exact />
          <Route path='/retailer/forgotpassword/complete' component={RetailerChangePasswordComplete} exact />
          <Route component={() => (<NotFound link={'/Login'} linkText='Back to Login' navTitle='Login' isLoggedIn={this.isLoggedIn()} isReRoute={this.isReRoute()} />)} />
        </Switch>
      </React.Fragment>
    );
  }

  renderHome = () => {
    const addressCompleted = this.addressCompleted();
    const isRetailer = JSON.parse(localStorage.getItem('isRetailer'));
    if(isRetailer) checkRetailer();
    const isRetailerVerified = localStorage.getItem('isRetailerVerified') === "true";
    const hasServiceSelected = localStorage.getItem('hasServiceSelected') === "true";
    return addressCompleted ? this.renderAddressCompleted(isRetailer, isRetailerVerified, hasServiceSelected) : this.renderAddressCheck(isRetailer, isRetailerVerified);
  }

  renderAddressCompleted = (isRetailer, isRetailerVerified, hasServiceSelected) => (
    <Layout onLogout={this.onLogout} isRetailer={isRetailer}>
      {isRetailer ?
        <Switch>
          <Route path='/' component={isRetailerVerified ? (props) => (<RetailerReturns hasServiceSelected={hasServiceSelected} {...props} />) : () => (<UnverifiedRetailer isRetailerVerified={isRetailerVerified} hasServiceSelected={hasServiceSelected} />)} exact />
          <Route path='/retailer' component={isRetailerVerified ? (props) => (<RetailerReturns hasServiceSelected={hasServiceSelected} {...props} />) : () => (<UnverifiedRetailer title="Returns" isRetailerVerified={isRetailerVerified} hasServiceSelected={hasServiceSelected} />)} exact />
          <Route path='/retailer/return' component={isRetailerVerified ?  (props) => (<RetailerReturns hasServiceSelected={hasServiceSelected} {...props} />) : () => (<UnverifiedRetailer title="Returns" isRetailerVerified={isRetailerVerified} hasServiceSelected={hasServiceSelected} />)} exact />
          <Route path='/retailer/return/details/:id' component={isRetailerVerified ?  (props) => (<RetailerReturnDetails hasServiceSelected={hasServiceSelected} {...props} />) : () => (<UnverifiedRetailer title="Returns" isRetailerVerified={isRetailerVerified} hasServiceSelected={hasServiceSelected} />)} exact />
          <Route path='/retailer/invoice' component={isRetailerVerified ?  (props) => (<Invoices hasServiceSelected={hasServiceSelected} {...props} />) : () => (<UnverifiedRetailer title="Invoicing" isRetailerVerified={isRetailerVerified} hasServiceSelected={hasServiceSelected} />)} exact />
          <Route path='/retailer/address/complete' component={RetailerAddAddressConfirmation} exact />
          <Route path='/retailer/settings' component={ReturnSettings} exact />
          <Route path='/retailer/settings/integrations' component={Integrations} exact />
          <Route path='/retailer/settings/integrations/add' component={AddIntegration} exact />
          <Route path='/retailer/settings/integrations/add/selazar' component={AddSelazarIntegration} exact />
          <Route path='/retailer/settings/integrations/edit/:id' component={EditIntegration} exact />

          <Route path='/retailer/settings/payments' component={() => <CardPayment history={this.props.history} />} exact />

          <Route path='/retailer/settings/payments/barclays/return' component={CardReturn} exact />
          <Route path='/retailer/settings/payments/directdebit' component={isRetailerVerified ? DirectDebit : () => DirectDebit(this.props.history)} exact />
          <Route path='/retailer/settings/payments/directdebit/complete' component={DirectDebitComplete} exact />
          <Route path='/retailer/settings/payments/directdebit/cancelled' component={DirectDebitCancelled} exact />
          <Route component={() => (<NotFound link={'/'} linkText='Back to Home' isLoggedIn={this.isLoggedIn()} />)} />
        </Switch> :
        <Switch>
          <Route path='/' component={Dashboard} exact />
          <Route path='/return' component={Returns} exact />
          <Route path='/return/create' component={CreateReturnWizard} exact />
          <Route path='/return/details/:id' component={ReturnDetails} exact />
          <Route path='/address/add' component={AddAddress} exact />
          <Route path='/address/complete' component={AddAddressConfirmation} exact />
          <Route path='/address/youraddresses' component={YourAddresses} exact />
          <Route path='/address/edit/:addressid' component={EditAddress} exact />
          <Route path='/profile' component={Profile} exact />
          <Route path='/profile/faq' component={FAQ} exact />
          <Route path='/profile/scorehistory' component={ScoreHistory} exact />
          <Route component={() => (<NotFound link={'/'} linkText='Back to Home' isLoggedIn={this.isLoggedIn()} />)} />
        </Switch>}
    </Layout>
  )

  renderAddressCheck = (isRetailer, isRetailerVerified) => {
    return (
      <React.Fragment><Layout onLogout={this.onLogout} isRetailerVerified={isRetailerVerified}>
        {isRetailer ?
          <Switch>
            <Route path='/retailer/address/addresscheck' component={RetailerAddressCheck} exact />
            <Route path='/retailer/address/firstaddress' component={RetailerFirstAddress} exact />
            <Route path='/retailer/address/complete' component={AddAddressConfirmation} exact />
            <Route component={RetailerAddressCheck} />
          </Switch> :
          <Switch>
            <Route path='/address/addresscheck' component={AddressCheck} exact />
            <Route path='/address/firstaddress' component={FirstAddress} exact />
            <Route path='/address/complete' component={AddAddressConfirmation} exact />
            <Route component={AddressCheck} />
          </Switch>
        }
      </Layout>
      </React.Fragment>)
  }

  render() {
    const result = this.isLoggedIn();
    return (
      <React.Fragment>
        {result ? this.renderHome() : this.renderLogin()}
      </React.Fragment>
    );
  }
}

export default withRouter(App);
