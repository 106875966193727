import React from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import 'react-intl-tel-input/dist/main.css';

export default function AddAddressForm(props) {

        const { details, validation, handleInputChange, addressTypes, disabledEdit } = props;

        const nameFieldError = validation.name.isInvalid ? "input-error" : undefined;
        const line1FieldError = validation.line1.isInvalid ? "input-error" : undefined;
        const cityFieldError = validation.city.isInvalid ? "input-error" : undefined;
        const countyFieldError = validation.county.isInvalid ? "input-error" : undefined;
        const postcodeFieldError = validation.postcode.isInvalid ? "input-error" : undefined;

        return (
            <React.Fragment>
                <FormGroup>
                    <FormLabel htmlFor="nameField">Full Name</FormLabel>
                    <FormControl id="nameField" className={nameFieldError} disabled={disabledEdit} type="text" name="name" maxLength="50" onChange={handleInputChange} value={details.name} />
                    <span className="text-danger">{validation.name.message}</span>
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="addressOneField">Address Line 1</FormLabel>
                    <FormControl id="addressOneField" className={line1FieldError} disabled={disabledEdit} type="text" name="line1" maxLength="50" onChange={handleInputChange} value={details.line1} />
                    <span className="text-danger">{validation.line1.message}</span>
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="addressTwoField">Address Line 2 <span className="form-label-optional font-weight-normal">- optional</span></FormLabel>
                    <FormControl id="addressTwoField" type="text" disabled={disabledEdit} name="line2" maxLength="50" onChange={handleInputChange} value={details.line2} />
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="cityField">City</FormLabel>
                    <FormControl id="cityField" className={cityFieldError} disabled={disabledEdit} type="text" name="city" maxLength="50" onChange={handleInputChange} value={details.city} />
                    <span className="text-danger">{validation.city.message}</span>
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="countyField">County</FormLabel>
                    <FormControl id="countyField" className={countyFieldError} disabled={disabledEdit} type="text" name="county" maxLength="50" onChange={handleInputChange} value={details.county} />
                    <span className="text-danger">{validation.county.message}</span>
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="postcodeField">Postcode</FormLabel>
                    <FormControl id="postcodeField" className={postcodeFieldError} disabled={disabledEdit} type="text" name="postcode" maxLength="50" onChange={handleInputChange} value={details.postcode} />
                    <span className="text-danger">{validation.postcode.message}</span>
                </FormGroup>

                <FormGroup>
                    {addressTypes.map((at, i) =>
                    <FormGroup className="custom-check form-check-inline" key={i} >
                        <FormControl className="custom-radio-input" type='radio' id={at.type} name='addressType' value={at.type}
                            checked={details.addressType === at.type} onChange={handleInputChange} />
                        <FormLabel className="custom-input-group-label" htmlFor={at.type}>{at.type} Address</FormLabel>
                    </FormGroup>
                    )}
                </FormGroup>
            </React.Fragment>
        )
}