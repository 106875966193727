import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { ErrorAlert } from '../../common/Alerts';

const ERROR_TITLE = "Unable to Add Selazar Integration";
const ERROR_MESSAGE = "Unable to add another Selazar integration. Only one Selazar Integration is allowed.";

const SelazarIntegrationError = ({ history }) =>
    <Fragment>
        <ErrorAlert heading={ERROR_TITLE} messages={ERROR_MESSAGE} />
        <Button variant="primary" onClick={() => history.push('/retailer/settings/integrations')}>Go to Integrations</Button>
    </Fragment>


export default SelazarIntegrationError;