import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Breadcrumb from '../common/Breadcrumb';
import Pagination from '../common/Pagination';
import { formatDateTime } from '../../Utilities';
import { getPointDifference, ScoreDetails, ScoreInfo } from './shared/Snippets';
import Endpoints from '../common/Endpoints';
import { GET } from '../../Consumer';
import LoadingBar from '../common/LoadingBar';
import { ErrorAlert } from '../common/Alerts';

import './shared/Profile.scss';

const ScoreHistoryCard = ({ history }) => (
    <Card className="card-nav p-3">
        {(history.score === 100 && history.scoreChange === 0) ? <p className="mb-0">Your starting score is {history.score}</p> : getPointDifference(history.scoreChange, history.scoreIncrease)}
        <p className="text-sub mb-0">You have a Robin Score of <strong>{history.score}</strong> on {formatDateTime(history.createdDate)}</p>
        {!(history.score === 100 && history.scoreChange === 0) &&
            <React.Fragment>
                <p className="title-sub mb-0">Reason for score change:</p>
                <p className="mb-0">{history.reason}</p>
            </React.Fragment>
        }
    </Card>
)

const ScoreHistoryList = ({ histories, onNext, onPrevious, onStart, onEnd, pageIndex, pageCount }) => (
    <Row className="mt-3">
        <Col sm={12} md={4}>
            <h4 className="mb-3">Score History</h4>
            {histories.map((history, i) => <ScoreHistoryCard key={`${i}-score-history`} history={history} />)}
            <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
        </Col>
    </Row>
);

class ScoreHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: "",
            pageIndex: 1,
            pageCount: 1,
            profile: {},
            scoreHistory: []
        };
    }

    async componentDidMount() {
        await this.getProfile();
        await this.getScoreHistory();
        this.setState({ loading: false })
    }

    getProfile = () => {
        return GET(Endpoints.SCORE.GET.PROFILE)
            .then(response => response.json())
            .then(result => {
                const profile = result.data.profile;
                const error = profile ? "" : "We are currently unable to retrieve profile details.";
                this.setState({ profile: profile, error: error });
            });
    }

    getScoreHistory = () => {
        const { pageIndex } = this.state;
        let url = new URL(Endpoints.SCORE.GET.HISTORY);
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const scoreHistory = results ? results.data.results : [];
                const pageCount = results ? results.data.pageCount : 0;
                this.setState({ scoreHistory: scoreHistory, pageCount: pageCount, loading: false });
            });
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.getScoreHistory);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.getScoreHistory);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.getScoreHistory);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.getScoreHistory);
        }
    }

    render() {
        const { profile, scoreHistory, pageIndex, pageCount, error, loading } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <div className="main-content profile-details">
                        <Row className="mb-3">
                            <Col sm={12} md={6}>
                                <Breadcrumb link="/profile" text="Back to Your Profile" />
                                <h3 className="mb-3">Robin Score History</h3>
                                {error && <ErrorAlert messages={error} />}
                            </Col>
                        </Row>
                        <ScoreDetails title="Current Score" scoreDetails={profile.scoreDetails} />
                        <ScoreHistoryList histories={scoreHistory} onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                        <Row>
                            <Col sm={12} md={4}>
                                <ScoreInfo />
                            </Col>
                        </Row>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default ScoreHistory;