import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import './Pagination.scss';

const Pagination = ({ onPrevious, onNext, onStart, onEnd, pageCount, pageIndex }) =>
    <div className="pagination justify-content-center">
        <div className={`mr-auto ${pageIndex > 1 ? "paging-active" : ""}`}>
            <span className="pagination-link mr-3" onClick={onStart}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </span>
            <span className="pagination-link" onClick={onPrevious}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </span>
        </div>
        <span className="m-auto pagination-count">{pageIndex} of {pageCount}</span>
        <div className={`ml-auto ${pageIndex < pageCount ? "paging-active" : ""}`}>
            <span className="pagination-link" onClick={onNext}>
                <FontAwesomeIcon icon={faAngleRight} />
            </span>
            <span className="pagination-link ml-3" onClick={onEnd}>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </span>
        </div>
    </div>

export default Pagination;