import React, { Fragment, useState } from 'react';
import { Row, Col, Button, InputGroup, FormControl, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faSearch, faArrowCircleDown, faArrowCircleUp, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { formatDate, getStatusClass } from '../../../Utilities';
import { WarningAlert, SuccessAlert, ErrorAlert } from '../../common/Alerts';
import { RETURN_METHODS } from '../../common/constants';

const NoReturns = () => (
    <div className="message-empty">
        <FontAwesomeIcon icon={faHistory} size="8x" />
        <div className="message">
            <p>You have not created any returns yet. When you create a return you can find the details here.</p>
        </div>
    </div>
);

const NoReturnsSearch = () => (
    <Row>
        <Col sm={12} md={6}>
            <WarningAlert message="There are no results matching your search. Check that you entered the details correctly and try again." />
        </Col>
    </Row>
);

const ReturnsFilter = ({ filter, sort, onFilterChange, onSearch, onClear, isRetailer }) => {
    const returnMethodName = isRetailer ? "Service" : "Return Method";
    return (
        <React.Fragment>
            <Row className="form-group-filter">
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <InputGroup >
                        <FormControl id="searchField" type="text" name="searchField" placeholder={isRetailer ? 'Search Order ID or Return Robin ID' : 'Search Order Reference or Retailer'} value={filter.searchField} onChange={onFilterChange} />
                        <InputGroup.Append>
                            <InputGroup.Text id="searchBox" className="search-icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <FormControl as="select" name="sort" value={sort} onChange={onFilterChange}>
                        {filter.orderingList.map((i) => <option value={i.id}>{i.name}</option>)}
                    </FormControl>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <FormControl as="select" name="returnMethod" value={filter.returnMethod} onChange={onFilterChange}>
                        <option value="">Filter by {returnMethodName}</option>
                        {RETURN_METHODS.map((rm, i) => <option value={i}>{rm}</option>)}
                    </FormControl>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <FormControl as="select" name="status" value={filter.status} onChange={onFilterChange} disabled={!filter.isReturnMethodSelected}>
                        <option value="">Filter by Status</option>
                        {filter.statusesList.map((i) => <option value={i.id}>{i.name}</option>)}
                    </FormControl>
                </Col>
            </Row>
            <div className="button-group-filter mb-3 d-none d-md-block">
                <Button variant="primary" className="float-right ml-3" onClick={onSearch}>Apply Filters</Button>
                <Button variant="secondary" className="float-right" onClick={onClear}>Clear Filters</Button>
            </div>
            <Row className="mb-3 d-md-none d-lg-none d-xl-none">
                <Col xs={12} sm={12}>
                    <Button variant="primary" className="mb-0" onClick={onSearch}>Apply Filters</Button>
                </Col>
                <Col xs={12} sm={12}>
                    <Button variant="secondary" onClick={onClear}>Clear Filters</Button>
                </Col>
            </Row>
        </React.Fragment>)};

const OrderReference = ({ orderReference, orderID }) => (
    <section>
        <h4>Order Details</h4>
        {orderReference === ""
            ? <Fragment>
                {/* Non-Integrated */}
                <p className="title">Order ID</p>
                <p>{orderID}</p>
            </Fragment>
            : <Fragment>
                {/* Integrated */}
                <p className="title">Order Reference</p>
                <p>{orderReference}</p>
            </Fragment>
        }
    </section>
);

const ProductDetails = ({ products, returnReason, otherReason, otherReasonInformation, additionalInformation }) => (
    <section>
        <h4>Product Details</h4>
        {(products && products.length > 0) && <div className="mb-3">
            <p className="title">Items</p>
            {products.map(product => <p key={product.id} className="mb-0">{product.name} {product.skuCode && <span>- {product.skuCode}</span>}</p>)}
        </div>
        }
        <p className="title">Return Reason</p>
        <p>{returnReason}</p>
        {otherReason && <Fragment>
            <p className="title">Other Reason</p>
            <p>{otherReasonInformation}</p>
        </Fragment>}
        {additionalInformation && <Fragment>
            <p className="title">Additional Information</p>
            <p>{additionalInformation}</p>
        </Fragment>}
    </section>
);

const Details = ({ collectionDate, returnRobinId, createdDate, courier, courierService, status, trackingReference, postcode }) => (
    <section>
        <h4>Return Details</h4>
        {
            collectionDate && (courier === "CollectPlus"
                ?<Fragment>
                    <p className="title">Drop-off Date</p>
                    <p>{formatDate(collectionDate)}</p>
                    </Fragment>
                : <Fragment>
                    <p className="title">Collection Date</p>
                    <p>{formatDate(collectionDate)}</p>
                    </Fragment>
            )
        }
        <p className="title">Return Robin ID</p>
        <p>{returnRobinId}</p>
        <p className="title">Date Created</p>
        <p>{formatDate(createdDate)}</p>
        <p className="title">Return Service</p>
        <p>{courierService}</p>
        {
            trackingReference && (status === "Cancelled"
                ? <Fragment>
                    <p className="title mb-1 mt-3">Tracking Number</p>
                    <p>{trackingReference}</p>
                    </Fragment>
                : <Fragment>
                    <p className="title mb-1 mt-3">Tracking Number</p>
                    <a
                        href={`https://www.yodel.co.uk/track/${trackingReference}?postcode=${postcode}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {trackingReference}
                    </a>
                    </Fragment>
            )
        }
        <p className="title mb-1 mt-3">Status</p>
        <span className={getStatusClass(status)}>{status}</span>
    </section>
);

const ChangeHistory = ({ changeHistory, isRetailer, close, retailer, orderNumber }) => (

    <div className="main-content return-details">
        <div className="link-breadcrumb mb-4">
            <FontAwesomeIcon icon={faChevronLeft} size="xs" /><Button variant="link" className="p-0 underline" onClick={close}>Back to Return Details</Button>
        </div>

        <h3 className="mt-2 mb-3">Return Status History</h3>
        <Fragment>
            <p>Below you can find the status history for {isRetailer ? "the" : "your "}<span className="font-weight-bold">{retailer}</span> order <span className="font-weight-bold">{orderNumber}</span></p></Fragment>

        <hr />
        {changeHistory.map((change, i) =>
            change.event === 'PreferredCollectionDateChanged' ?
                <Fragment>
                    <p className="font-weight-bold small" key={`${i}-change`}>Collection Date Changed</p>
                    <p className="mt-1">Original collection date: {change.previousCollectionDate}</p>
                    <p>New collection date: {change.newCollectionDate}</p>
                    <hr />
                </Fragment> : change.event === 'StatusChange' ?
                    <Fragment>
                        <p className="font-weight-bold small mb-1" key={`${i}-change`}>Status Update</p>
                        <span className={getStatusClass(change.newStatus)}>{change.newStatusValue}</span>
                        <p className="mt-1">{change.createdDate}</p>
                        <hr />
                    </Fragment> : <Fragment>No changes recorded</Fragment>
        )}
    </div>
);

const SuccessMessage = ({ heading, message }) => (
    <Row>
        <Col sm={12} md={6} >
            <SuccessAlert heading={heading} message={message} />
        </Col>
    </Row>
);

const ErrorMessage = ({ heading, message }) => (
    <Row>
        <Col sm={12} md={6} >
            <ErrorAlert heading={heading} messages={message} />
        </Col>
    </Row>
);

const setAccordionIcon = (activeAccordionItem, accordionItem) => {
    return (activeAccordionItem === accordionItem) ? faArrowCircleUp : faArrowCircleDown;
}

const AccordionRow = ({ name, value }) =>
    <Row>
        <Col sm={2} md={3} lg={3}>
            <p><span className='font-weight-bold'>{name}</span></p>
        </Col>
        <Col sm={10} md={9} lg={9}>
            <p>{value}</p>
        </Col>
    </Row>

const CreateReturnSummaryAccordion = ({ createdReturnDetails, selectedAddressFull, showProducts, hasValidSelazarIntegration, isCreateReturnWizard }) => {
    const [activeAccordionItem, setActiveAccordionItem] = useState(null);

    return (
        <Accordion onSelect={(e) => setActiveAccordionItem(e)}>
        <Card key='0'>
            <Accordion.Toggle as={Card.Header} eventKey='0'>
                <Row>
                    <Col sm={10} md={10} lg={10}>
                        <span className='font-weight-bold'>Order Details</span>
                    </Col>
                    <Col sm={2} md={2} lg={2} className='float-right'>
                        <FontAwesomeIcon icon={setAccordionIcon(activeAccordionItem, '0')} color='#4dabf7'/>
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
                <Card.Body className='ml-3 m-2'>
                    <AccordionRow name="Email Address" value={createdReturnDetails.emailAddress} />
                    <AccordionRow name={!hasValidSelazarIntegration ? "Order ID" : "Order Reference"}
                        value={createdReturnDetails.orderReference !== "" ? createdReturnDetails.orderReference : createdReturnDetails.orderID} />
                    <AccordionRow name="Retailer" value={createdReturnDetails.selectedRetailer} />
                    <AccordionRow name="Order Date" value={formatDate(createdReturnDetails.orderDate)} />
                </Card.Body>
            </Accordion.Collapse>
    </Card>
    <Card key='1'>
            <Accordion.Toggle as={Card.Header} eventKey='1'>
                <Row>
                    <Col sm={10} md={10} lg={10}>
                        <span className='font-weight-bold'>Product Details</span>
                    </Col>
                    <Col sm={2} md={2} lg={2} className='float-right'>
                    <FontAwesomeIcon icon={setAccordionIcon(activeAccordionItem, '1')} color='#4dabf7' />
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='1'>
                <Card.Body className='ml-3 m-2'>
                {showProducts && createdReturnDetails.products.length > 0 && 
                    createdReturnDetails.products.map(p =>
                    <Row>
                        <Col sm={2} md={3} lg={3}>
                            <p><span className='font-weight-bold'>Item Name</span></p>
                        </Col>
                        <Col sm={10} md={9} lg={9}>
                            <p key={p.id} className="mb-0">{p.name} {p.skuCode && <span>- {p.skuCode}</span>}</p>
                        </Col>
                    </Row>)}
                <AccordionRow name="Return Reason" value={createdReturnDetails.returnReason} />
                <AccordionRow name="Additional Info" value={createdReturnDetails.additionalInformation} />
                </Card.Body>
            </Accordion.Collapse>
    </Card>
    <Card key='2'>
            <Accordion.Toggle as={Card.Header} eventKey='2'>
                <Row>
                    <Col sm={10} md={10} lg={10}>
                        <span className='font-weight-bold'>Return Details</span>
                    </Col>
                    <Col sm={2} md={2} lg={2} className='float-right'>
                        <FontAwesomeIcon icon={setAccordionIcon(activeAccordionItem, '2')} color='#4dabf7' />
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='2'>
                <Card.Body className='ml-3 m-2'>
                <AccordionRow name="Return Robin ID" value={createdReturnDetails.returnRobinId} />
                <AccordionRow name="Date Created" value={formatDate(createdReturnDetails.createdDate)} />
                <AccordionRow name="Return Method" value={RETURN_METHODS[createdReturnDetails.returnMethod]} />
                {createdReturnDetails.returnMethod === 0 && <Fragment>
                    <Row>
                        <Col sm={2} md={3} lg={3}>
                            <p><span className='font-weight-bold'>Collection Address</span></p>
                        </Col>
                        <Col sm={10} md={9} lg={9}>
                            <p className="mb-1">{selectedAddressFull.line1}</p>
                            {selectedAddressFull.line2 && <p className="mb-1">{selectedAddressFull.line2}</p>}
                            <p className="mb-1">{selectedAddressFull.city}</p>
                            <p className="mb-1">{selectedAddressFull.county}</p>
                            <p>{selectedAddressFull.postcode}</p>
                        </Col>
                    </Row>
                </Fragment>}
                {createdReturnDetails.returnMethod === 0 && !isCreateReturnWizard && <Row>
                    <Col sm={2} md={3} lg={3}>
                        <p><span className='font-weight-bold'>Tracking Number</span></p>
                    </Col>
                    <Col sm={10} md={9} lg={9}>
                        <p>
                            <a href={`https://www.yodel.co.uk/track/${createdReturnDetails.returnCollectionInformation.trackingReference}?postcode=${createdReturnDetails.returnCollectionInformation.postcode}`} 
                                target="_blank"rel="noopener noreferrer">{createdReturnDetails.returnCollectionInformation.trackingReference}</a>
                        </p>
                    </Col>
                </Row>}
                <Row>
                    <Col sm={2} md={3} lg={3}>
                    <p><span className='font-weight-bold'>Status</span></p>
                    </Col>
                    <Col sm={10} md={9} lg={9}>
                        <p><span className={`mr-auto ${getStatusClass(createdReturnDetails.status)}`}>{createdReturnDetails.status}</span></p>
                    </Col>
                </Row>
                </Card.Body>
            </Accordion.Collapse>
    </Card>
</Accordion>)}

export {
    NoReturns,
    NoReturnsSearch,
    ReturnsFilter,
    OrderReference,
    ProductDetails,
    Details,
    ChangeHistory,
    SuccessMessage,
    ErrorMessage,
    CreateReturnSummaryAccordion
}