import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import RRLogo from '../../common/RRLogo'

export class RegistrationComplete extends Component {

    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    }

    render() {

        return (
            <div className="center">
                <div className="container">
                    <RRLogo />
                    <div className="center-block mb-5">
                        <h3>Account Created</h3>
                        <div className="d-block text-center mx-5 mt-5 mb-3">
                            <FontAwesomeIcon className="block text-center green" icon={faCheckCircle} size="6x" />
                        </div>
                        <p className="font-weight-bold text-center mb-5">You have successfully registered for Return Robin</p>
                        <Button variant="primary" block onClick={this.handleClick}>Login</Button>
                    </div>
                </div>
            </div>
        )
    }

}