import React, { Component } from 'react';
import { Form, FormGroup, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import FormValidator from '../../common/FormValidator';
import { GET, PUT, POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { WarningAlert, ErrorAlert } from '../../common/Alerts';
import { Address } from '../shared/Address';
import Confirm from '../../common/modals/Confirm';
import LoadingBar from '../../common/LoadingBar';

const POST_CODE_PATTERN = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

const getReturnWarningMessage = (returnsCount) => `You are unable to edit or remove this address as there are ${returnsCount} return(s) in progress associated to it. You can view returns for full details.`;

export class EditAddress extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your full name'
            },
            {
                field: 'line1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a vaild address'
            },
            {
                field: 'city',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid City'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid County'
            },
            {
                field: 'postcode',
                method: 'matches',
                args: [POST_CODE_PATTERN],
                validWhen: true,
                message: 'Enter a valid Postcode'
            },
            {
                field: 'addressType',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            }
        ]);

        this.state = {
            userId: JSON.parse(localStorage.getItem('user')).user.id.toString(),
            addressTypes: [],
            errorMessages: [],
            data: '',
            address: {
                name: '',
                line1: '',
                line2: '',
                city: '',
                county: '',
                postcode: '',
                notes: '',
                addressType: '',
                defaultAddress: false,
            },
            loading: true,
            validation: this.validator.valid(),
            showRemoveModal: false,
            returnsCount: 0,
            showDefaultSlide: true,
            disabledEdit: false
        }
    };

    validate = (address) => {
        return this.validator.validate(address);
    }

    async componentDidMount() {
        Promise.all([
            this.getAddressTypes(),
            this.getAddress(),
            this.checkReturns()
        ]);
        this.setState({ loading: false });
    }

    getAddress() {
        return GET(`${Endpoints.ADDRESS.GET.ADDRESS_GETBYID}${this.props.match.params.addressid}`)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else {
                    let address = {
                        ...result.data.address,
                        addressType: this.state.addressTypes.find(f => f.id === result.data.addressTypeID).type,
                        defaultAddress: result.data.default
                    };
                    this.setState({ data: result.data, address: address, showDefaultSlide: !address.defaultAddress })
                }
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving addresses."] }));
    }

    getAddressTypes() {
        return POST(Endpoints.ALL_ADDRESS_TYPES)
            .then(response => response.json())
            .then(result => {
                this.setState({ addressTypes: result ? result : [] });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving address types."] }));
    }

    checkReturns() {
        return GET(`${Endpoints.RETURN.GET.RETRIEVE_RETURNS_BYADDRESSID}/${this.props.match.params.addressid}`)
            .then(response => response.json())
            .then(result => {
                this.setState({ returnsCount: result.data, disabledEdit: result.data > 0 ? true : false });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving returns for address."] }));
    }

    handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            const { checked } = e.target;
            value = checked;
        }

        this.setState(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                ...{ [name]: value }
            }
        }));
    }

    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/address/youraddresses');
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { address, addressTypes, data } = this.state;
        const validation = this.validate(address);
        this.setState({ validation: validation });

        const viewModel = {
            addressTypeId: addressTypes.find(f => f.type === address.addressType).id,
            default: address.defaultAddress,
            address: address,
            addressID: address.id,
            id: data.id,
            active: true
        }

        if (validation.isValid) {
            return PUT(Endpoints.ADDRESS.PUT.EDIT_ADDRESS, viewModel)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data) this.setState({ errorMessages: result.data });
                        else this.setState({ errorMessages: result.message });
                    }
                    else this.props.history.push({ pathname: "/address/youraddresses" });
                }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the address."] }));
        }
    }

    handleClose = (e) => {
        this.setState({ showRemoveModal: false });
    }

    handleRemoveAddressModel = (e) => {
        e.preventDefault();
        this.setState({ showRemoveModal: true });
    }


    handleRemove = (e) => {
        e.preventDefault();

        const { address } = this.state;
        const validation = this.validate(address);
        this.setState({ validation: validation });

        const viewModel = {
            addressID: this.props.match.params.addressid,
        }

        if (validation.isValid) {
            return PUT(Endpoints.ADDRESS.PUT.REMOVE_ADDRESS, viewModel)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data) this.setState({ errorMessages: result.data });
                        else this.setState({ errorMessages: result.message, showRemoveModal: false });
                    }
                    else this.props.history.push({ pathname: "/address/youraddresses", state: { addressRemoved: true } });
                }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the address."] }));
        }
    }

    renderForm = () => {
        const { address, validation, errorMessages, addressTypes, returnsCount, showDefaultSlide, disabledEdit } = this.state;

        return (
            <Form onSubmit={this.handleSubmit} className="w-100">

                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                {returnsCount > 0 && <WarningAlert message={getReturnWarningMessage(returnsCount)} />}
                <Address
                    details={address}
                    validation={validation}
                    handleInputChange={this.handleInputChange}
                    addressTypes={addressTypes}
                    showDefault={showDefaultSlide}
                    disabledEdit={disabledEdit}
                />

                <FormGroup className="mt-3">
                    {!disabledEdit && <Button variant="outline-primary" block type="submit" value="Submit">Save Changes</Button>}
                    {showDefaultSlide && !disabledEdit ? <Button variant="outline-danger" block type="button" onClick={this.handleRemoveAddressModel}>Remove Address</Button> : null}
                </FormGroup>

                <Confirm
                    title="Remove Address" variant="outline-danger"
                    text="Are you sure that you want to remove this address?"
                    handleClose={this.handleClose}
                    handleConfirmAction={this.handleRemove}
                    buttonText="Remove Address"
                    linkText="Cancel and keep address"
                    closeLink={true}
                    show={this.state.showRemoveModal}
                />

            </Form>
        );
    }

    render() {
        const { loading } = this.state;

        return (
            <div className="main-content">
                <div className="main-container">
                    <div className="mb-2">
                        <FontAwesomeIcon icon={faChevronLeft} size="xs" /><Button variant="link" className="p-0  underline" onClick={this.handleClick}>Back to Addresses</Button>
                    </div>
                    <h3 className="py-2">Edit Address</h3>
                    <Row>
                        <Col sm={12} md={7}>
                            {loading ? <LoadingBar /> : this.renderForm()}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

}