import React, { Component } from 'react';
import HomeHeader from '../shared/HomeHeader';
import HomeFooter from '../shared/HomeFooter';

import './FAQ.scss';

class LeaveParcel extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={false} />
                <div className="faq-container">
                    <div className="faq-text">
                        <h1>Can I leave my parcel with my neighbour?</h1>
                        <p>Yes! You can choose whatever address you’d like as your pickup, as long as it is in the UK. If you would like to leave it with your neighbour, just choose their address when you create the return, instead of your own.</p>
                        <p>You can provide additional notes to our driver when you add the address, such as help to find the right house, or special collection instructions.</p>
                        <p>Your Return Robin account also saves your addresses for ease of use for future returns.</p>
                    </div>
                </div>
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default LeaveParcel;