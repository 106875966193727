import React from 'react';
import { Card } from 'react-bootstrap';

const WarehouseSelectionCard = ({ selectedId, warehouse, handleWarehouseInputChange, addressPending }) =>
    <Card className={warehouse.id === selectedId ? "card-selection-selected" : "card-selection"} onClick={() => handleWarehouseInputChange(true, warehouse.id, false, false)}>
        <p><strong>{warehouse.name}</strong></p>
        <p className="mb-0">{warehouse.address.line1}</p>
        {warehouse.address.line2 && <p className="mb-0">{warehouse.address.line2}</p>}
        <p className="mb-0">{warehouse.address.town}</p>
        <p className="mb-0">{warehouse.address.county}</p>
        <p className="mb-0">{warehouse.address.country}</p>
        <p>{warehouse.address.postcode}</p>
        <div className="mb-0">
            {warehouse.id === selectedId && addressPending && <label className="d-inline pending-label">Pending Approval</label>}
        </div> 
    </Card>

const RetailerSelectionCard = ({ selectedId, address, handleWarehouseInputChange, addressPending }) =>
    <Card className={address.id === selectedId ? "card-selection-selected" : "card-selection"} onClick={() => handleWarehouseInputChange(false, address.id, false, false)}>
        <p><strong>Return Robin</strong></p>
        <p className="mb-0">{address.line1}</p>
        {address.line2 && <p className="mb-0">{address.line2}</p>}
        <p className="mb-0">{address.city}</p>
        <p className="mb-0">{address.county}</p>
        <p>{address.postcode}</p>
        <div className="mb-0">
            {address.id === selectedId && addressPending && <label className="d-inline pending-label">Pending Approval</label>}
        </div> 
    </Card>

const RetailerCard = ({ address, selected }) =>
    <Card className={selected ? "card-address selected" : "card-address"}>
        <p><strong>Return Robin</strong></p>
        <p className="mb-0">{address.line1}</p>
        {address.line2 && <p className="mb-0">{address.line2}</p>}
        <p className="mb-0">{address.city}</p>
        <p className="mb-0">{address.county}</p>
        <p>{address.postcode}</p>
    </Card>

export {
    WarehouseSelectionCard,
    RetailerSelectionCard,
    RetailerCard
}
