import React, { Component } from 'react';
import HomeHeader from '../shared/HomeHeader';
import HomeFooter from '../shared/HomeFooter';

import './FAQ.scss';

class RobinScore extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={false} />
                <div className="faq-container">
                    <div className="faq-text">
                        <h1>What is my Robin Score and what impacts it?</h1>
                        <p>Your Robin score is a number from 0 to 100 that represents your history with using Return Robin. The higher the score, the better, as this tells the retailer that you are a good candidate to be using Return Robin.</p>
                        <p>When your return parcel is collected successfully you will gain points.  You will also gain more points every 28 days just by being an active customer.  The only way that your score will decrease is if there is a failed collection.  This means that if a courier attempted to collect your return parcel at your desired location and was unable to do so it will be reflected in your Return Robin score.</p>
                        <p>Although, if the return collection failed due to the courier, this will not impact your score.</p>
                        <p>You need to have at least 30 points to create a return.  If your score goes below 30 you will be unable to use the return service until it increases again.</p>
                        <div className="mt-4">
                            <img src="Images/horizontal-score-graphic.svg" alt="Robin Score Details" />
                        </div>
                    </div>
                </div>
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default RobinScore;