import React, { useCallback } from 'react';
import { Row, Col, Button, Modal, FormGroup, FormLabel } from 'react-bootstrap';
import Breadcrumb from '../../../common/Breadcrumb';
import LoadingBar from '../../../common/LoadingBar';
import Confirm from '../../../common/modals/Confirm'
import { formatDate } from '../../../../Utilities';
import { ChangeHistory, SuccessMessage, ErrorMessage } from '../../shared/Snippets';
import Endpoints from '../../../common/Endpoints';
import { GET, GET_ASYNC, PUT } from '../../../../Consumer';
import FormValidator from '../../../common/FormValidator';
import CustomDatePicker from '../../../common/inputs/CustomDatePicker';
import moment from 'moment-timezone';
import { bankHolidays } from '../../shared/BankHolidays';
import CreateReturnConfirmation from '../../customer/create/CreateReturnConfirmation';
import '../../shared/ReturnDetails.scss';

const CANCEL_SUCCESS_TITLE = "Return Cancelled Successfully";
const CANCEL_SUCCESS_TEXT = "This return has been cancelled and will no longer be collected.";
const CANCEL_ERROR_TITLE = "Return Cancel Failed";
const CANCEL_ERROR_TEXT = "Unable to cancel the return at this time.";

const EDIT_SUCCESS_TITLE = "Return Edited Successfully";
const EDIT_SUCCESS_TEXT = "This return has been edited successfully.";
const EDIT_ERROR_TITLE = "Return Edit Failed";
const EDIT_ERROR_TEXT = "Unable to edit the return at this time.";

let minDate = new Date();
minDate.setDate(minDate.getDate() + 2);
let maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 16);

let collectionDateValidation = new Date();
collectionDateValidation.setDate(collectionDateValidation.getDate() + 1);

const EditReturn = ({ collectionDate, handleShowHideEditModal }) => (
    <React.Fragment>
        <hr></hr>
        <section className="mb-0 mt-4">
            <p className="font-weight-bold">Need to change the collection date of the return?</p>
            <p>{`You can edit your return before the ${formatDate(collectionDate)}`}</p>
        </section>
        <Row>
            <Col sm={12} md={4} lg={2}>
                <Button variant="secondary" onClick={handleShowHideEditModal} block>Edit Return</Button>
            </Col>
        </Row>
    </React.Fragment>
);

const EditModal = ({ collectionDate, show, handleClose, returnDetailsValidation, handleEditReturn, handleReturnDetailsInputChange }) => {
    const isNotSundayOrBankHoliday = useCallback((date) => {
        console.log(bankHolidays);
        const dateAsString = date.toDateString();
        const day = date.getDay();
        return day !== 0 && !bankHolidays.includes(dateAsString);
    }, []);
    
    return (<Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>Edit Return</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormGroup className="mb-4">
                <FormLabel htmlFor="collectionDateField">Collection Date</FormLabel>
                <CustomDatePicker errorClass={returnDetailsValidation.collectionDate.isInvalid ? "input-error" : undefined} 
                    filterDate={isNotSundayOrBankHoliday} minDate={minDate} maxDate={maxDate} selected={new Date(collectionDate)} 
                    handleInputChange={handleReturnDetailsInputChange} />
                <span className="text-danger">{returnDetailsValidation.collectionDate.message}</span>
            </FormGroup>
        </Modal.Body>
        <Modal.Footer className="no-border text-center">
            <Row>
                <Col xs={{ span: 12, order: '2' }} sm={{ span: 12, order: '2' }} md={{ span: 8, order: '1' }} 
                    lg={{ span: 8, order: '1' }} className="col-link">
                    <Button variant="link" onClick={handleClose} className="cancel-link underline">Return and cancel edit</Button>
                </Col>
                <Col xs={{ span: 12, order: '1' }} sm={{ span: 12, order: '1' }} md={{ span: 4, order: '2' }} lg={{ span: 4, order: '2' }}>
                    <Button variant="primary" block onClick={handleEditReturn}>Edit Return</Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>)
};

const CancelReturn = ({ collectionDate, handleShowHideCancelModal }) => (
    <React.Fragment>
        <hr></hr>
        <section className="mb-0 mt-4">
            <p className="font-weight-bold">No longer need this return?</p>
            <p>{`You can cancel your return before the ${formatDate(collectionDate)}`}</p>
        </section>
        <Row>
            <Col sm={12} md={4} lg={2}>
                <Button variant="outline-danger" onClick={handleShowHideCancelModal}>Cancel Return</Button>
            </Col>
        </Row>
    </React.Fragment>
);

class ReturnDetails extends React.Component {

    constructor(props) {
        super(props);

        this.returnDetailsValidator = new FormValidator([
            {
                field: 'collectionDate',
                method: 'isAfter',
                args: [collectionDateValidation.toDateString()],
                validWhen: true,
                message: 'Select a collection date to continue'
            }
        ]);

        this.state = {
            loading: true,
            returnId: props.match.params.id || null,
            showEditModal: false,
            showCancelModal: false,
            returnDetails: {},
            showCancelSuccess: false,
            showCancelFailure: false,
            showEditSuccess: false,
            showEditFailure: false,
            collectionDate: null,
            returnDetailsValidation: this.returnDetailsValidator.valid(),
            viewStatusHistory: false,
            hasValidSelazarIntegration: false
        };
    }

    async componentDidMount() {
        Promise.all([this.getReturn()]);
    }

    getReturn = async () => {
        const { returnId } = this.state;

        GET(Endpoints.RETURN.GET.RETURN_DETAILS + returnId)
            .then(response => response.json())
            .then(async result => {
                const returnDetails = result.data;
                const error = returnDetails ? "" : "We are currently unable to retrieve details for this return.";
                const collectionDate = returnDetails && returnDetails.returnMethod === 0 ? returnDetails.returnCollectionInformation.collectionDate.split("T")[0] : undefined;
                this.setState({ returnDetails: returnDetails, collectionDate: collectionDate, error: error });

                await this.hasValidSelazarIntegration(returnDetails.selectedRetailerID);
            })
            .catch(error => console.log(error));
    }

    hasValidSelazarIntegration = async (selectedRetailerID) => {
        await GET_ASYNC(new URL(selectedRetailerID, Endpoints.SETTINGS.GET.VALID_SELAZAR_INTEGRATION))
            .then(response => { if (response.ok) return response.json(); })
            .then(result => {
                const hasValidSelazarIntegration = result ? result.data : false;
                this.setState({ hasValidSelazarIntegration: hasValidSelazarIntegration });
                this.setState({ loading: false }); 
            })
            .catch(error => console.log(error));
    }

    cancelReturn = () => {
        const { returnId } = this.state;
        return PUT(Endpoints.RETURN.PUT.CANCEL + returnId)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    this.setState({ showCancelFailure: true, showCancelModal: false, showEditFailure: false, showEditSuccess: false });
                }
            })
            .then(result => {
                const cancelSuccess = result.data ? true : false;
                this.setState({ showCancelSuccess: cancelSuccess, showCancelFailure: !cancelSuccess, showCancelModal: false, 
                                showEditFailure: false, showEditSuccess: false });
            });
    }

    handleReturnDetailsInputChange = (date) => this.setState({ collectionDate: date });

    handleShowHideEditModal = () => this.setState((prevState) => ({ showEditModal: !prevState.showEditModal }));

    handleShowHideCancelModal = () => this.setState((prevState) => ({ showCancelModal: !prevState.showCancelModal }));

    handleEditReturn = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { collectionDate, returnDetails } = this.state;
        const viewModel = {
            collectionDate: collectionDate
        }

        const returnDetailsValidation = this.returnDetailsValidator.validate(viewModel);
        this.setState({ returnDetailsValidation: returnDetailsValidation });

        if (returnDetailsValidation.isValid) {
            this.handleShowHideEditModal();

            const collectionDateMoment = moment.utc(viewModel.collectionDate).tz("Europe/London");
            const formattedViewModel = {
                collectionDate: collectionDateMoment.format().split("T")[0]
            }

            return PUT(Endpoints.RETURN.PUT.COLLECTION_DATE + `${returnDetails.id}/collection`, formattedViewModel)
                .then(response => response.json())
                .then(async (result) => {
                    if (result.error) {
                        this.setState({ showEditFailure: true, showCancelFailure: false, showCancelSuccess: false, loading: false })
                    } else {
                        const success = result.data;
                        this.setState({ showEditSuccess: success, showEditFailure: !success, showCancelSuccess: false, loading: false });
                        await this.getReturn();
                    }
                });
        }
    }

    handleCancelReturn = async () => {
        this.setState({ loading: true });
        await this.cancelReturn();
        await this.getReturn();
        this.setState({ loading: false });
    }

    handleViewStatusHistory = async () => {
        this.setState({ viewStatusHistory: true });
    }

    handleCloseStatusHistory = async () => {
        this.setState({ viewStatusHistory: false });
    }

    render() {
        const { returnDetails, viewStatusHistory, collectionDate, returnDetailsValidation, loading, showEditModal, 
            showCancelModal, showCancelSuccess, showCancelFailure, showEditSuccess, showEditFailure, 
            hasValidSelazarIntegration } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> 
                    : viewStatusHistory 
                        ? <ChangeHistory
                            isRetailer={false}
                            changeHistory={returnDetails.changeHistory}
                            close={this.handleCloseStatusHistory} 
                            retailer={returnDetails.selectedRetailer}
                            orderNumber={returnDetails.orderID} /> 
                        : <React.Fragment>
                            <div className="main-content return-details">
                                <Breadcrumb link="/return" text="Back to Returns" />
                                <h3 className="mb-3">Return Information</h3>
                                {showCancelSuccess && <SuccessMessage heading={CANCEL_SUCCESS_TITLE} message={CANCEL_SUCCESS_TEXT} />}
                                {showCancelFailure && <ErrorMessage heading={CANCEL_ERROR_TITLE} message={CANCEL_ERROR_TEXT} />}
                                {showEditSuccess && <SuccessMessage heading={EDIT_SUCCESS_TITLE} message={EDIT_SUCCESS_TEXT} />}
                                {showEditFailure && <ErrorMessage heading={EDIT_ERROR_TITLE} message={EDIT_ERROR_TEXT} />}
                                <CreateReturnConfirmation
                                    createdReturnDetails={returnDetails}
                                    selectedAddressFull={returnDetails?.returnCollectionInformation?.selectedAddressFull}
                                    showProducts={true}
                                    showCreatedAlert={false}
                                    hasValidSelazarIntegration={hasValidSelazarIntegration} />
                                {returnDetails?.returnCollectionInformation?.canCancel && <EditReturn collectionDate={returnDetails?.returnCollectionInformation?.collectionDate} handleShowHideEditModal={this.handleShowHideEditModal} />}
                                {returnDetails?.returnCollectionInformation?.canCancel && <CancelReturn collectionDate={returnDetails?.returnCollectionInformation?.collectionDate} handleShowHideCancelModal={this.handleShowHideCancelModal} />}
                            </div>
                            <EditModal
                                collectionDate={collectionDate}
                                show={showEditModal}
                                handleClose={this.handleShowHideEditModal} 
                                returnDetailsValidation={returnDetailsValidation}
                                handleEditReturn={this.handleEditReturn} 
                                handleReturnDetailsInputChange={this.handleReturnDetailsInputChange} />
                            <Confirm
                                title="Cancel Return"
                                variant="outline-danger"
                                linkText="Return and keep return" 
                                block="true"
                                buttonText="Cancel Return"
                                text="Are you sure that you no longer want process this return?" 
                                show={showCancelModal}
                                handleClose={this.handleShowHideCancelModal} 
                                handleConfirmAction={this.handleCancelReturn}
                                closeLink={true} />
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ReturnDetails;