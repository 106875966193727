import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import 'react-intl-tel-input/dist/main.css';
import PrivacyPolicy from '../../privacypolicy.pdf';
import TermsAndConditions from '../../termsandconditions.pdf';

const Confirmation = ({ confirmation, confirmationValidation, handleConfirmationInputChange }) =>
    <FormGroup className={`custom-control custom-checkbox ${!confirmationValidation && "custom-input-error"}`}>
        <input id="terms-confirmation" className="custom-control-input" type="checkbox" checked={confirmation} onChange={handleConfirmationInputChange} />
        <FormLabel className="custom-control-label" htmlFor="terms-confirmation">
            <span className="custom-checkbox-label">I confirm and agree to the <a href={PrivacyPolicy} target='_blank' rel="noopener noreferrer" alt='Return Robin Privacy Policy - opens in a new window'>privacy policy</a> and <a href={TermsAndConditions} target='_blank' rel="noopener noreferrer" alt='Return Robin Terms and Conditions - opens in a new window'>terms & conditions</a></span>
        </FormLabel>
    </FormGroup>

export default Confirmation;