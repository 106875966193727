import React from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { WarningAlert, ErrorAlert } from '../../common/Alerts';
import RRLogo from '../../common/RRLogo';
import NewPassword from '../../common/inputs/NewPassword';

const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_TOKEN;

const ResetPasswordForm = ({ resetPasswordDetails, validation, errorMessages, recaptchaError, handleInputChange, handleSubmit, handleRecaptcha }) => (
    <Form>
        {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
        <p className="text">Enter your e-mail address and we will send you an email with your information on how to reset your password.</p>

        <FormGroup>
            <FormLabel htmlFor="verificationCodeField">Email</FormLabel>
            <FormControl type="text" className={validation.email.isInvalid ? "input-error" : undefined} value={resetPasswordDetails.email} name="email" onChange={handleInputChange} />
            <span className="text-danger">{validation.email.message}</span>
        </FormGroup>

        <div className="center">
            <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={handleRecaptcha} />
        </div>
        {recaptchaError && <p className="center text-danger">Please complete captcha</p>}

        <Button variant="primary" block className="mt-4" type="submit" onClick={handleSubmit}>Reset Password</Button>
    </Form>
);

const KnowPasswordLink = ({ link }) => (
    <p className="mt-3 text-center">Know your password?  <Link className="link-button" to={link}>Login</Link></p>
);

const PasswordLinkSentCard = ({ email, errorMessages, handleResendRequest, handleLogin, warningMessages }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>Reset Password</h3>
                <p className="text">If an account exists for {email}, you will receive an email with a reset password link. Click the link in your email to reset your password.</p>
                <p className="font-weight-bold m-0">Didn't receive your password email?</p>
                <p>Check your junk folder or resend the password reset email. If you need further help contact <a href="mailto:hello@returnrobin.com">hello@returnrobin.com</a></p>
                {warningMessages.length > 0 && <WarningAlert message={warningMessages} />}
                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                <Button variant="outline-primary" block className="mb-4" type="submit" value="Submit" onClick={handleResendRequest}>Resend password reset email</Button>
                <p className="font-weight-bold mb-0">Have you reset your password?</p>
                <Button variant="primary" block type="submit" value="Submit" onClick={handleLogin}>Login</Button>
            </div>
        </div>
    </div>
);

const ChangePasswordCard = ({ handleSubmit, errorMessages, addResetLink, passwordStatus, passwordInputType, passwordDetails, handleInputChange, handleShowHidePassword, passwordInputIcon, validation, passwordChecks, submitted, handleLogin, handleResendRequest }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>Reset Password</h3>
                <Form onSubmit={handleSubmit}>
                    {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                    {addResetLink && <WarningAlert message="The password reset link has expired. Send a new password reset email."/> }
                    {errorMessages.length === 0 && !addResetLink ? 
                    <React.Fragment>
                        <NewPassword passwordStatus={passwordStatus} passwordInputType={passwordInputType} formPassword={passwordDetails.password} handleInputChange={handleInputChange} handleShowHidePassword={handleShowHidePassword} passwordInputIcon={passwordInputIcon} validationPasswordMessage={validation.password.message} passwordChecks={passwordChecks} submitted={submitted} />
                        <Button variant="primary" block type="submit" value="Submit">Save new password</Button>
                    </React.Fragment> : 
                    <React.Fragment>
                        <Button variant="outline-primary" block className="mb-4" type="submit" value="Submit" onClick={handleResendRequest}>Resend password reset email</Button>
                        <p className="font-weight-bold m-0">Didn't receive your password email?</p>
                        <p>Check your junk folder or resend the password reset email. If you need further help contact <a href="mailto:hello@returnrobin.com">hello@returnrobin.com</a></p>
                        <p className="font-weight-bold mb-0">Have you reset your password?</p>
                        <Button variant="primary" block type="submit" value="Submit" onClick={handleLogin}>Login</Button>
                    </React.Fragment>}
                    
                </Form>
            </div>
        </div>
    </div>
);

const ChangePasswordCompleteCard = ({ handleLogin }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>Password Reset Successfully</h3>
                <p>Your Return Robin password has been successfully reset.</p>
                <div className="d-block text-center m-5">
                    <FontAwesomeIcon className="block text-center green" icon={faCheckCircle} size="6x" />
                </div>
                <Button variant="primary" type="submit" value="Submit" onClick={handleLogin} block>Login</Button>
            </div>
        </div>
    </div>
);

const STRONG_PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[@$!%*#?&]).{6,}/;
const NUMBER_PATTERN = /^(?=.*[0-9])/;
const SPECIAL_CHAR_PATTERN = /^(?=.*[@$!%*#?&])/;
const LENGTH_PATTERN = /^.{6,}/;

export {
    ResetPasswordForm,
    KnowPasswordLink,
    PasswordLinkSentCard,
    ChangePasswordCompleteCard,
    ChangePasswordCard,
    STRONG_PASSWORD_PATTERN,
    NUMBER_PATTERN,
    SPECIAL_CHAR_PATTERN,
    LENGTH_PATTERN
}