import React, { Component } from 'react';
import RRLogo from '../../common/RRLogo'
import FormValidator from '../../common/FormValidator';
import Endpoints from '../../common/Endpoints';
import { POST  } from '../../../Consumer';
import { ResetPasswordForm, KnowPasswordLink } from '../shared/Snippets';

class ForgotPassword extends Component {

    constructor(props) {

        super(props);

        this.validator = new FormValidator([
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'Please enter your email address'
            }
        ]);

        this.state = {
            resetPasswordDetails: {
                email: '',
            },
            errorMessages: [],
            recaptcha: false,
            recaptchaError: false,
            validation: this.validator.valid(),
            error: false,
        }

    }
    
    handleRecaptcha = () => {
        this.setState({ recaptcha: true });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            resetPasswordDetails: {
                ...prevState.resetPasswordDetails,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { resetPasswordDetails, recaptcha } = this.state;

        const validation = this.validator.validate(resetPasswordDetails);
        this.setState({ validation: validation, recaptchaError: !recaptcha });

        if (recaptcha && validation.isValid) {
            return POST(Endpoints.PASSWORD.POST.RETAILER_RESET_REQUEST, resetPasswordDetails)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) result.data !== null ? this.setState({ errorMessages: result.data }) : this.setState({ errorMessages: result.message });
                    else this.props.history.push({ pathname: "/retailer/forgotpassword/linksent", state: { email: resetPasswordDetails.email } });
                }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the password."] }));
        }
    }

    render() {
        const { resetPasswordDetails, validation, errorMessages, recaptchaError } = this.state;

        return (
            <div className="center">
                <div className="container">
                    <RRLogo />
                    <div className="center-block mb-5">
                        <h3>Reset Password</h3>
                        <ResetPasswordForm resetPasswordDetails={resetPasswordDetails} validation={validation} errorMessages={errorMessages} recaptchaError={recaptchaError} handleInputChange={this.handleInputChange} handleSubmit={this.handleSubmit} handleRecaptcha={this.handleRecaptcha} />
                        <KnowPasswordLink link={"/retailer/login"} />
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;