import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import RRLogo from '../../common/RRLogo'
import FormValidator from '../../common/FormValidator';
import { ErrorAlert } from '../../common/Alerts';
import { POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';

const POSTCODE_PATTERN = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

const AddressForm = ({ addressDetails, errorMessages, validation, handleInputChange, handleSubmit }) => (
    <Form>
        {errorMessages.length > 0 && <ErrorAlert errors={errorMessages} />}
        <FormGroup>
            <FormLabel htmlFor="addressOneField">Address Line 1</FormLabel>
            <FormControl id="addressOneField" className={validation.line1.isInvalid ? "input-error" : ""} type="text" name="line1" maxLength="50" onChange={handleInputChange} value={addressDetails.line1} />
            <span className="text-danger">{validation.line1.message}</span>
        </FormGroup>

        <FormGroup>
            <FormLabel htmlFor="addressTwoField">Address Line 2 <span className="form-label-optional font-weight-normal">- optional</span></FormLabel>
            <FormControl id="addressTwoField" type="text" name="line2" maxLength="50" onChange={handleInputChange} value={addressDetails.line2} />
        </FormGroup>

        <FormGroup>
            <FormLabel htmlFor="cityField">City</FormLabel>
            <FormControl id="cityField" className={validation.city.isInvalid ? "input-error" : ""} type="text" name="city" maxLength="50" onChange={handleInputChange} value={addressDetails.city} />
            <span className="text-danger">{validation.city.message}</span>
        </FormGroup>

        <FormGroup>
            <FormLabel htmlFor="countyField">County</FormLabel>
            <FormControl id="countyField" className={validation.county.isInvalid ? "input-error" : ""} type="text" name="county" maxLength="50" onChange={handleInputChange} value={addressDetails.county} />
            <span className="text-danger">{validation.county.message}</span>
        </FormGroup>

        <FormGroup>
            <FormLabel htmlFor="postcodeField">Postcode</FormLabel>
            <FormControl id="postcodeField" className={(validation.postcode.isInvalid) ? "input-error" : ""} type="text" name="postcode" maxLength="50" onChange={handleInputChange} value={addressDetails.postcode} />
            <span className="text-danger">{validation.postcode.message}</span>
        </FormGroup>

        <Button variant="primary" className="mt-5" onClick={handleSubmit} block>Add Address</Button>
    </Form>
);


class FirstAddress extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'line1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid address'
            },
            {
                field: 'city',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid city'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid county'
            },
            {
                field: 'postcode',
                method: 'matches',
                args: [POSTCODE_PATTERN],
                validWhen: true,
                message: 'Enter a valid postcode'
            }
        ]);

        this.state = {
            errorMessages: [],
            addressDetails: {
                line1: '',
                line2: '',
                city: '',
                county: '',
                postcode: '',
            },
            validation: this.validator.valid()
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            addressDetails: {
                ...prevState.addressDetails,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { addressDetails } = this.state;

        const validation = this.validator.validate(addressDetails);
        this.setState({ validation: validation });

        const user = JSON.parse(localStorage.getItem('user'));

        const viewModel = {
            address: addressDetails
        }

        if (validation.isValid) {
            return POST(Endpoints.RETAILER.POST.CREATE_ADDRESS, viewModel)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data) this.setState({ errorMessages: result.data });
                        else this.setState({ errorMessages: result.message });
                    }
                    else {
                        user.setupComplete = true;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.props.history.push({ pathname: "/retailer/address/complete" });
                    }
                }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the address."] }));
        }
    }


    render() {
        const { addressDetails, errorMessages, validation } = this.state;

        return (
            <div className="center">
                <div className="container">
                    <RRLogo />
                    <div className="center-block mb-5">
                        <h3>Add Returns Address</h3>
                        <div className="form-input-description mb-3">
                            <p>The address that your returns will be sent to must be located in the United Kingdom</p>
                        </div>
                        <AddressForm addressDetails={addressDetails} errorMessages={errorMessages} validation={validation} handleInputChange={this.handleInputChange} handleSubmit={this.handleSubmit} />
                    </div>
                </div>
            </div>
        )
    }
}

export default FirstAddress;