import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const Mobile = ({ value, handlePhoneNumberChange, validationMessage, invalidPhoneNumber }) =>
    <FormGroup>
        <link rel="stylesheet" href="intlTelInput.css"></link>
        <FormLabel htmlFor="phoneField">Mobile Number</FormLabel>
        <IntlTelInput required id="phoneField" value={value} onPhoneNumberChange={handlePhoneNumberChange}
            type="tel" maxLength="50" fieldName="phoneNumber" defaultCountry={'GB'} preferredCountries={['GB']}
            containerClassName="intl-tel-input" placeholder="" inputClassName={invalidPhoneNumber ? "form-control intl-tel-input-field input-error" : "form-control intl-tel-input-field"} />
        <span className="text-danger">{validationMessage}</span>
    </FormGroup>

export default Mobile;

