import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Row, Col } from 'react-bootstrap';
import PrivacyPolicy from '../../../privacypolicy.pdf';
import TermsAndConditions from '../../../termsandconditions.pdf';

import './HomeFooter.scss';

const About = () => (
    <Col sm={12} md={3} className="footer-column">
        <section>
            <h4>About Return Robin</h4>
            <Link to={`/`}>Return</Link>
            <a href="https://support.returnrobin.com/" target='_blank' rel="noopener noreferrer" alt='Help & Advice - opens in a new window'>Help & Advice</a>
            <a href={TermsAndConditions} target='_blank' rel="noopener noreferrer" alt='Return Robin Terms and Conditions - opens in a new window'>Terms & Conditions</a>
            <a href={PrivacyPolicy} target='_blank' rel="noopener noreferrer" alt='Return Robin Privacy Policy - opens in a new window'>Privacy Policy</a>
            <a href="https://www.selazar.com/" target='_blank' rel="noopener noreferrer" alt='Powered by Selazar - opens in a new window'>Powered by Selazar</a>
        </section>
    </Col>
);

const Address = () => (
    <Col sm={12} md={3} className="footer-column">
        <section>
            <h4>Address</h4> 
            <p>124 City Road</p>
            <p>London</p>
            <p>England</p>
            <p>EC1V 2NX</p>
        </section>
    </Col>
);

const GetInTouch = () => (
    <Col sm={12} md={3} className="footer-column">
        <section>
            <h4>Get In Touch</h4>
            <p className="title">Email</p>
            <a href="mailto:hello@returnrobin.com">hello@returnrobin.com</a>
            <p className="title">Phone</p>
            <a href="tel:02045665160">0204 566 5160</a>
        </section>
    </Col>
);

const Connect = () => (
    <Col sm={12} md={3} className="footer-column">
        <section>
            <h4>About Return Robin</h4>
            <a target='_blank' rel="noopener noreferrer" href="https://www.linkedin.com/company/return-robin/"><FontAwesomeIcon icon={faLinkedin} /> Linkedin</a>
            <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/ReturnRobin1"><FontAwesomeIcon icon={faTwitter} /> Twitter</a>
            <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/ReturnRobin-111811310607361"><FontAwesomeIcon icon={faFacebook} /> Facebook</a>
            <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/return_robin/"><FontAwesomeIcon icon={faInstagram} /> Instagram</a>
        </section>
    </Col>
);

class HomeFooter extends Component {

    render() {
        return (
            <footer className="footer-home">
                <div className="main-content">
                    <Row className="m-auto pt-5 px-3">
                        <About />
                        <Address />
                        <GetInTouch />
                        <Connect />
                    </Row>
                </div>
            </footer>
        )
    }
}

export default HomeFooter;