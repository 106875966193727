
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { ErrorAlert } from '../../../common/Alerts';
import { useHistory } from 'react-router';

const CompleteCard = ({ errorMessages }) => {

    const { push } = useHistory();

    return (
        <Row>
            <Col sm={12} md={8}>
                <h6 className="mb-1">There was a problem processing your card</h6>
                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                <Button variant="primary" className="px-4 float-right" onClick={() => push('/retailer/settings/payments')}>Back to Payments</Button>
            </Col>
        </Row>
    );
}

export default CompleteCard;