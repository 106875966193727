import React, { Component, Fragment } from 'react';
import { Row, Col, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import FormValidator from '../../../common/FormValidator';
import { GET, POST } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { RetailerCard } from '../../../common/inputs/AddressSelectionCards';
import Slider from '../../../common/inputs/Slider';

const CYBERSOURCE_CHECKOUT_URL = process.env.REACT_APP_CYBERSOURCE_CHECKOUT;

const CardHolderAddress = ({ address, addressValidation, handleAddressInputChange }) =>
    <Fragment>
        <h4 className="mb-3">New Cardholder Address</h4>
        <FormGroup>
            <FormLabel htmlFor="addressLine1">Address Line 1</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="addressLine1" onChange={(e) => handleAddressInputChange("addressLine1", e.target.value)} value={address.addressLine1} />
            <span className="text-danger">{addressValidation.addressLine1.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="addressLine2">Address Line 2  <span className="form-label-optional font-weight-normal">- optional</span></FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="addressLine2" onChange={(e) => handleAddressInputChange("addressLine2", e.target.value)} value={address.addressLine2} />
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="town">Town</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="town" onChange={(e) => handleAddressInputChange("town", e.target.value)} value={address.town} />
            <span className="text-danger">{addressValidation.town.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="postCode">Postcode</FormLabel>
            <FormControl type="text" size="20" maxLength="20" id="postCode" onChange={(e) => handleAddressInputChange("postCode", e.target.value)} value={address.postCode} />
            <span className="text-danger">{addressValidation.postCode.message}</span>
        </FormGroup>
    </Fragment>

const AddressDetails = ({ retailerAddress, customAddress, useCustomAddress, addressValidation, handleToggleChange, handleAddressInputChange, handlePaymentClick }) =>
    <Fragment>
        <h4 className="mb-3">Cardholder Address</h4>
        <RetailerCard address={retailerAddress} selected={!useCustomAddress} />
        <div className="my-3">
            <Slider id="customAddress" name="customAddress" label="Use another address" checked={useCustomAddress} onChange={() => handleToggleChange(!useCustomAddress)} />
        </div>
        {useCustomAddress &&
            <CardHolderAddress
                address={customAddress}
                addressValidation={addressValidation}
                handleAddressInputChange={handleAddressInputChange}
            />
        }
        <FormGroup>
            <div className="float-right d-none d-sm-block">
                <Button variant="primary" onClick={handlePaymentClick}>Proceed to Payment Details</Button>
            </div>
            <div className="d-block d-sm-none text-center">
                <Button variant="primary" block onClick={handlePaymentClick}>Proceed to Payment Details</Button>
            </div>
        </FormGroup>
    </Fragment>

const CyberSourceForm = ({ signedModel, paymentFormRef }) =>
    <form id="payment_form" action={CYBERSOURCE_CHECKOUT_URL} method="post" ref={paymentFormRef}>
        <input type="hidden" name="access_key" value={signedModel.accessKey} />
        <input type="hidden" name="profile_id" value={signedModel.profileID} />
        <input type="hidden" name="transaction_uuid" value={signedModel.transactionID} />
        <input type="hidden" name="signed_field_names" value={signedModel.signedFieldNames} />
        <input type="hidden" name="signature" value={signedModel.signature} />
        <input type="hidden" name="unsigned_field_names" />
        <input type="hidden" name="signed_date_time" value={signedModel.signedDateTime} />
        <input type="hidden" name="locale" value="en-GB" />
        <input type="hidden" name="transaction_type" size="50" value={signedModel.transactionType} />
        <input type="hidden" name="reference_number" size="50" value={signedModel.referenceNumber} />
        <input type="hidden" name="amount" size="25" value={signedModel.amount} />
        <input type="hidden" name="currency" size="25" value={signedModel.currency} />
        <input type="hidden" name="bill_to_company_name" size="25" value={signedModel.billToCompanyName} />
        <input type="hidden" name="bill_to_forename" size="25" value={signedModel.billToForename} /><br />
        <input type="hidden" name="bill_to_surname" size="25" value={signedModel.billToSurname} /><br />
        <input type="hidden" name="bill_to_email" size="25" value={signedModel.billToEmail} /><br />
        <input type="hidden" name="bill_to_address_line1" size="25" value={signedModel.billToAddressLine1} />
        <input type="hidden" name="bill_to_address_line2" size="25" value={signedModel.billToAddressLine2} />
        <input type="hidden" name="bill_to_address_city" size="25" value={signedModel.billToAddressCity} />
        <input type="hidden" name="bill_to_address_state" size="25" value={signedModel.billToAddressState} />
        <input type="hidden" name="bill_to_address_country" size="25" value={signedModel.billToAddressCountry} />
        <input type="hidden" name="bill_to_address_postal_code" size="25" value={signedModel.billToAddressPostalCode} />
        <input type="hidden" name="bill_to_phone" size="25" value={signedModel.phoneNumber} />
    </form>

export class CardForm extends Component {

    addressValidator = new FormValidator([
        {
            field: 'addressLine1',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter address line 1 of the account holder's address`
        },
        {
            field: 'town',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the town of the account holder's address`
        },
        {
            field: 'postCode',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the postcode of the account holder's address`
        }
    ]);

    paymentFormRef = React.createRef();

    state = {
        loading: true,
        customAddress: {
            addressLine1: '',
            addressLine2: '',
            town: '',
            postCode: ''
        },
        retailerAddress: {},
        useCustomAddress: false,
        addressValidation: this.addressValidator.valid(),
        signedModel: {
            accessKey: '',
            profileID: '',
            signature: '',
            transactionID: '',
            signedFieldNames: '',
            signedDateTime: '',
            transactionType: '',
            referenceNumber: '',
            amount: '',
            currency: '',
            billToEmail: '',
            billToForename: '',
            billToSurname: '',
            billToCompanyName: '',
            billToAddressLine1: '',
            billToAddressLine2: '',
            billToAddressCity: '',
            billToAddressState: '',
            billToAddressCountry: '',
            billToAddressPostalCode: '',
            phoneNumber: ''
        }
    }

    async componentDidMount() {
        await this.getRetailerAddress();
        this.setState({ loading: false });
    }

    getRetailerAddress = () =>
        GET(Endpoints.SETTINGS.GET.RETAILER_ADDRESS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const retailerAddress = results ? results.data[0] : {};
                this.setState({ retailerAddress: retailerAddress });
            })
            .catch(error => console.log(error));

    handleToggleChange = (value) => this.setState({ useCustomAddress: value });

    handleAddressInputChange = (name, value) => this.setState(prevState => ({ customAddress: { ...prevState.customAddress, [name]: value } }));

    createTransaction = (retailerAddress) =>
        POST(Endpoints.FINANCE.CARD.POST.NEW, retailerAddress)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ signedModel: results, loading: false });
                this.handleSubmit();
            })
            .catch(error => console.log(error));

    handlePaymentClick = () => {
        const { customAddress, useCustomAddress, retailerAddress } = this.state

        if (useCustomAddress) {
            const addressValidation = this.addressValidator.validate(customAddress);
            this.setState({ addressValidation: addressValidation });
            if (addressValidation.isValid) {
                this.setState({ loading: true });
                return this.createTransaction(customAddress)
            }
            else return;
        }

        const retailerAddressVm = {
            addressLine1: retailerAddress.line1,
            addressLine2: retailerAddress.line2,
            town: retailerAddress.city,
            postCode: retailerAddress.postcode,
        }

        this.setState({ loading: true });
        return this.createTransaction(retailerAddressVm);
    }

    handleSubmit = () => this.paymentFormRef.current && this.paymentFormRef.current.submit();

    render() {
        const { loading, retailerAddress, customAddress, useCustomAddress, addressValidation, signedModel } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <h3 className="mb-4">Set up Card Payment</h3>
                    <Row>
                        <Col sm={12} md={6}>
                            <AddressDetails
                                retailerAddress={retailerAddress}
                                customAddress={customAddress}
                                useCustomAddress={useCustomAddress}
                                addressValidation={addressValidation}
                                handleToggleChange={this.handleToggleChange}
                                handleAddressInputChange={this.handleAddressInputChange}
                                handlePaymentClick={this.handlePaymentClick}
                            />
                            <CyberSourceForm signedModel={signedModel} paymentFormRef={this.paymentFormRef} />
                        </Col>
                    </Row>
                </Fragment>
        )
    }
}

export default CardForm;
