import React, { Component } from 'react';
import Header from './Header';

import './Layout.scss';

export class Layout extends Component {
  render() {
    const { onLogout, isRetailer } = this.props;

    return (
      <React.Fragment>
        <Header onLogout={onLogout} isRetailer={isRetailer} />
        <div className="content-wrapper">
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}