import React from 'react';
import { Button } from 'react-bootstrap';
import RRLogo from '../../common/RRLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const AddressCheckCard = ({ handleClick, summaryText, buttonText, infoTitle, infoText }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>One Final Step</h3>
                <p>{summaryText}</p>
                <Button variant="primary" block onClick={handleClick}>{buttonText}</Button>
                <p className="font-weight-bold mt-3">{infoTitle}</p>
                <p>{infoText}</p>
            </div>
        </div>
    </div>
);

const AddressConfirmationCard = ({ handleClick, titleText, infoText, buttonText }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>{titleText}</h3>
                <div className="d-block text-center m-5">
                    <FontAwesomeIcon className="block text-center green" icon={faCheckCircle} size="6x" />
                </div>
                <div className="text-center">
                    <p>{infoText}</p>
                </div>
                <Button variant="primary" block onClick={handleClick}>{buttonText}</Button>
            </div>
        </div>
    </div>
);

export {
    AddressCheckCard,
    AddressConfirmationCard
}

