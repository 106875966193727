import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RRLogo from '../../common/RRLogo';
import { WarningAlert, ErrorAlert } from '../../common/Alerts';

const Header = () => (
    <React.Fragment>
        <RRLogo />
        <div className="center-block mb-0">
            <h3>Sign In</h3>
            <h6>Sign in with email</h6>
        </div>
    </React.Fragment>
);

const SignInMessaging = ({ errorMessages, warning, signInRequired, heading }) => (
    <React.Fragment>
        {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} heading={heading} />}
        {warning && <WarningAlert message="The email address you have entered is associated with an account." linkRequired={signInRequired} link="login" linkText="Sign in" linkPostText="to use Return Robin." />}
    </React.Fragment>
);

const SignInForm = ({ form, handleSubmit, validation, handleInputChange, passwordInputIcon, passwordInputType, handleShowHidePassword, forgotPasswordLink = "/forgotpassword", showRetailerLink = false }) => (
    <Form onSubmit={handleSubmit}>
        <FormGroup>
            <FormLabel htmlFor="emailField">Email</FormLabel>
            <FormControl id="emailField" className={validation.email.isInvalid ? "input-error" : undefined} type="email" name="email" maxLength="50" onChange={handleInputChange} value={form.email} />
            <span className="text-danger">{validation.email.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="passwordField" className="font-weight-bold">Password</FormLabel>
            <FormControl id="passwordField" className={validation.password.isInvalid ? "input-error" : undefined} maxLength="50" type={passwordInputType} name="password" value={form.password} onChange={handleInputChange} />
            <span className="form-password-icon" onClick={handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
            <span className="text-danger">{validation.password.message}</span>
        </FormGroup>
        <Link className="link-button" to={forgotPasswordLink}>Forgot your password?</Link>
        <FormGroup>
            <Button variant="primary" type="submit" value="Submit" block>Login</Button>
        </FormGroup>
        {showRetailerLink ? <Link className="link-button" to={'/retailer/login'}>Retailer? Log in here.</Link> : <Link className="link-button" to={'/login'}>Customer? Log in here.</Link>}
    </Form>
);

const CreateAccount = ({ title, text, link, buttonText }) => (
    <div className="center-block mb-5">
        <h3>{title}</h3>
        <h6>{text}</h6>
        <LinkContainer to={link}>
            <Button variant="secondary" block>{buttonText}</Button>
        </LinkContainer>
    </div>
);

export {
    Header,
    SignInMessaging,
    SignInForm,
    CreateAccount
}