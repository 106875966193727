import React from 'react';
import { Row } from 'react-bootstrap';
import { faUndo, faAddressBook, faUser } from '@fortawesome/free-solid-svg-icons';
import { DashboardTile } from '../shared/Snippets';

import '../shared/Dashboard.scss';

class Dashboard extends React.Component {

    handleTileClick = (e, link) => {
        e.preventDefault();
        this.props.history.push(link);
    }

    render() {
        return (
            <div className="main-content">
                <h3>Welcome to Return Robin!</h3>
                <Row>
                    <DashboardTile icon={faUndo} iconClass="icon-purple" title="Returns" handleClick={(e) => this.handleTileClick(e, "/return")}/>
                    <DashboardTile icon={faAddressBook} iconClass="icon-orange" title="Address Book" handleClick={(e) => this.handleTileClick(e, "/address/youraddresses")} />
                    <DashboardTile icon={faUser} iconClass="icon-green" title="Your Profile" handleClick={(e) => this.handleTileClick(e, "/profile")} />
                </Row>
            </div>
        );
    }
}


export default Dashboard;