import React, { Component } from 'react';
import { AddressConfirmationCard } from '../shared/Snippets';

const TITLE_TEXT = "Completed";
const INFO_TEXT = "You have successfully created your Return Robin account.";
const BUTTON_TEXT = "Continue to homepage";

class AddAddressConfirmation extends Component {

    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    }

    render() {

        return (
            <AddressConfirmationCard handleClick={this.handleClick} titleText={TITLE_TEXT} infoText={INFO_TEXT} buttonText={BUTTON_TEXT} />
        )
    }
}

export default AddAddressConfirmation;