import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Image } from 'react-bootstrap';
import SettingsNav from '../../common/nav/SettingsNav';

const SelazarIntegration = () =>
    <Link to="/retailer/settings/integrations/add/selazar">
        <Card className="card-integration-create">
            <Image src='Images/selazar-logo.svg' />
        </Card>
    </Link>

const AddIntegration = () =>
    <Fragment>
        <SettingsNav activeTitle="Integrations" />
        <div className="nav-content">
            <div className="main-content">
                <Row>
                    <Col sm={12} md={6}>
                        <h3 className="mb-5">Integrations</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3}>
                        <SelazarIntegration />
                    </Col>
                </Row>
            </div>
        </div>
    </Fragment>

export default AddIntegration;
