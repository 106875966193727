import React, { Component } from 'react';
import LoadingBar from '../../../common/LoadingBar';

class DirectDebitCancelled extends Component {

    async componentDidMount() {
        this.props.history.push({ pathname: "/retailer/settings/payments/directdebit", state: { cancelled : true  } })
    }

    render() {
        return (
            <LoadingBar />
        )
    }
}

export default DirectDebitCancelled;