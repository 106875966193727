import React, { Component } from 'react';
import { Col, Row, Button, Card, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { GET, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { SuccessAlert, ErrorAlert } from '../../common/Alerts';


export class YourAddresses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            errorMessages: [],
            addressRemoved: this.props.location.state !== undefined ? this.props.location.state.addressRemoved : false,
        };

    }

    componentDidMount() {
        Promise.all([this.fetchAddresses()]);
    }

    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/address/add');
    }

    handleSelected = (addressId) => (e) => {
        e.preventDefault();
        this.props.history.push('/address/edit/' + addressId);
    }

    setDefault = (id) => {
        return PUT(Endpoints.ADDRESS.PUT.ADDRESS_SET_DEFAULT + id)
            .then(response => response.json())
            .then(result => {
                this.fetchAddresses();
            });
    }

    fetchAddresses() {
        return GET(Endpoints.ADDRESS.GET.ADDRESSES_BY_USERID)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else this.setState({ items: result.data ? result.data : [] });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving addresses."] }));
    }

    render() {
        const { items, errorMessages, addressRemoved } = this.state;

        return (
            <div className="main-content">
                <div className="main-container">
                    <h3 className="mb-0">Your Addresses</h3>

                    <Row className="mt-0">
                        <Col sm={12} md={4}>
                            <Button variant="secondary" className="btn-block" onClick={this.handleClick}>Add New Address</Button>
                        </Col>
                    </Row>

                    {addressRemoved && <SuccessAlert message="Address deleted successfully" />}
                    {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}

                    <Row className="mt-2">
                        {items.length ? items.map((item, i) =>
                            <Col sm={12} md={7} key={i} className="px-2">
                                <Card className="p-3 mb-3 card-list d-block">
                                    <div className="card-list-info" onClick={this.handleSelected(item.id)}>
                                        <FontAwesomeIcon className="card-icon" icon={faChevronRight} size="1x" />
                                        <p className="mb-1 font-weight-bold">{item.address.name}</p>
                                        <p className="mb-1">{item.address.line1}</p>
                                        {item.address.line2 && <p className="mb-1">{item.address.line2}</p>}
                                        <p className="mb-1">{item.address.city}</p>
                                        <p className="mb-1">{item.address.county}</p>
                                        <p className="mb-1">{item.address.postcode}</p>
                                    </div>
                                    {item.default === true ? <Badge variant="info" className="d-inline light-blue-bg btn-link">Default Address</Badge > : <Button variant="link" className="d-inlinep-0 m-0 p-0 underline" onClick={() => this.setDefault(item.id)}>Set as default address</Button>}
                                </Card>
                            </Col>) : <p className="front-weight-bold">No Addresses.</p>}
                    </Row>
                </div>
            </div>
        )

    }

}