import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SuccessAlert } from '../../../common/Alerts';

const SUCCESS_TITLE = "Successfully Submitted";
const SUCCESS_MESSAGE = "Direct debit application has been submitted successfully. It will be reviewed within 6 business days. We will notify you regarding the bank account details being accepted or rejected.";

const DirectDebitSuccess = () =>
    <Row>
        <Col sm={12} md={6}>
            <SuccessAlert heading={SUCCESS_TITLE} message={SUCCESS_MESSAGE} />
        </Col>
    </Row>

export default DirectDebitSuccess
