import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import RRLogo from '../../common/RRLogo'


export class AccountVerification extends Component {

    constructor(props) {
        super(props);

        this.state = {
            emailAddress: this.props.location.state !== undefined ? this.props.location.state.emailAddress : undefined,
        }
    }

    render() {
        const { emailAddress } = this.state;
        return (
            <div className="center">
                <div className="container">
                    <RRLogo />
                    <div className="center-block">
                        <h3>Verify Your Email</h3>
                        <p className="text">We have sent a verification email to {emailAddress}. You need to verify your email address before you can use Return Robin. If you have verified your email you can login.</p>
                        <Button variant="primary" block onClick={() => this.props.history.push({ pathname: "/retailer/login" })}>Login</Button>
                        <br />
                    </div>
                </div>
            </div>
        )
    }

}