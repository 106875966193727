import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { WarningAlert } from '../../../common/Alerts';

const NO_LINK_TITLE = "Cancelled Direct Debit";
const NO_LINK_MESSAGE = "You have cancelled your direct debit set up. You are not currently setup for direct debits.";


const DirectDebitCancelledWarning = ({ handleBackClick }) =>
    <Row>
        <Col sm={12} md={6}>
            <WarningAlert heading={NO_LINK_TITLE} message={NO_LINK_MESSAGE} />
            <Button variant="primary" className="float-right" onClick={handleBackClick}>Try again</Button>
        </Col>
    </Row>

export default DirectDebitCancelledWarning
