import React, { Component, Fragment } from 'react';
import { Button, Row, Col, Card, Image } from 'react-bootstrap';
import { GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { formatDate } from '../../../Utilities';
import SettingsNav from '../../common/nav/SettingsNav';
import { SuccessAlert } from '../../common/Alerts';
import LoadingBar from '../../common/LoadingBar';

const FAQ = () =>
    <section>
        <p className="mb-0">Add a new integration below. Please refer to the <a target='_blank' rel="noopener noreferrer" href="https://support.selazar.com/support/home">FAQ</a> page to help with this set up.</p>
        <Button variant="primary" className="px-4" onClick={() => window.open("http://support.selazar.com", "_blank")}>FAQ</Button>
    </section>

const IntegrationAdd = ({ history }) =>
    <Col Col sm={12} md={4}>
        <Card className="card-integration-add">
            <Card.Body className="mt-4">
                <p className="title">Want to add an integration?</p>
                <Button variant="secondary" className="px-4" onClick={() => history.push("/retailer/settings/integrations/add")}>Set up Integration</Button>
            </Card.Body>
        </Card>
    </Col>

const Integration = ({ integration, history }) =>
    <Col Col sm={12} md={4} className="mb-3">
        <Card className="card-integration-view" onClick={() => history.push(`/retailer/settings/integrations/edit/${integration.id}`)}>
            <Card.Body>
                <Image src='Images/selazar-logo.svg' className="card-integration-summary mb-3" />
                <p className="mt-5 mb-3">Date of Connection <span className="sub-text">{formatDate(integration.dateOfIntegration)}</span></p>
                <span className={integration.connected ? "status-completed" : "status-failed"}>{integration.connected ? "Connected" : "Disconnected"}</span>
            </Card.Body>
        </Card>
    </Col>

class Integrations extends Component {
    state = {
        loading: true,
        integrations: [],
        showSuccess: this.props.location.state !== undefined ? this.props.location.state.showSuccess : false,
    };

    async componentDidMount() {
        await this.getIntegrations();
        this.setState({ loading: false });
    }

    getIntegrations = () =>
        GET(Endpoints.SETTINGS.GET.INTEGRATIONS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const integrations = results ? results.data : [];
                this.setState({ integrations: integrations });
            })
            .catch(error => console.log(error));

    render() {
        const { loading, integrations, showSuccess } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <SettingsNav activeTitle="Integrations" />
                    <div className="nav-content">
                        <div className="main-content">
                            <Row className="mb-4">
                                <Col sm={12} md={6}>
                                    <h3 className="mb-3">Integrations</h3>
                                    {showSuccess && <SuccessAlert message="You have successfully integrated with Selazar!" />}
                                    <FAQ />
                                </Col>
                            </Row>
                            <Row>
                                {integrations?.map(integration => <Integration key={integration.id} integration={integration} history={this.props.history} />)}
                                {integrations?.length === 0 && <IntegrationAdd history={this.props.history} />}
                            </Row>
                        </div>
                    </div>
                </Fragment>
        )
    }
}

export default Integrations;
