import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Col, Row } from 'react-bootstrap';

const TITLE = 'Not found!';
const RESOURCE_NOT_FOUND = 'Unable to find the resource you were looking for. Please try again.';

const NotFoundSection = (props) => {
    return (
        <div>
            <h3>{TITLE}</h3>
            <br />
            <p>{RESOURCE_NOT_FOUND}</p><Link className="link-button" to={props.link}>{props.linkText}</Link>
        </div>
    );
}

const NotFound = (props) => {
    return (
        <React.Fragment>
            {props.isLoggedIn ? <NotFoundSection link={props.link} linkText={props.linkText} /> :
                <React.Fragment>
                    <Navbar collapseOnSelect expand="lg" className="header shadow-sm fixed-top bg-navbar">
                        <Link to="/">
                            <img
                                src="Images/logo_light_icon.svg"
                                width="30"
                                height="30"
                                className="d-sm-block d-md-block d-lg-none d-xl-none"
                                alt="Return Robin"
                            />
                        </Link>
                        <Link to="/">
                            <img src="Images/logo_light_horizontal.svg"
                                className="d-none d-sm-none d-md-none d-lg-block d-xl-block"
                                height="30"
                                alt="Return Robin" />
                        </Link>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Link className={`nav-link nav-first mr-2`} to={props.link}><span>{props.navTitle}</span></Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <div className="content-wrapper">
                        <div className="main-content">
                            <Row className="p-4">
                                <Col>
                                    <NotFoundSection link={props.link} linkText={props.linkText} />
                                </Col>
                            </Row>
                        </div>
                    </div>

                </React.Fragment>}
        </React.Fragment>
    );
};

NotFound.displayName = NotFound.name;
export default NotFound;