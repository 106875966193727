import React, { Component } from 'react';
import FormValidator from '../../common/FormValidator';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { PUT, GET  } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { ChangePasswordCard, STRONG_PASSWORD_PATTERN, NUMBER_PATTERN, SPECIAL_CHAR_PATTERN, LENGTH_PATTERN } from '../shared/Snippets';
import LoadingBar from '../../common/LoadingBar';

const VERIFICATION_ERROR = 'There has been an issue verifying reset password link.';

class ChangePassword extends Component {

    constructor(props) {

        super(props);

        this.validator = new FormValidator([
            {
                field: 'password',
                method: 'matches',
                args: [STRONG_PASSWORD_PATTERN],
                validWhen: true,
                message: 'Password does not meet the criteria, try again.'
            }
        ]);

        this.state = {
            passwordDetails: {
                password: '',
            },
            passwordresetid: this.props.match.params.passwordresetid,
            errorMessages: [],
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordChecks: {
                passwordNumberCheck: false,
                passwordSpecialCharCheck: false,
                passwordLengthCheck: false
            },
            validation: this.validator.valid(),
            error: false,
            addResetLink: false,
            loading: true
        }

    }

    checkValidity = () => {
        const { passwordresetid } = this.state;

        return GET(Endpoints.PASSWORD.GET.VERIFY_RESET_REQUEST + "/" + passwordresetid)
            .then(response => response.json())
            .then((result) => {
                if (result.data !== null) {
                    if (result.data.error) this.setState({ errorMessages: [VERIFICATION_ERROR] });
                    else {
                        if (!result.data.valid) this.setState({ errorMessages: ['Invalid password reset link'] });
                        else if (!result.data.alive) this.setState({ addResetLink: true });
                    }
                }
            }).catch(() => this.setState({ errorMessages: [VERIFICATION_ERROR] }));

    }

    async componentDidMount() {
        await this.checkValidity();
        this.setState({ loading: false });
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "password") {
            this.setState({
                passwordChecks: {
                    passwordNumberCheck: NUMBER_PATTERN.test(value),
                    passwordSpecialCharCheck: SPECIAL_CHAR_PATTERN.test(value),
                    passwordLengthCheck: LENGTH_PATTERN.test(value)
                },
                passwordDetails: {
                    [name]: value
                }
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { passwordDetails, passwordresetid } = this.state;

        const validation = this.validator.validate(passwordDetails);
        this.setState({ validation: validation });
        this.submitted = true;

        const viewModel = { passwordresetid: passwordresetid.toString(), password: passwordDetails.password }

        if (validation.isValid) {
            return PUT(Endpoints.PASSWORD.PUT.CHANGEPASSWORD, viewModel)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null && result.data.isExpired) this.setState({ addResetLink: result.data.isExpired });
                        result.data != null ? this.setState({ errorMessages: result.data }) : this.setState({ errorMessages: result.message });
                    }
                    else this.props.history.push({ pathname: "/retailer/forgotpassword/complete" });

                });
        }
    }

    handleLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/retailer/login');
    }

    handleResendRequest = (e) => {
        e.preventDefault();
        this.props.history.push('/retailer/forgotpassword');
    }

    render() {
        const { loading, passwordDetails, errorMessages, addResetLink, passwordStatus, passwordInputType, passwordInputIcon, validation, passwordChecks } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <ChangePasswordCard
                        handleSubmit={this.handleSubmit}
                        errorMessages={errorMessages}
                        addResetLink={addResetLink}
                        passwordStatus={passwordStatus}
                        passwordInputType={passwordInputType}
                        passwordDetails={passwordDetails}
                        handleInputChange={this.handleInputChange}
                        handleShowHidePassword={this.handleShowHidePassword}
                        passwordInputIcon={passwordInputIcon} validation={validation}
                        passwordChecks={passwordChecks}
                        submitted={this.submitted}
                        handleLogin={this.handleLogin}
                        handleResendRequest={this.handleResendRequest}
                    />}
            </React.Fragment>
        )
    }
}

export default ChangePassword;