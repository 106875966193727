import React, { Component } from 'react';
import RRLogo from '../../common/RRLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

export class RegistrationCancelled extends Component {

    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    }

    render() {

        return (
            <div className="center">
                <div className="container">
                    <RRLogo />
                    <div className="center-block">
                        <h3>Account Creation Cancelled</h3>
                        <div className="d-block text-center mx-5 mt-5 mb-3">
                            <FontAwesomeIcon icon={faBell} mask={faCircle} className="orange" size="6x" transform="shrink-4" />
                        </div>
                        <p className="font-weight-bold text-center mb-5">We have cancelled your Return Robin account creation</p>
                        <Button variant="primary" block onClick={this.handleClick}>Return to Login</Button>
                    </div>
                </div>
            </div>
        )
    }

}