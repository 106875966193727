import React, { Component } from 'react';
import { AddressCheckCard } from '../shared/Snippets'; 

const SUMMARY_TEXT = "Before you can use Return Robin we need you to add your address to your account.";
const BUTTON_TEXT = "Add my address";
const INFO_TITLE = "Why do you need my address?";
const INFO_TEXT = "To organise returns we need to know the address, this allows us to give you the correct information before you start your return."

class AddressCheck extends Component {
    
    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/address/firstaddress');
    }

    render() {

        return (
            <AddressCheckCard handleClick={this.handleClick} summaryText={SUMMARY_TEXT} buttonText={BUTTON_TEXT} infoTitle={INFO_TITLE} infoText={INFO_TEXT} />
        )
    }
}

export default AddressCheck;