import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

const getPointDifference = (scoreChange, scoreIncrease) => scoreIncrease
    ? <span>Your score has gone up by <span className="text-positive">{scoreChange} points<FontAwesomeIcon icon={faLongArrowAltUp} /></span></span>
    : scoreChange === 0 || scoreChange === 100
        ? <span>You score has <span className="text-neutral">not changed</span></span>
        : <span>Your score has gone down by <span className="text-negative">{scoreChange} points<FontAwesomeIcon icon={faLongArrowAltDown} /></span></span>


const ScoreDetails = ({ title, scoreDetails }) => (
    <Row>
        <Col sm={12} md={4}>
            <section className="section-score text-center">
                <h4 className="mb-3">{title}</h4>
                <h1 className="title-total">{scoreDetails.score}</h1>
                <span className="text-sub mb-2">Out of 100</span>
                {getPointDifference(scoreDetails.scoreChange, scoreDetails.scoreIncrease)}
            </section>
        </Col>
    </Row>
);

const ScoreInfo = () => (
    <section className="link-faq">
        <p className="title">What is a Robin Score?</p>
        <p>View our <Link className="link-button" to='/profile/faq'>Robin Score FAQ</Link> to find out more information.</p>
    </section>
);

export {
    getPointDifference,
    ScoreDetails,
    ScoreInfo
}