import React from 'react';
import RRLogo from '../../common/RRLogo'
import { ErrorAlert } from '../../common/Alerts';

const IssueCard = ({ title, message }) => (
    <div className="center">
        <div className="container">
            <RRLogo />
            <div className="center-block mb-5">
                <h3>{title}</h3>
                <ErrorAlert heading="Issue" messages={message} />
                <h6 className="mt-4">Next Steps</h6>
                <p>Contact our customer service team by emailing <a href="mailto:hello@returnrobin.com">hello@returnrobin.com</a> for advice on what to do next.</p>
            </div>
        </div>
    </div>
);

const STRONG_PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[@$!%*#?&]).{6,}/;
const NUMBER_PATTERN = /^(?=.*[0-9])/;
const SPECIAL_CHAR_PATTERN = /^(?=.*[@$!%*#?&])/;
const LENGTH_PATTERN = /^.{6,}/;
const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_TOKEN;
const PHONE_UTIL = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export {
    IssueCard,
    STRONG_PASSWORD_PATTERN,
    NUMBER_PATTERN,
    SPECIAL_CHAR_PATTERN,
    LENGTH_PATTERN,
    CAPTCHA_KEY,
    PHONE_UTIL
}