import React, { Component } from 'react';
import HomeHeader from '../shared/HomeHeader';
import HomeFooter from '../shared/HomeFooter';
import { Link } from 'react-router-dom';

import './FAQ.scss';

class CancelCollection extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={false} />
                <div className="faq-container">
                    <div className="faq-text">
                        <h1>How do I amend or cancel a collection?</h1>
                        <p>Return Robin collection dates can be changed or cancelled up until 8 pm the day before your preferred collection day.</p>
                        <p>You can edit a collection by viewing the return in Return Robin, and selecting a new collection date.</p>
                        <p>You can cancel a collection by viewing the return in Return Robin and clicking “Cancel Return”.</p>
                        <p>Just so you know, amending or cancelling a collection will have no impact on your Robin Score. To find out more about your Robin Score and what impacts it, <Link to="/help/robinscore">click here</Link>.</p>
                    </div>
                </div>
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default CancelCollection;