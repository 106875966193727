import React, { Component } from 'react';
import { Form, FormGroup, Button, Row, Col } from 'react-bootstrap';
import FormValidator from '../../common/FormValidator';
import { POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alerts';
import { Address } from '../shared/Address';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Confirm from '../../common/modals/Confirm';

const PostCodePattern = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

export class AddAddress extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your full name'
            },
            {
                field: 'line1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a vaild address'
            },
            {
                field: 'city',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid City'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid County'
            },
            {
                field: 'postcode',
                method: 'matches',
                args: [PostCodePattern],
                validWhen: true,
                message: 'Enter a valid postcode'
            },
            {
                field: 'addressType',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            }
        ]);

        this.state = {
            addressTypes: [],
            errorMessages: [],
            userId: '',
            addressTypeId: '',
            form: {
                name: '',
                line1: '',
                line2: '',
                city: '',
                county: '',
                postcode: '',
                notes: '',
                addressType: 'Personal',
                defaultAddress: false
            },
            validation: this.validator.valid(),
            show: false,
            showAddress: false
        };
    }

    validate = (form) => {
        return this.validator.validate(form);
    }

    componentDidMount() {
        Promise.all([this.getAddressTypes()]);
    }

    getAddressTypes() {
        return POST(Endpoints.ALL_ADDRESS_TYPES)
            .then(response => response.json())
            .then(result => {
                this.setState({ addressTypes: result ? result : [] });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving address types."] }));
    }

    handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "radio") {

            if (name === "addressType") {
                if (value === 'Personal') {
                    this.setState(prevState => ({
                        form: {
                            ...prevState.form,
                            addressType: 'Personal',
                        }
                    }));
                } else {
                    this.setState(prevState => ({
                        form: {
                            ...prevState.form,
                            addressType: 'Commercial'
                        }
                    }));
                }
            }

        }

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { form, addressTypes } = this.state;
        const validation = this.validate(form);

        this.setState({ validation: validation });
        this.submitted = true;

        const viewModel = {
            addressTypeId: addressTypes.find(f => f.type === form.addressType)?.id,
            default: form.defaultAddress,
            address: form
        }

        if (validation.isValid) {
            return POST(Endpoints.ADDRESS.POST.CREATE_ADDRESS, viewModel)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data) this.setState({ errorMessages: result.data });
                        else this.setState({ errorMessages: result.message });
                        this.setState({ show: true });
                    }
                    else this.props.history.push({ pathname: "/address/youraddresses" });
                }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the address."] }));
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        this.props.history.push('/address/youraddresses');
    }

    handleShow = (e) => {
        e.preventDefault();
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleCloseModal = (e) => {
        e.preventDefault();
        this.setState({ show: false });
    }

    handleAddressShow = (e) => {
        e.preventDefault();
        this.setState({ showAddress: true });
    }

    handleAddressClose = () => {
        this.setState({ showAddress: false });
    }

    handleAddressCloseModal = (e) => {
        e.preventDefault();
        this.setState({ showAddress: false });
    }


    handleCancel = (e) => {
        e.preventDefault();
        this.props.history.push("/address/youraddresses");
    }

    renderForm = () => {
        const { form, addressTypes, validation, errorMessages } = this.state;

        return (
            <Form onSubmit={this.handleSubmit} className="w-100">
                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                <Address
                    details={form}
                    validation={validation}
                    showDefault={true}
                    handleInputChange={this.handleInputChange}
                    addressTypes={addressTypes}
                />
                <FormGroup>
                    <div className="float-right d-none d-sm-block">
                        <Button variant="link" className="underline" type="button" onClick={this.handleAddressShow}>Cancel</Button>
                        <Button variant="primary" type="submit" value="Submit">Add Address</Button>
                    </div>
                    <div className="d-block d-sm-none text-center">
                        <Button variant="link" className="underline" type="button" onClick={this.handleAddressShow}>Cancel</Button>
                        <Button variant="primary" block type="submit" value="Submit">Add Address</Button>
                    </div>
                </FormGroup>
                <Confirm handleConfirmAction={this.handleCancel} title="Cancel Adding Address" variant="outline-danger" block="false" buttonText="Cancel Adding Address" text="Are you sure that you want to cancel adding this address? We will not save this form." show={this.state.showAddress} handleClose={this.handleAddressClose} handleCancelAccount={this.handleAddressCancelAccount} handleCloseModal={this.handleAddressCloseModal} closeLink={true} linkText="Return to adding address" />
            </Form>
        );
    }

    render() {

        return (
            <div className="main-content">
                <div className="main-container">
                    <div className="mb-2">
                        <FontAwesomeIcon icon={faChevronLeft} size="xs" /><Button variant="link" className="p-0  underline" onClick={this.handleClick}>Back to Addresses</Button>
                    </div>
                    <h3 className="py-2">Add Address</h3>
                    <Row>
                        <Col sm={12} md={7}>
                            {this.renderForm()}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

}