  
import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

const AddCard = ({ onAddCardClick }) =>
    <Row>
        <Col sm={12} md={4}>
            <Card className="card-payment-add">
                <Card.Body className="mt-4">
                    <p className="title">Add a Card Payment</p>
                    <Button variant="secondary" className="px-4" onClick={onAddCardClick}>Set up Card Payment</Button>
                </Card.Body>
            </Card>
        </Col>
    </Row>

export default AddCard;