import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HomeHeader from './shared/HomeHeader';
import Register from '../register/customer/Register';
import HomeFooter from './shared/HomeFooter';

import './Returns.scss';

const LOG_TITLE = "Log your return";
const LOG_TEXT = "Enter your return details and choose a date that suits you between Mon-Sat up to 2 weeks in advance.";
const COLLECTION_TITLE = "Collection slot notification";
const COLLECTION_TEXT = "We will notify you of your 2 hour collection window on your chosen date.";
const COURIER_TITLE = "Courier collects";
const COURIER_TEXT = "No label, no problem. Our driver brings your label with them when collecting."
const TRACK_TITLE = "Track progress"
const TRACK_MESSAGE = "You can track and monitor the progress of your return until it reaches its final destination."

const Banner = () => (
    <div className="returns-banner">
        <div className="returns-banner-text">
            <h1>Manage Your Returns</h1>
            <h5>Swift returns without leaving your nest!</h5>
            <p>With Return Robin, you can easily manage and monitor multiple returns under one roof. We provide socially distanced collections via our Yodel courier partners, offering you reliable returns from the comfort and safety of your own home.</p>
        </div>
        <div className="returns-banner-image-front">
            <img src="Images/return-menu.png" alt="Returns Menu" />
        </div>
        <div className="returns-banner-image">
            <img src="Images/returns-banner.jpg" alt="Help and Advice" />
        </div>
    </div>
);

const HowItWorksSection = ({ imgSrc, imgAlt, title, text }) => (
    <Col sm={12} md={3}>
        <div className="returns-info-section">
            <img src={imgSrc} alt={imgAlt} height="64" />
            <p className="title">{title}</p>
            <p>{text}</p>
        </div>
    </Col>
);

const HowItWorks = () => (
    <Container className="returns-info-container" fluid>
        <h2 className="text-center">How it Works</h2>
        <Row className="px-5">
            <HowItWorksSection imgSrc="Images/comms_mobile_phone.svg" imgAlt="Log Return" title={LOG_TITLE} text={LOG_TEXT} />
            <HowItWorksSection imgSrc="Images/delivery_package.svg" imgAlt="Collection" title={COLLECTION_TITLE} text={COLLECTION_TEXT} />
            <HowItWorksSection imgSrc="Images/delivery_van.svg" imgAlt="Courier" title={COURIER_TITLE} text={COURIER_TEXT} />
            <HowItWorksSection imgSrc="Images/delivery_return.svg" imgAlt="Track Progress" title={TRACK_TITLE} text={TRACK_MESSAGE} />
        </Row>
    </Container>
)

class Returns extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={true} />
                <Banner />
                <div className="form-home">
                    <Register showLogo={false} />
                </div>
                <HowItWorks />
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default Returns;