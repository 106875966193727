import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { WarningAlert } from '../../../common/Alerts';

const NO_LINK_TITLE = "No Direct Debit Linked";
const NO_LINK_MESSAGE = "You have not set up a direct debit. Please be aware that currently we can only accept UK bank accounts.";


const DirectDebitNoLink = ({ handleNoLinkClick }) =>
    <Row>
        <Col sm={12} md={6}>
            <WarningAlert heading={NO_LINK_TITLE} message={NO_LINK_MESSAGE} />
            <Button variant="primary" className="float-right" onClick={handleNoLinkClick}>Set up Direct Debit</Button>
        </Col>
    </Row>

export default DirectDebitNoLink
