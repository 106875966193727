import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, Button, Modal, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import AddAddressForm from './AddAddressForm';
import FormValidator from '../../common/FormValidator';
import { POST, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alerts';
import './AddAddressModal.scss';

const PostCodePattern = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

export class AddAddressModal extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your full name'
            },
            {
                field: 'line1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a vaild address'
            },
            {
                field: 'city',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid City'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid County'
            },
            {
                field: 'postcode',
                method: 'matches',
                args: [PostCodePattern],
                validWhen: true,
                message: 'Enter a valid postcode'
            },
            {
                field: 'addressType',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            }
        ]);

        this.state = {
            addressTypes: [],
            errorMessages: [],
            userId: '',
            addressTypeId: '',
            form: {
                name: '',
                line1: '',
                line2: '',
                city: '',
                county: '',
                postcode: '',
                notes: '',
                addressType: 'Personal',
                defaultAddress: false
            },
            validation: this.validator.valid(),
            show: false,
            showButtonModal: false,
            userAddressId: ''
        };
    }

    validate = (form) => {
        return this.validator.validate(form);
    }

    componentDidMount() {
        Promise.all([this.getAddressTypes()]);
    }

    getAddressTypes() {
        return POST(Endpoints.ALL_ADDRESS_TYPES)
            .then(response => response.json())
            .then(result => {
                this.setState({ addressTypes: result ? result : [] });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving address types."] }));
    }

    handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "radio") {

            if (name === "addressType") {
                if (value === 'Personal') {
                    this.setState(prevState => ({
                        form: {
                            ...prevState.form,
                            addressType: 'Personal',
                        }
                    }));
                } else {
                    this.setState(prevState => ({
                        form: {
                            ...prevState.form,
                            addressType: 'Commercial'
                        }
                    }));
                }
            }
        }

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { form, addressTypes } = this.state;
        const validation = this.validate(form);

        this.setState({ validation: validation });
        this.submitted = true;

        const viewModel = {
            addressTypeId: addressTypes.find(f => f.type === form.addressType)?.id,
            address: form
        }

        if (validation.isValid) {
            return POST(Endpoints.ADDRESS.POST.CREATE_ADDRESS, viewModel)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data) this.setState({ errorMessages: result.data });
                        else this.setState({ errorMessages: result.message });
                        this.setState({ show: true });
                    }
                    else this.setState({ userAddressId: result.data.id });
                    this.handleShowButtonModal();
                }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the address."] }));
        }
    }

    setDefault = () => {
        const id = this.state.userAddressId;
        return PUT(Endpoints.ADDRESS.PUT.ADDRESS_SET_DEFAULT + id)
            .then(response => response.json())
            .then(result => {
                this.setState({ showButtonModal: false });
                this.props.fetchAddresses();
            });
    }

    handleShow = (e) => {
        e.preventDefault();
        this.setState({ 
            show: true, 
            form: {
                name: '',
                line1: '',
                line2: '',
                city: '',
                county: '',
                postcode: '',
                notes: '',
                addressType: 'Personal',
                defaultAddress: false
            },
            validation: this.validator.valid(),
        });
    }
    
    handleShowButtonModal = () => {
        this.setState({ 
            show: false,
            showButtonModal: true,  
            }
        );
    }

    handleCloseModal = (e) => {
        e.preventDefault();
        this.setState({ show: false });
    }

    handleButtonCloseModal = () => {
        this.setState({ showButtonModal: false });
        this.props.fetchAddresses();
    }

    render = () => {
        const { form, addressTypes, validation, errorMessages, show, showButtonModal } = this.state;

        return (
            <>
            <Row>
                <Col sm={12} md={7} lg={7}>
                    <div className='float-right'>
                        <FontAwesomeIcon className='fa-plus' icon={faPlusCircle} size="1x" />
                        <button className='open-modal' onClick={this.handleShow}>
                            Add new address
                        </button>
                    </div>
                
                </Col>
            </Row>
                <Modal show={show}>         
                    <Modal.Header closeButton onClick={this.handleCloseModal}></Modal.Header>

                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit} className="w-100">
                            {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                            <AddAddressForm
                                details={form}
                                validation={validation}
                                handleInputChange={this.handleInputChange}
                                addressTypes={addressTypes}
                            />
                            <FormGroup>
                                <div className="float-right d-none d-sm-block">
                                    <Button variant="primary" type="submit" value="Submit">Add Address</Button>
                                </div>
                                <div className="d-block d-sm-none text-center">
                                    <Button variant="primary" block type="submit" value="Submit">Add Address</Button>
                                </div>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showButtonModal}>         
                    <Modal.Body>
                        <FormGroup>
                            <div>
                                <FormLabel className="modalLabel">Make this your default address?</FormLabel>
                            </div>
                                
                            <div className="btn-group modalButton">
                                <Button variant="secondary" className="modal-Button" type="button" onClick={this.handleButtonCloseModal} value="No">No</Button>
                                <div className="divider"/>
                                <Button variant="primary" className="modal-Button" type="button" onClick={this.setDefault} value="Yes">Yes</Button>
                            </div> 
                        </FormGroup>     
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}