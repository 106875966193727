import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import { POST, POST_WITH_HEADER, extractData } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import GoogleLogin from 'react-google-login';
import { WarningAlert, ErrorAlert } from '../../common/Alerts';
import { Header, SignInMessaging, SignInForm, CreateAccount } from '../shared/Snippets';

import '../shared/Login.scss';

const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;

const GoogleSignIn = ({ googleMessages, heading, googleWarning, signInRequired, responseGoogleSuccess, responseGoogleFailure }) => (
    <React.Fragment>
        {googleMessages.length > 0 && <ErrorAlert messages={googleMessages} heading={heading} />}
        {googleWarning && <WarningAlert linkRequired={signInRequired} message="The email address you have entered is associated with an account." link="login" linkText="Sign in" linkPostText="to use Return Robin." />}
        <div className="google mt-4">
            <GoogleLogin
                clientId={GOOGLE_LOGIN_CLIENT_ID}
                buttonText="Google Account"
                onSuccess={responseGoogleSuccess}
                onFailure={responseGoogleFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
                className="googleButton" />
        </div>
    </React.Fragment>
);

class Login extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'Email is required.'
            },
            {
                field: 'password',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password is required.'
            }
        ]);

        this.state = {
            form: {
                email: '',
                password: ''
            },
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            validation: this.validator.valid(),
            warning: false,
            errorMessages: [],
            heading: '',
            signInRequired: false,
            googleMessages: [],
            googleWarning: false,
            loading: false
        };
    }

    validate = (form) => {
        return this.validator.validate(form);
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { form } = this.state;

        const validation = this.validate(form);
        this.setState({ validation: validation });

        if (validation.isValid) {
            return POST(Endpoints.USER.POST.REGULAR_LOGIN, form)
                .then(response => response.json())
                .then((result) => {
                    var data = result.data;
                    if (result.error) result.data !== null ? this.setState({ errorMessages: result.data, loading: false }) : this.setState({ errorMessages: result.message, loading: false });
                    else if (data.user.active === false) this.props.history.push({ pathname: "/register/accountverification", state: { userid: data.user.id, phoneNumber: data.user.phoneNumber } });
                    else {
                        if (data.setupComplete === false) {
                            this.props.onLogin(extractData(result), false);
                            this.props.history.push({ pathname: "/address/addresscheck", state: { userid: data.user.id } });
                        }
                        else {
                            this.props.onLogin(extractData(result), false);

                            const prevURL = JSON.parse(localStorage.getItem('prevURL'));
                            if(prevURL)
                            {
                                this.props.history.push({ pathname: prevURL });
                            }
                            else
                                this.props.history.push({ pathname: "/" });
                            }
                        this.setState({ loading: false });
                    }
                }).catch(() => this.setState({ errorMessages: ["There has been an issue logging in."], loading: false }));
        } else this.setState({ loading: false });
    }

    responseGoogleSuccess = (response) => {
        POST_WITH_HEADER(Endpoints.USER.GET.GOOGLE_LOGIN, response.getAuthResponse().id_token)
            .then(response => response.json())
            .then((result) => {
                var data = result.data;
                if (result.error) {
                    this.setState({ googleMessages: result.message });
                    if (data !== null) this.setState({ googleWarning: data.warning, heading: data.heading, signInRequired: data.signInRequired, errorMessages: data.errorMessages ?? [] });
                }
                else {
                    if (data.user === null) this.props.history.push({ pathname: "/register/mobile", state: { identifier: response.tokenId } });
                    else if (data.user.active === false) this.props.history.push({ pathname: "/register/accountverification", state: { userid: data.user.id, phoneNumber: data.user.phoneNumber } })
                    else {
                        if (data.setupComplete === false) {
                            this.props.onLogin(extractData(result), false);
                            this.props.history.push({ pathname: "/address/addresscheck", state: { userid: data.user.id } });
                        }
                        else {
                            this.props.onLogin(extractData(result), false);
                            this.props.history.push({ pathname: "/" });
                        }
                    }
                }
            });
    }

    responseGoogleFailure = () => {
        this.setState({ googleMessages: ["Cannot connect with Google"] });
    }

    render() {
        const { form, loading, validation, errorMessages, warning, signInRequired, passwordInputIcon, passwordInputType, googleMessages, heading, googleWarning } = this.state;

        return (
            <React.Fragment>
                {loading && <LoadingBar />}
                <div className="center">
                    <Container>
                        <Header />
                        <div className="center-block">
                            <SignInMessaging errorMessages={errorMessages} warning={warning} signInRequired={signInRequired} />
                            <SignInForm form={form} handleSubmit={this.handleSubmit} validation={validation} handleInputChange={this.handleInputChange} passwordInputIcon={passwordInputIcon} passwordInputType={passwordInputType} handleShowHidePassword={this.handleShowHidePassword} showRetailerLink={true} />
                            <div className="separator small-top">or</div>
                            <h6>Sign in with one of the following accounts</h6>
                            <GoogleSignIn googleMessages={googleMessages} heading={heading} googleWarning={googleWarning} signInRequired={signInRequired} responseGoogleSuccess={this.responseGoogleSuccess} responseGoogleFailure={this.responseGoogleFailure} />
                        </div>
                        <div className="full-width-separator my-5"></div>
                        <CreateAccount title="Create an Account" text="Create an account in minutes" link="/register" buttonText="Create an Account" />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Login);
