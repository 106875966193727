import React, { Fragment } from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import 'react-intl-tel-input/dist/main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewPassword = ({ passwordStatus, passwordInputType, formPassword, handleInputChange, handleShowHidePassword, passwordInputIcon, validationPasswordMessage, passwordChecks, submitted }) =>
    <Fragment>
        <FormGroup>
            <FormLabel htmlFor="passwordField" className="font-weight-bold">Password</FormLabel>
            <FormControl id="passwordField" className={passwordStatus} maxLength="50" type={passwordInputType} name="password" value={formPassword} onChange={handleInputChange} />
            <span className="form-password-icon" onClick={handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
            <span className="text-danger">{validationPasswordMessage}</span>
        </FormGroup>
        <div className="form-password-checklist">
            <ul>
                <li className={submitted && !passwordChecks.passwordLengthCheck ? "submission-fail" : passwordChecks.passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                <li className={submitted && !passwordChecks.passwordNumberCheck ? "submission-fail" : passwordChecks.passwordNumberCheck ? "pass" : "fail"}>One number</li>
                <li className={submitted && !passwordChecks.passwordSpecialCharCheck ? "submission-fail" : passwordChecks.passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
            </ul>
        </div>
    </Fragment>

export default NewPassword;