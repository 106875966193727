import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import HomeHeader from './shared/HomeHeader';
import HomeFooter from './shared/HomeFooter';
import { LinkContainer } from 'react-router-bootstrap';

import './Help.scss';

const LABEL_TITLE = "No label no problem";
const LABEL_TEXT = "No way to print labels at home? Don't worry, our drivers bring your labels with them to make your life easier.";
const SUPPORT_TITLE = "Informed customer support";
const TRACKING_TITLE = "Live tracking";
const TRACKING_TEXT = "Live tracking is available on all returns with multiple scans throughout the journey. We track returns from your door to your chosen location.";

const Banner = () => (
    <div className="help-banner pt-3">
        <h1>Helpful Tips and Information</h1>
        <div className="help-banner-image">
            <img src="Images/help-banner.jpg" alt="Help and Advice" />
        </div>
    </div>
);

const FAQCard = ({ title, link }) => (
    <LinkContainer to={link}>
        <Card className="card-faq">
            <h2 className="text-center">{title}</h2>
        </Card>
    </LinkContainer>
);

const FAQs = () => (
    <Container className="help-faq-container" fluid>
        <Row>
            <Col sm={12} md={12} lg={4}>
                <FAQCard title="How do I book a return?" link="/help/bookreturn" />
            </Col>
            <Col sm={12} md={12} lg={4}>
                <FAQCard title="How do I track my return?" link="/help/trackreturn" />
            </Col>
            <Col sm={12} md={12} lg={4}>
                <FAQCard title="I have multiple returns, what do I do?" link="/help/multiplereturns" />
            </Col>
            <Col sm={12} md={12} lg={4}>
                <FAQCard title="How do I amend or cancel a collection?" link="/help/cancelcollection" />
            </Col>
            <Col sm={12} md={12} lg={4}>
                <FAQCard title="What is my Robin Score and what impacts it?" link="/help/robinscore" />
            </Col>
            <Col sm={12} md={12} lg={4}>
                <FAQCard title="Can I leave my parcel with my neighbour?" link="/help/leaveparcel" />
            </Col>
        </Row>
    </Container>
);

const ReturnsInfoSection = ({ imgSrc, imgAlt, title, text, isComponent = false }) => (
    <Col sm={12} md={4}>
        <div className="help-info-section">
            <img src={imgSrc} alt={imgAlt} height="96" />
            <p className="title">{title}</p>
            {isComponent ? text : <p>{text}</p>}
        </div>
    </Col>
);

const SupportText = () => <p>Our customer service team are always here to help, just email <a href="mailto:hello@returnrobin.com">hello@returnrobin.com</a> or call us on <a href="tel:02045665160">0204 566 5160</a>.</p>;

const ReturnsInfo = () => (
    <Container className="help-info-container" fluid>
        <h2 className="text-center">With Return Robin, returns couldn't be easier</h2>
        <Row className="px-5">
            <ReturnsInfoSection imgSrc="Images/delivery_tracking.svg" imgAlt="Label" title={LABEL_TITLE} text={LABEL_TEXT} />
            <ReturnsInfoSection imgSrc="Images/help_info.svg" imgAlt="Support" title={SUPPORT_TITLE} text={<SupportText />} isComponent={true} />
            <ReturnsInfoSection imgSrc="Images/map_tracking.svg" imgAlt="Tracking" title={TRACKING_TITLE} text={TRACKING_TEXT} />
        </Row>
    </Container>
);

class Help extends Component {

    render() {
        return (
            <React.Fragment>
                <HomeHeader isReturnsActive={false} />
                <Banner />
                <FAQs />
                <ReturnsInfo />
                <HomeFooter />
            </React.Fragment>
        )
    }
}

export default Help;